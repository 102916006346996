import Home from '../views/management-home/Home';
import Activities from '../views/management-activities/Activities';
import Resources from '../views/management-resource/Resources';
import Searcher from '../views/management-searcher/Searcher';
import Login from '../views/login/Login';
import Register from '../views/register/Register';
import Recovery from '../views/recovery/Recovery';
import Help from '../views/management-help/Help';
import Index from '../views/management-activities';
import IndexResource from '../views/management-resource';
import ValidateActivity from '../views/management-activities/ValidateActivity';
import ShowActivity from '../views/showActivity/ShowActivity';
import ShowResource from '../views/showResource/ShowResource';
import ActivitiesAdmin from '../views/admin-activities/ActivitiesAdmin';
import ResourcesAdmin from '../views/admin-resources/ResourcesAdmin';
import Admin from '../views/admin/Admin';
import ActivityImport from 'views/activity-import/Activity-import';
import Verification from 'views/verification/Verification';
import ResetPasswordForm from 'views/reset-password/ResetPasswordForm';
import ActivitiesView from 'views/management-activities/ActivitiesView';
import ResourcesView from 'views/management-resource/ResourcesView';
import ValidateResource from 'views/management-resource/ValidateResource';

export const privateRoutes = [
	{
		path: '/admin',
		component: Admin,
		isPrivate: true,
	},
	{
		path: '/actividad/importar',
		component: ActivityImport,
		isPrivate: true,
	},
	{
		path: '/actividad/consultar',
		component: Activities,
		isPrivate: true,
	},
	{
		path: '/actividad/consultar/:id',
		component: ActivitiesView,
		isPrivate: true,
	},
	{
		path: '/actividad/validar/:id',
		component: ValidateActivity,
		isPrivate: true,
	},
	{
		path: '/admin/actividades',
		component: ActivitiesAdmin,
		isPrivate: true,
	},
	{
		path: '/recurso/consultar',
		component: Resources,
		isPrivate: true,
	},
	{
		path: '/recurso/consultar/:id',
		component: ResourcesView,
		isPrivate: true,
	},
	{
		path: '/admin/recursos',
		component: ResourcesAdmin,
		isPrivate: true,
	},
	{
		path: '/actividad/editar/:id',
		component: Index,
		isPrivate: true,
	},
	{
		path: '/recurso/editar/:id',
		component: IndexResource,
		isPrivate: true,
	},
	{
		path: '/actividad/nueva',
		component: Index,
		isPrivate: true,
	},
	{
		path: '/recurso/nuevo',
		component: IndexResource,
		isPrivate: true,
	},
	{
		path: '/recurso/validar/:id',
		component: ValidateResource,
		isPrivate: true,
	}
];

export const publicRoutes = [
	{
		path: '/actividad/detalle/:id',
		component: ShowActivity,
		isPrivate: false,
	},
	{
		path: '/recurso/detalle/:id',
		component: ShowResource,
		isPrivate: false,
	},
	{
		path: '/login',
		component: Login,
		isPrivate: false,
	},

	{
		path: '/registro',
		component: Register,
		isPrivate: false,
	},
	{
		path: '/recuperar',
		component: Recovery,
		isPrivate: false,
	},
	{
		path: '/ayuda',
		component: Help,
		isPrivate: false,
	},
	{
		path: '/buscador',
		component: Searcher,
		isPrivate: false,
	},
	{
		path: '/',
		component: Home,
		isPrivate: false,
	},
	{
		path: '/inicio',
		component: Home,
		isPrivate: false,
	},
	{
		path: '/verificacion/:id',
		component: Verification,
		isPrivate: false,
	},
	{
		path: '/recuperar/formulario/:token',
		component: ResetPasswordForm,
		isPrivate: false,
	},
];
