import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'state';
import { useParams, Link } from "react-router-dom";
import cx from "classnames";
import styles from './ResetPasswordForm.module.scss';
import { FormText, Alert } from "reactstrap";
import { useIntl } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { resetPassword, reset } from "state/auth/actions";
import { regularExpression } from "utils/consts";
import { useHistory } from "react-router-dom";
import ReactLoading from 'react-loading';

const ResetPasswordForm = () => {
  const { formatMessage: f } = useIntl();

  const dispatch = useDispatch();
  const { token } = useParams();
  const { loading, error, ok } = useSelector('auth');
  let history = useHistory();

  useEffect(() => {
    reset(dispatch);
  }, []);

  const recoverPasswordSchema = Yup.object().shape({
    password: Yup.string().required(f({ id: "form.errors.required" })).matches(regularExpression.PASSWORD, f({ id: "form.errors.password.strong" })),
    repeatPassword: Yup.string().oneOf([Yup.ref('password')], f({ id: "form.errors.password.match" })).required(f({ id: "form.errors.required" }))
  });

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(recoverPasswordSchema)
  });


  const onSubmit = async (data) => {
    data = { ...data, token: token };
    await resetPassword(dispatch, data);
  }

  return (
    loading ? <div className='loading-container'><ReactLoading type={'bars'} color={'#DC042B'} height={128} width={128} /></div> :
    <div className={cx(styles["main-container"])} >
      <div className={cx(styles["panel"])}>
          <div>
            <div className={cx(styles["panel-head"])}>
              <div className={cx(styles["head-text"])}>Recuperación</div>
            </div>
            <div className={cx(styles["panel-body"])} >
              <div className="login-description">
                Es necesario que se identifique para solicitar la adscripción
                de nueva actividad comunitaria a la Red de Actividades Comunitarias (RAC) del Observatorio de Salud en Asturias
                o bien para modificar una ya existente. Para más información
                <a className="links" onClick={() => { history.push('/ayuda'); history.go('/ayuda') }}> puedes acceder a información sobre qué es la RAC y qué tipo de actividades pueden incluirse.</a>
              </div>
              <form onSubmit={handleSubmit(onSubmit)} className={cx(styles["recovery-form"])}>
                <div className={cx(styles["input-container"])}>
                  <div>Contraseña:</div>
                  <input type="password" name="password" ref={register} className="textfield" />
                  <div className={"error"}>{errors.password?.message}</div>
                </div>
                <div className={cx(styles["input-container"])}>
                  <div>Repite contraseña:</div>
                  <input type="password" name="repeatPassword" ref={register} className="textfield" />
                  <div className={"error"}>{errors.repeatPassword?.message}</div>
                </div>
                {error && <Alert className="mt-1" color="danger">
                  {error.description}
                </Alert>}
                {ok && <Alert className="mt-1" color="primary">
                  {f({ id: "resetPassword.ok" })}
                </Alert>}
                <input type="submit" className='button' value="Enviar" />
              </form>
              <div className={"links-container"}>
                <a className="links" onClick={(e) => { history.push('/login'); history.go('/login') }}>Volver al formulario de acceso</a>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
