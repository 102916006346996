import React, { useState, useEffect } from "react";
import cx from 'classnames';
import styles from './NewActivity.module.scss';
import { useParams } from "react-router-dom";
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useDispatch, useSelector } from '../../state';
import { getActivityById } from "../../state/activities/actions";

const InfoContainer = () => {
    return (
        <div className={cx(styles["info-container"])}>
            <div className={cx(styles['info'])}>
                <div>
                    Los datos que se faciliten serán utilizados solamente en relación a la Red de Actividades Comunitarias
                    (RAC) del Observatorio de Salud en Asturias y el proyecto de red nacional de actividades comunitarias que se está desarrollando con el PACAP. El Observatorio de Salud en Asturias garantiza, en los términos establecidos por la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (LOPD), que sus datos serán tratados de modo confidencial. En todo caso, y al amparo de lo dispuesto en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (LOPD), el interesado puede ejercer los derechos de acceso, rectificación, cancelación y oposición de sus datos de carácter personal en los términos establecidos en la LOPD.
                    La entidad responsable del fichero es el Observatorio de Salud en Asturias,
                    y se puede contactar a través de su secretaría: Observatorio de Salud de Asturias

                    <br />
                    <br />

                    Dirección General de Salud Pública y Participación
                    <br />
                    Consejería de Salud y Servicios Sanitarios
                    <br />
                    C/ Ciriaco Miguel Vigil – nº 9
                    <br />
                    33006 Oviedo
                    <br />
                    <br />
                    Tfno: 985106385 / 985106338
                    <br />
                    Correo electrónico: obsalud@asturias.org
                </div>
            </div>
        </div>)
}

const ActivitiesView = () => {


    const dispatch = useDispatch();
    const { activity } = useSelector('activities');
    const [preview, setPreview] = useState();
    const { id } = useParams();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        const getActivity = async () => {
            await getActivityById(dispatch, { id });
        }
        if (id) {
            getActivity();
        }
    }, []);

    useEffect(() => {
        activity.photo ? setPreview(`${process.env.REACT_APP_IMG_URL}${activity.photo}`) : setPreview("");

        if (activity.description && activity.description !== "") {
            const parsed = convertFromRaw(JSON.parse(activity.description));
            setEditorState((EditorState.createWithContent(parsed)));
        }
    }, [activity])


    return (
        <form >
            <div className={"subheader" + ' ' + cx(styles["header"])}>
                <div className={"h2-title" + ' ' + cx(styles["header-text"])}>Detalles de la Actividad Ref:{activity.id}</div>
            </div>
            
            <div className={cx(styles["body-style"])}>
                
                <div>
                    <div className={cx(styles["confirmation-element"])}>Nombre: {activity.name}</div>
                    <div className={cx(styles["confirmation-element"])}>Breve descripción: {activity.summary}</div>
                    {activity.description && activity.description !== "" ?
                        <div>
                            <div className={cx(styles["confirmation-element"])}>Descripción completa:</div>
                            <div className={cx(styles["confirmation-element"])}>
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="ACToolbar"
                                    wrapperClassName="wrapperClassName"
                                    readOnly
                                />
                            </div>
                        </div>
                        :
                        ""
                    }
                    <div className={cx(styles["confirmation-element"])}>Población: {activity.demographicGroup?.description}</div>
                    <div className={cx(styles["confirmation-element"])}>Género: {activity.gender?.description}</div>
                    {activity.otherDetails && activity.otherDetails !== "" ?
                        <div className={cx(styles["confirmation-element"])}>Otros detalles población: {activity.otherDetails}</div>
                        :
                        ""
                    }
                    <div className={cx(styles["confirmation-element"])}>Gratuita: {activity.free}</div>
                    <div className={cx(styles["confirmation-element"])}>Temporalidad: {activity.temporality}</div>
                    {activity.temporality === "Temporal" ?
                        activity.temporalities ?
                        activity.temporalities.map((item, index) => {
                                //console.log("Temporalities: " + index);
                                return (
                                    <div key={index} className={cx(styles["confirmation-element"])}>De: {item.start_date} a {item.end_date}</div>
                                )
                            })
                            : ""
                        :""}

                    <div className={cx(styles["confirmation-element"])}>Lugares donde se desarrolla:
                    </div>
                    {activity.location ?
                    activity.location.map((item, index) => {
                        return (
                            <div key={index} className={cx(styles["confirmation-element"])}>
                                {item.roadType.description !== undefined ? item.roadType.description + " " : ""}
                                {item.street !== undefined ? item.street + " " : ""}
                                {item.postalCode !== undefined ? item.postalCode + " " : ""}
                                {item.locality !== undefined ? item.locality + " " : ""}
                                {item.ubication !== undefined ? item.ubication + " " : ""}
                                {item.municipality.name !== undefined ? item.municipality.name : ""}
                            </div>
                        )
                    }): ""}
                    <div className={cx(styles["confirmation-element"])}>Persona de contacto: {activity.contact}</div>
                    <div className={cx(styles["confirmation-element"])}>Mail de contacto: {activity.contactMail}</div>
                    <div className={cx(styles["confirmation-element"])}>Teléfono de contacto: {activity.phone}</div>
                    {activity.webPage && activity.webPage !== "" ?
                        <div className={cx(styles["confirmation-element"])}>Página web: {activity.webPage}</div>
                        : ""
                    }
                    {activity.facebookPage && activity.facebookPage !== "" ?
                        <div className={cx(styles["confirmation-element"])}>Página de Facebook: {activity.facebookPage}</div>
                        : ""
                    }
                    {activity.twitterPage && activity.twitterPage !== "" ?
                        <div className={cx(styles["confirmation-element"])}>Página de Twitter: {activity.twitterPage}</div>
                        : ""
                    }
                    {activity.photo ?
                        <div className={cx(styles["confirmation-element"])}>Imagen: <img src={preview} alt={activity.photo} className="img-preview" /></div>
                        : ""
                    }
                    <div className={cx(styles["confirmation-element"])}>Has aceptado la política de confidencialidad</div>
                    {activity.permiso ? 
                    <div className={cx(styles["confirmation-element"])}>La persona de contacto de la actividad/recurso ha dado su conformidad para recibir mensajes de la web</div>
                    : ""}
                </div>
            </div>
            {InfoContainer}
            <div className={cx(styles["footer-container"])}>
                <div className={cx(styles["buttons-container-index"])}>

                </div>
            </div>

        </form>
    );
}

export default ActivitiesView;