import { country_enum, cautonoma_enum, provincia_enum, municipio_enum } from "utils/enums";

export const initialResourceState = {
    resources: [],
    resource: {},
    resourceOk: false,
    locations: [],
    location: {},
    loading: false,
    topics: [],
    selectedTopics: [],
    totalElements: 0,
    totalPages: 1,
    pageIndex: 0,
    determinantes: [0, 0, 0, 0, 0],
    field: 'id',
    direction: "DESC",
    keyword: '',
    selectedSituation: 1,
    countries: [],
    country: country_enum.SPAIN,
    autonomousCommunities: [],
    autonomousCommunity: cautonoma_enum.ASTURIAS,
    provinces: [],
    province: provincia_enum.ASTURIAS,
    municipalities: [],
    municipality: municipio_enum.ALLANDE,
    locality: '',
    categories: [],
    category: '',
    types: [],
    roadType: '',
    xaxis: 43.3622522,
    yaxis: -5.8485461,
    networks: [],
    network: '',
    validated: '',
    typeResource: 1,
    preview: [],
    photo: [],
    renew: 0
};

export const resourceReducer = (initialState = initialResourceState, action) => {

    switch (action.type) {

        case 'SET_RESOURCE':
            return { ...initialState, resource: action.payload };

        case 'SET_RESOURCE_SUCCESS':
            return { ...initialState, resourceOk: true };

        case 'RESET_RESOURCE_SUCCESS':
            return { ...initialState, resourceOk: false };

        case 'SET_TYPE_RESOURCE':
            return { ...initialState, typeResource: action.payload };

        case 'SET_LOADING':
            return { ...initialState, loading: action.payload };

        case 'SET_XAXIS':
            return { ...initialState, xaxis: action.payload }

        case 'SET_YAXIS':
            return { ...initialState, yaxis: action.payload }


        case 'UPDATE_RESOURCE_DATA':
            const resourceAux = { ...initialState.resource, ...action.payload };
            return { ...initialState, resource: resourceAux };

        case 'SET_VALIDATED':
            return { ...initialState, validated: action.payload }

        case 'ADD_REMOVE_SELECTED_TOPICS':
            const auxTopics = [...initialState.selectedTopics]
            if (auxTopics.includes(action.payload)) {
                auxTopics.splice(auxTopics.indexOf(action.payload), 1);
            } else {
                auxTopics.push(action.payload);
            }
            return { ...initialState, selectedTopics: auxTopics };

        case 'GET_RESOURCES':
            return {
                ...initialState,
                resources: [],
                loading: true,
                totalElements: 0
            };

        case 'GET_RESOURCES_SUCCESS':
            return {
                ...initialState,
                resources: action.payload.content,
                totalElements: action.payload.totalElements,
                totalPages: action.payload.totalPages,
                loading: false
            };


        case 'GET_RESOURCES_FAILURE':
            return {
                ...initialState,
                loading: false
            };

        case 'INITIALIZE_NEW_RESOURCES_FORM':
            return { ...initialState, initializing: true };

        case 'INITIALIZE_NEW_RESOURCES_FORM_SUCCESS':

            return {
                ...initialState, initializing: false, municipalities: action.payload.municipalities,
                categories: action.payload.categories, types: action.payload.types,
            };

        case 'INITIALIZE_NEW_RESOURCES_FORM_FAILURE':
            return { ...initialState, initializing: false };

        case 'SET_COUNTRY':
            return { ...initialState, country: action.payload }

        case 'SET_SELECTED_SITUATION':
            return { ...initialState, selectedSituation: action.payload };

        case 'SET_AUTONOMOUS_COMMUNITY':
            return { ...initialState, autonomousCommunity: action.payload }

        case 'SET_AUTONOMOUS_COMMUNITIES':
            return { ...initialState, autonomousCommunities: action.payload };

        case 'GET_AC_LIST':
            return { ...initialState, autonomousCommunities: [] };

        case 'GET_AC_LIST_SUCCESS':
            return { ...initialState, autonomousCommunities: action.payload };

        case 'GET_AC_LIST_FAILURE':
            return { ...initialState };

        case 'GET_PROVINCES_LIST':
            return { ...initialState, provinces: [] };

        case 'GET_PROVINCES_LIST_SUCCESS':

            return { ...initialState, provinces: action.payload };

        case 'GET_PROVINCES_LIST_FAILURE':
            return { ...initialState };

        case 'SET_PROVINCES_LIST':
            return { ...initialState, provinces: action.payload };

        case 'SET_PROVINCE':
            return { ...initialState, province: action.payload };

        case 'GET_MUNICIPALITIES_LIST':
            return { ...initialState, municipalities: [] };

        case 'GET_MUNICIPALITIES_LIST_SUCCESS':
            return { ...initialState, municipalities: action.payload };

        case 'GET_MUNICIPALITIES_LIST_FAILURE':
            return { ...initialState };

        case 'SET_MUNICIPALITIES_LIST':
            return { ...initialState, municipalities: action.payload };

        case 'SET_MUNICIPALITY':
            return { ...initialState, municipality: action.payload };

        case 'SET_LOCATION':
            return { ...initialState, location: action.payload };

        case 'SET_PAGE_INDEX':
            return { ...initialState, pageIndex: action.payload };

        case 'SET_FIELD':
            return { ...initialState, field: action.payload };

        case 'SET_DIRECTION':
            return { ...initialState, direction: action.payload };

        case 'SET_KEYWORD':
            return { ...initialState, keyword: action.payload };

        case 'SET_NETWORK':
            return { ...initialState, network: action.payload };

        case 'SET_ROAD_TYPE':
            return { ...initialState, roadType: action.payload };

        case 'GET_RESOURCE':
            return { ...initialState, resource: {}, loading: true };

        case 'GET_RESOURCE_SUCCESS':
            return { ...initialState, resource: action.payload, loading: false };

        case 'GET_RESOURCE_FAILURE':
            return { ...initialState, loading: false };

        case 'GET_ALL_TOPICS':
            return { ...initialState };

        case 'GET_ALL_TOPICS_SUCCESS':
            return { ...initialState, topics: action.payload };

        case 'GET_ALL_TOPICS_FAILURE':
            return { ...initialState };

        case 'RENEW_RESOURCE':
            return { ...initialState, loading: true, renew: 0 }

        case 'RENEW_RESOURCE_SUCCESS':
            return { ...initialState, loading: false, renew: 1 }

        case 'RENEW_RESOURCE_FAILURE':
            return { ...initialState, loading: false };

        case 'DELETE_RESOURCE':
            return { ...initialState, loading: true };

        case 'DELETE_RESOURCE_SUCCESS':
            let resourcesToBeFiltered = initialState.resources;
            resourcesToBeFiltered = resourcesToBeFiltered.filter((item) =>
                item.id !== action.payload
            );
            return { ...initialState, loading: false, resources: resourcesToBeFiltered };

        case 'DELETE_RESOURCE_FAILURE':
            return { ...initialState, loading: false, resources: [] };

        case 'SET_PREVIEW':
            return { ...initialState, preview: action.payload };

        case 'SET_PHOTO':
            return { ...initialState, photo: action.payload }

        case 'SET_CATEGORY':
            return { ...initialState, category: action.payload }

        case 'ADD_REMOVE_DETERMINANTE':
            const auxDeterminantes = [...initialState.determinantes]

            auxDeterminantes[action.payload - 1] = auxDeterminantes[action.payload - 1] ? 0 : 1;
            return { ...initialState, determinantes: auxDeterminantes };


        case 'ADD_LOCATION':
            const addLocationAux = [...initialState.locations];
            let location = {
                "municipality": { "name": action.payload.data.municipality },
                "locality": action.payload.data.locality,
                "ubication": action.payload.data.ubication,
                "roadType": { "description": action.payload.data.roadType },
                "street": action.payload.data.street,
                "number": action.payload.data.number,
                "postalCode": action.payload.data.postalCode,
                "xaxis": action.payload.data.xaxis,
                "yaxis": action.payload.data.yaxis
            }
            addLocationAux.push({ ...location })
            return { ...initialState, locations: addLocationAux };

        case 'REMOVE_LOCATION':
            //const removeLocationsAux = [...initialState.locations];
            //removeLocationsAux.splice(removeLocationsAux.indexOf(action.payload), 1)
            const removeLocationsAux = [...initialState.locations.filter((location) => location !== action.payload)];
            return { ...initialState, locations: removeLocationsAux };

        case 'SET_LOCATIONS':
            return { ...initialState, locations: action.payload };

        case 'EDIT_LOCATIONS':
            const locationsAux = [...initialState.locations];
            locationsAux[action.payload.cloneData.locationIndex] = {
                ...action.payload.cloneData
            }
            return { ...initialState, locations: locationsAux }

        default:
            return { ...initialState };
    }
}
