import React, { useState, useEffect } from "react";
import cx from 'classnames';
import styles from './NewResource.module.scss';
import { useHistory } from "react-router-dom";
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useSelector } from '../../state';

const Confirmation = (props) => {

    let history = useHistory();

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const { preview } = useSelector('resources');

    useEffect(() => {
        if (props.resource.description && props.resource.description !== "") {
            const parsed = convertFromRaw(JSON.parse(props.resource.description));
            setEditorState((EditorState.createWithContent(parsed)));
        }

        props.resource.permiso ? props.resource.permiso = 1 : props.resource.permiso = 0;
        
    }, []);

    return (
        <div>
            <div className={cx(styles["header"], "subheader")}>
                <div className={cx(styles["header-text"], "h2-title")}>Formulario Recursos Saludables</div>
            </div>
            <div className={cx(styles["body-style"])}>
                Cumplimente el siguiente formulario para solicitar la adscripción de nueva actividad comunitaria a la Red de Actividades Comunitarias
                (RAC) del Observatorio de Salud en Asturias o bien para modificar una ya existente.
                Para más información <a className="links" onClick={(e) => { history.push('/ayuda'); history.go('/ayuda') }}>puedes acceder a información sobre qué es la RAC y qué tipo de actividades pueden incluirse.</a>
            </div>
            <div className={cx(styles["body-style"])}>
                <div className={cx(styles["icons-container"])}>
                    <div className={"col-xs-4"}>
                        <div className={cx(styles["icon-circle"]) + ' ' + cx(styles["circle-completed"])}><img className={cx(styles["icon-completed"])} src={require("../../images/information.svg").default} /></div>
                        <div className={cx(styles["circle-text"])}>Información del Recurso</div>
                    </div>
                    <div className={"col-xs-4"}>
                        <div className={cx(styles["icon-circle"]) + ' ' + cx(styles["circle-completed"])}><img className={cx(styles["icon-completed"])} src={require("../../images/user.svg").default} /></div>
                        <div className={cx(styles["circle-text"])}>Datos de contacto</div>
                    </div>
                    <div className={"col-xs-4"}>
                        <div className={cx(styles["icon-circle"]) + ' ' + cx(styles["circle-active"])}><img className={cx(styles["icon-active"]) + ' ' + cx(styles["icon-check"])} src={require("../../images/check-relleno.svg").default} /></div>
                        <div className={cx(styles["circle-text"])}>Confimación</div>
                    </div>
                </div>
                <div>
                    <div className={cx(styles["confirmation-element"])}>Nombre: {props.resource.name}</div>
                    <div className={cx(styles["confirmation-element"])}>Breve descripción: {props.resource.summary}</div>
                    {props.resource.description && props.resource.description !== "" ?
                        <div>
                            <div className={cx(styles["confirmation-element"])}>Descripción completa:</div>
                            <div className={cx(styles["confirmation-element"])}>
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="ACToolbar"
                                    wrapperClassName="wrapperClassName"
                                    readOnly
                                />
                            </div>
                        </div>
                        :
                        ""
                    }
                    <div className={cx(styles["confirmation-element"])}>Categoría: {props.resource.category}</div>
                    <div className={cx(styles["confirmation-element"])}>Lugares donde se desarrolla:</div>
                    {props.locations.map((item, index) => {
                            return (
                                <div key={index} className={cx(styles["confirmation-element"])}>
                                    {item.roadType.description !== undefined ? item.roadType.description + " " : ""}
                                    {item.street !== undefined ? item.street + " " : ""}
                                    {item.number !== undefined ? item.number + " " : ""}
                                    {item.postalCode !== undefined ? item.postalCode + " " : ""}
                                    {item.locality !== undefined ? item.locality + " " : ""}
                                    {item.ubication !== undefined ? item.ubication + " " : ""}
                                    {item.municipality.name !== undefined ? item.municipality.name : ""}
                                </div>
                            )
                        })}
                    <div className={cx(styles["confirmation-element"])}>Persona de contacto: {props.resource.contact}</div>
                    <div className={cx(styles["confirmation-element"])}>Mail de contacto: {props.resource.contactMail}</div>
                    <div className={cx(styles["confirmation-element"])}>Teléfono de contacto: {props.resource.phone}</div>
                    {props.resource.webPage && props.resource.webPage !== "" ?
                        <div className={cx(styles["confirmation-element"])}>Página web: {props.resource.webPage}</div>
                        :
                        ""
                    }
                    {props.resource.facebookPage && props.resource.facebookPage !== "" ?
                    <div className={cx(styles["confirmation-element"])}>Página de Facebook: {props.resource.facebookPage}</div>
                        :
                        ""
                    }
                    {props.resource.twitterPage && props.resource.twitterPage !== "" ?
                    <div className={cx(styles["confirmation-element"])}>Página de Twitter: {props.resource.twitterPage}</div>
                        :
                        ""
                    }
                    {preview && preview.length > 0 ?
                        <div className={cx(styles["confirmation-element"])}>Imagen: <img src={preview} alt={preview} className="img-preview" /></div>
                        : ""
                    }
                    <div className={cx(styles["confirmation-element"])}>Has aceptado la política de confidencialidad</div>
                    {props.resource.permiso ? 
                        <div className={cx(styles["confirmation-element"])}>La persona de contacto de la actividad/recurso ha dado su conformidad para recibir mensajes de la web</div>
                     : ""}
                </div>
            </div>

            {props.infoContainer}
            <div className={cx(styles["footer-container"])}>
                <div className={cx(styles["buttons-container-index"])}>
                    <div onClick={() => { props.returnHome() }} className={cx(styles["button-return"], "button")}>Comenzar de nuevo</div>
                    <div onClick={() => { props.backpage() }} className={cx(styles["button-index"], "button")}>Anterior</div>
                    <div 
                        onClick={() => { 
                            if(!props.loading){
                                props.post() 
                            }
                        }} 
                        className={cx(styles["button-index"], "button")}>Enviar</div>
                </div>
            </div>
        </div>
    );
}

export default Confirmation;