import React from "react";
import Toolbar from '@material-ui/core/Toolbar';
import Navbar from "views/layout/PublicLayout/NavBar";

const Header = (props) => {

    return (
        <Toolbar>
            <Navbar />
        </Toolbar>
    );

}

export default Header;