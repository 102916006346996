import React, { useReducer } from 'react';
import combineReducers from 'react-combine-reducers';
import { activitiesReducer, initialActivitiesState } from './activities';
import { resourceReducer, initialResourceState } from './resources';
import { authReducer, initialAuthState } from './auth';

const StoreStateContext = React.createContext();
const StoreDispachContext = React.createContext();

export const useSelector = (state) => {
    const context = React.useContext(StoreStateContext);
    if (context === undefined) {
        throw new Error("useSelector must be used within a StoreStateProvider");
    }

    return context[state];
}

export const  useDispatch = () =>{
    const context = React.useContext(StoreDispachContext);

    if (context === undefined) {
        throw new Error("useDispacht must be used within a StoreDispachContext");
    }

    return context;
}

export const StoreProvider = ({children}) => {
    
    const [stateReducer, InitialState] = combineReducers({
        activities: [activitiesReducer, initialActivitiesState],
        resources: [resourceReducer, initialResourceState],
        auth: [authReducer, initialAuthState]
    });
    
    const [state, dispatch] = useReducer(stateReducer, InitialState);

    return (
        <StoreStateContext.Provider value={state}>
            <StoreDispachContext.Provider value={dispatch}>
                {children}
            </StoreDispachContext.Provider>
        </StoreStateContext.Provider>
    )
}