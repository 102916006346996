import React from "react";
import obsa from "../../../images/obsa_icon_hd_color.png";
import styles from "./NavBar.module.scss";
import cx from 'classnames';
import hearticon from '../../../images/heart.svg'
import searchicon from '../../../images/search.svg';
import briefcaseicon from '../../../images/briefcase.svg';
import helpicon from '../../../images/help.svg';
import usericon from '../../../images/user.svg';
import adminicon from '../../../images/wrench-solid.svg';
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from "state";
import { logout } from "state/auth/actions";
import { roles } from "utils/enums";

const Navbar = (props) => {
    let history = useHistory();
    const [toggle, setToggle] = useState(false);
    const [tab, setTab] = useState();
    const [isTouching, setIsTouching] = useState();
    const dispatch = useDispatch();

    const { token, email, role } = useSelector('auth');

    const handleOnClick = (route, tab) => {

        const width = window.innerWidth;

        if(width > 984){
            history.push(route);
            history.go(route);
        }else{
            showMoreOptions(tab, true);
        }

    }


    const showMoreOptions = (num, verify) => {
        setToggle(verify);
        setTab(num);
    }


    const cerrarSesion = () => {
        logout(dispatch);
    }

    return (
        <>
            <div className={cx(styles["navbar"])}>
                <div className={cx(styles["navbar-left-item"])}>
                    <a href="https://obsaludasturias.com/obsa/" target="_blank"><div className={cx(styles["left-logo"])}><img src={obsa} className={cx(styles["logo"])} /></div></a>
                    <div onClick={(e) => {
                        history.push('/inicio');
                        history.go('/inicio');
                    }} className={cx(styles["left-text"])}>ACTIVOS DE SALUD</div>
                </div>
                <ul className={cx(styles["navbar-right-item"])}>
                    <div 
                    onClick={(e) => {
                        history.push('/buscador');
                        history.go('/buscador');
                    }} className={cx(styles["items"])}>
                        <div className="icon-flex"><img src={searchicon} className={cx(styles["icons"])} /></div>
                        <div className={cx(styles["navbar-labels"])}>Buscador</div>
                    </div>

                    <div className={cx(styles["items"])} 
                        onClick={() => handleOnClick('/actividad/consultar', 1)}
                        onMouseEnter={() => showMoreOptions(1, true)}
                        onMouseLeave={() => setToggle(false)}>
                        <div className="icon-flex"><img src={hearticon} className={cx(styles["icons"])} /></div>
                        <div className={cx(styles["navbar-labels"])}>Actividades</div>
                    </div>

                    <div className={cx(styles["items"])} 
                        onClick={() => handleOnClick('/recurso/consultar', 2)}
                        onTouchStart={() => showMoreOptions(2, true)}
                        onMouseEnter={() => showMoreOptions(2, true)} onMouseLeave = {() => setToggle(false)}>
                            <div className="icon-flex"><img src={briefcaseicon} className={cx(styles["icons"])} /></div>
                            <div className={cx(styles["navbar-labels"])}>Recursos</div>
                    </div>

                    <div className={cx(styles["items"])} onClick={(e) => {
                        history.push('/ayuda');
                        // history.go('/ayuda');
                    }}>
                        <div className="icon-flex"><img src={helpicon} className={cx(styles["icons"])} /></div>
                        <div className={cx(styles["navbar-labels"])}>Ayuda</div>
                    </div>

                    {
                        token && role=='ROLE_ADMIN' ? 
                            <div className={cx(styles["items"])} onClick={(e) => { 
                                history.push('/admin');
                            }}>
                                <div className="icon-flex"><img src={adminicon} className={cx(styles["icons"])} /></div>
                                <div className={token && role=='ROLE_ADMIN' ? cx(styles["items-logged"], styles["navbar-labels"]) : null}>Administración</div>
                            </div>
                        : ""
                    }
                    {token ?
                    <>
                        <div onMouseEnter={() => showMoreOptions(3, true)} className={cx(styles["navbar-access"])}>
                            <div className="icon-flex"><img src={usericon} className={cx(styles["icons"])} /></div>
                            <div className={token ? cx(styles["items-logged"]) : null}>{email}</div>
                        </div>
                        <div className={cx(styles["items"], styles["session-btn"])} onClick={() => { cerrarSesion() }}>
                            <img className={cx(styles["icons"])} src={require('../../../images/power-off-solid.svg').default} />
                        </div>
                    </>
                        :
                        <div onClick={() => { history.push('/login'); history.go('/login'); }} className={cx(styles["navbar-access-login"])}>
                            <div className="icon-flex"><img src={usericon} className={cx(styles["icons"])} /></div>
                            <div>Acceso</div>
                        </div>
                    }
                </ul>
            </div>

            

            {toggle === true ?
                <div>
                    {tab === 1 ?
                        <div className={ token && token && role === roles.ADMIN ? cx(styles["activityDropdown-admin"]) 
                        : token && role === roles.USER ? cx(styles["activityDropdown-user"]) 
                        : cx(styles["activityDropdown"])} onMouseEnter={() => showMoreOptions(1,true)} onMouseLeave={() => showMoreOptions(1, false)}>
                            <div className={cx(styles["dropdown-item"])} onClick={(e) => {
                                history.push('/actividad/consultar');
                                history.go('/actividad/consultar');
                            }}>
                                <img className={"icons" + ' ' + cx(styles["dropdown-icons"])} src={require('../../../images/bookmark.svg').default} />
                                Mis Actividades
                            </div>
                            <div className={cx(styles["dropdown-item"])} onClick={(e) => {
                                dispatch({ type: "SET_ACTIVITY" });
                                history.push('/actividad/nueva');
                                history.go('/actividad/nueva');
                            }}>
                                <img className={"icons" + ' ' + cx(styles["dropdown-icons"])} src={require('../../../images/bigplus.svg').default} />
                                Nueva Actividad
                            </div>
                        </div>
                        : ""
                    }
                    {tab === 2 ?
                        <div className={ token && token && role === roles.ADMIN ? cx(styles["resourceDropdown-admin"]) 
                        : token && role === roles.USER ? cx(styles["resourceDropdown-user"]) 
                        : cx(styles["resourceDropdown"])} onMouseEnter={() => showMoreOptions(2,true)} onMouseLeave={() => showMoreOptions(2, false)}>
                            <div className={cx(styles["dropdown-item"])} onClick={(e) => {
                                history.push('/recurso/consultar');
                                history.go('/recurso/consultar');
                            }}>
                                <img className={"icons" + ' ' + cx(styles["dropdown-icons"])} src={require('../../../images/bookmark.svg').default} />
                                Mis Recursos
                            </div>
                            <div className={cx(styles["dropdown-item"])} onClick={(e) => {
                                dispatch({ type: "SET_RESOURCE" });
                                history.push('/recurso/nuevo');
                                history.go('/recurso/nuevo');
                            }}>
                                <img className={"icons" + ' ' + cx(styles["dropdown-icons"])} src={require('../../../images/bigplus.svg').default} />
                                Nuevo Recurso
                            </div>
                        </div>
                        : ""
                    }
                    {tab === 3 ?
                        <div className={cx(styles["userDropdown"])} >
                            <div className={cx(styles["dropdown-item"])} onClick={() => { cerrarSesion() }} onMouseLeave={() => showMoreOptions(3, false)}>
                                <img className={cx(styles["dropdown-icons"], "icons")} src={require('../../../images/power-off-solid.svg').default} />
                                Cerrar sesión
                            </div>
                        </div>
                        : ""
                    }
                </div> : ""

            }
        </>
    );

}

export default Navbar;
