import { country_enum, cautonoma_enum, provincia_enum, municipio_enum } from "utils/enums";

export const initialActivitiesState = {
    activities: [],
    activity: {},
    activityOk: false,
    resources: [],
    resource: {},
    locations: [],
    mapElements: [],
    location: {},
    temporality: '',
    temporalitiesList: [],
    temporalities: [],
    demgr: [],
    demographicGroup: '',
    countries: [],
    country: country_enum.SPAIN,
    ca: [],
    autonomousCommunity: cautonoma_enum.ASTURIAS,
    pr: [],
    province: provincia_enum.ASTURIAS,
    municipalities: [],
    municipality: '',
    topics: [],
    selectedTopics: [],
    determinantes: [0, 0, 0, 0, 0],
    locality: '',
    ubication: '',
    street: '',
    portalNumber: '',
    postalCode: '',
    selectedSituation: 1,
    totalElements: 0,
    initializing: false,
    population: [],
    genders: [],
    gender: '',
    freeList: [],
    free: '',
    pageIndex: 0,
    totalPages: 1,
    direction: "DESC",
    field: "id",
    loading: false,
    categories: [],
    category: '',
    tableElementsType: '',
    types: [],
    type: '',
    keyword: '',
    tableKeyword: '',
    tableElements: [],
    xaxis: 43.3622522,
    yaxis: -5.8485461,
    networks: [],
    network: '',
    typeActivity: 1,
    photo: [],
    description: {},
    preview: [],
    validated: '',
    renew: 0
};


export const activitiesReducer = (initialState = initialActivitiesState, action) => {

    switch (action.type) {

        case 'SET_PREVIEW':
            return { ...initialState, preview: action.payload }

        case 'SET_DESCRIPTION':
            return { ...initialState, description: action.payload }

        case 'SET_PHOTO':
            return { ...initialState, photo: action.payload }

        case 'SET_TYPE_ACTIVITY':
            return { ...initialState, typeActivity: action.payload }

        case 'SET_ADMIN_VIEW':
            return { ...initialState, adminView: action.payload };

        case 'SET_VALIDATED':
            return { ...initialState, validated: action.payload }

        case 'RENEW_ACTIVITY':
            return { ...initialState, loading: true, renew: 0 }

        case 'RENEW_ACTIVITY_SUCCESS':
            return { ...initialState, loading: false, renew: 1 }

        case 'RENEW_ACTIVITY_FAILURE':
            return { ...initialState, loading: false, renew: 0 };

        case 'SET_TYPE':
            return { ...initialState, type: action.payload }

        case 'SET_XAXIS':
            return { ...initialState, xaxis: action.payload }

        case 'SET_YAXIS':
            return { ...initialState, yaxis: action.payload }

        case 'SET_TABLE_KEYWORD':
            return { ...initialState, tableKeyword: action.payload }

        case 'SET_KEYWORD':
            return { ...initialState, keyword: action.payload }

        case 'SET_TABLE_ELEMENTS_TYPE':
            return { ...initialState, tableElementsType: action.payload }

        case 'GET_TYPE':
            return { ...initialState }

        case 'GET_ALL_TYPES':
            return { ...initialState };

        case 'GET_ALL_TYPES_SUCCESS':
            return { ...initialState, types: action.payload };

        case 'SET_ALL_TYPES':
            return { ...initialState, types: action.payload };

        case 'SET_CATEGORY':
            return { ...initialState, category: action.payload }

        case 'GET_CATEGORY':
            return { ...initialState }

        case 'GET_ALL_CATEGORIES':
            return { ...initialState };

        case 'GET_ALL_CATEGORIES_SUCCESS':
            return { ...initialState, categories: action.payload };

        case 'SET_ALL_CATEGORIES':
            return { ...initialState, categories: action.payload };

        case 'SET_TOTAL_ELEMENTS':
            return { ...initialState, totalElements: action.payload }

        case 'SET_DIRECTION':
            return { ...initialState, direction: action.payload }

        case 'GET_DIRECTION':
            return { ...initialState }

        case 'SET_FIELD':
            return { ...initialState, field: action.payload }

        case 'GET_FIELD':
            return { ...initialState }

        case 'SET_TOTAL_PAGES':
            return { ...initialState, totalPages: action.payload }

        case 'GET_TOTAL_PAGES':
            return { ...initialState }

        case 'SET_PAGE_INDEX':
            return { ...initialState, pageIndex: action.payload }

        case 'GET_PAGE_INDEX':
            return { ...initialState }

        case 'GET_PAGE_INDEX_SUCCESS':
            return { ...initialState, pageIndex: action.payload }

        case 'SET_AUTONOMOUS_COMMUNITY':
            return { ...initialState, autonomousCommunity: action.payload }

        case 'SET_PROVINCE':
            return { ...initialState, province: action.payload }

        case 'SET_MUNICIPALITY':
            return { ...initialState, municipality: action.payload }

        case 'GET_MUNICIPALITY':
            return { ...initialState }

        case 'SET_COUNTRY':
            return { ...initialState, country: action.payload }

        case 'GET_ALL_COUNTRIES':
            return { ...initialState };

        case 'GET_ALL_COUNTRIES_SUCCESS':
            return { ...initialState, countries: action.payload };

        case 'GET_DEMGR':
            return { ...initialState };

        case 'GET_DEMGR_SUCCESS':
            return { ...initialState, demgr: action.payload };

        case 'GET_DEMGR_FAILURE':
            return { ...initialState };

        case 'GET_DEMOGRAPHIC_GROUP':
            return { ...initialState }

        case 'SET_DEMOGRAPHIC_GROUP':
            return { ...initialState, demographicGroup: action.payload }

        case 'GET_GENDERS':
            return { ...initialState };

        case 'GET_GENDERS_SUCCESS':
            return { ...initialState, genders: action.payload };

        case 'GET_GENDERS_FAILURE':
            return { ...initialState };

        case 'SET_GENDER':
            return { ...initialState, gender: action.payload };

        case 'GET_GENDER':
            return { ...initialState };

        case 'GET_FREE_LIST':
            return { ...initialState };

        case 'GET_FREE_LIST_SUCCESS':
            return { ...initialState, freeList: action.payload };

        case 'GET_FREE_LIST_FAILURE':
            return { ...initialState };

        case 'SET_FREE':
            return { ...initialState, free: action.payload };

        case 'SET_TEMPORALITY':
            return { ...initialState, temporality: action.payload };

        case 'SET_POPULATION':
            return { ...initialState, population: action.payload };

        case 'GET_ALL_POPULATION':
            return { ...initialState };

        case 'GET_ALL_POPULATION_SUCCESS':
            return { ...initialState, population: action.payload };

        case 'GET_ALL_TOPICS':
            return { ...initialState };

        case 'GET_ALL_TOPICS_SUCCESS':
            return { ...initialState, topics: action.payload };

        case 'GET_ALL_TOPICS_FAILURE':
            return { ...initialState };

        case 'ADD_REMOVE_SELECTED_TOPICS':
            const auxTopics = [...initialState.selectedTopics]
            if (auxTopics.includes(action.payload)) {
                auxTopics.splice(auxTopics.indexOf(action.payload), 1);
            } else {
                auxTopics.push(action.payload);
            }
            return { ...initialState, selectedTopics: auxTopics };

        case 'ADD_REMOVE_DETERMINANTE':
            const auxDeterminantes = [...initialState.determinantes]

            auxDeterminantes[action.payload - 1] = auxDeterminantes[action.payload - 1] ? 0 : 1;
            return { ...initialState, determinantes: auxDeterminantes };


        case 'GET_ALL_CA':
            return { ...initialState, ca: [] };

        case 'SET_CA':
            return { ...initialState, ca: action.payload };

        case 'GET_ALL_CA_SUCCESS':
            return { ...initialState, ca: action.payload };

        case 'GET_ALL_PR':
            return { ...initialState, pr: [] };

        case 'SET_PR':
            return { ...initialState, pr: action.payload };

        case 'GET_PR_CA_SUCCESS':
            return { ...initialState, pr: action.payload };

        case 'SET_ALL_PR':
            return { ...initialState, pr: action.payload };

        case 'GET_ALL_MUNICIPALITIES':
            return { ...initialState, municipalities: [] };

        case 'GET_MU_PR_SUCCESS':
            return { ...initialState, municipalities: action.payload };

        case 'SET_MUNICIPALITIES':
            return { ...initialState, municipalities: action.payload };

        case 'GET_ACTIVITIES':
            return {
                ...initialState,
                activities: [],
                loading: true,
                totalElements: 0
            };

        case 'GET_ACTIVITIES_SUCCESS':
            return {
                ...initialState,
                activities: action.payload.content,
                totalElements: action.payload.totalElements,
                totalPages: action.payload.totalPages,
                loading: false
            };

        case 'GET_ACTIVITIES_FAILURE':
            return {
                ...initialState,
                loading: false
            };

        case 'GET_LOCATIONS':
            return {
                ...initialState,
                locations: []
            }

        case 'GET_LOCATIONS_SUCCESS':
            return {
                ...initialState,
                locations: action.payload
            }

        case 'GET_LOCATIONS_FAILURE':
            return {
                ...initialState,
            }

        case 'SEARCH_TABLE_ELEMENTS':
            return { ...initialState, activities: [], loading: true };

        case 'SEARCH_TABLE_ELEMENTS_SUCCESS':
            return {
                ...initialState, tableElements: action.payload.content,
                totalElements: action.payload.totalElements, totalPages: action.payload.totalPages, loading: false
            };

        case 'SEARCH_TABLE_ELEMENTS_FAILURE':
            return { ...initialState, loading: false };

        case 'GET_RESOURCES':
            return { ...initialState, resource: [] };

        case 'GET_RESOURCES_SUCCESS':
            return { ...initialState, resource: action.payload };

        case 'GET_RESOURCES_FAILURE':
            return { ...initialState };

        case 'SEARCH_RESOURCES':
            return { ...initialState, resources: [], loading: true };

        case 'SEARCH_RESOURCES_SUCCESS':
            return { ...initialState, resources: action.payload, loading: false };

        case 'SEARCH_RESOURCES_FAILURE':
            return { ...initialState, loading: false };

        case 'SET_LOCALITY':
            return { ...initialState, locality: action.payload };

        case 'SET_UBICATION':
            return { ...initialState, ubication: action.payload };

        case 'SET_STREET':
            return { ...initialState, street: action.payload };

        case 'SET_PORTAL_NUMBER':
            return { ...initialState, portalNumber: action.payload };

        case 'SET_POSTAL_CODE':
            return { ...initialState, postalCode: action.payload };

        case 'SET_SELECTED_SITUATION':
            return { ...initialState, selectedSituation: action.payload };

        case 'SET_ACTIVITY':
            return { ...initialState, activity: action.payLoad };

        case 'SET_ACTIVITY_SUCCESS':
            console.log("HOLA")
            return { ...initialState, activityOk: true };

        case 'RESET_ACTIVITY_SUCCESS':
            return { ...initialState, activityOk: false };

        case 'GET_ACTIVITY':
            return { ...initialState, activity: {}, loading: true };

        case 'GET_ACTIVITY_SUCCESS':
            return { ...initialState, activity: action.payload, loading: false };

        case 'GET_ACTIVITY_FAILURE':
            return { ...initialState, loading: false };

        case 'DELETE_ACTIVITY':
            return { ...initialState, loading: true };

        case 'DELETE_ACTIVITY_SUCCESS':
            let activitiesToBeFiltered = initialState.activities;
            activitiesToBeFiltered = activitiesToBeFiltered.filter((item) =>
                item.id !== action.payload
            );
            return { ...initialState, loading: false, activities: activitiesToBeFiltered };

        case 'DELETE_ACTIVITY_FAILURE':
            return { ...initialState, loading: false, activities: [] };

        case 'GET_COUNTRY':
            return { ...initialState, activity: {}, loading: true };

        case 'GET_COUNTRY_SUCCESS':
            return { ...initialState, activity: action.payload, loading: false };

        case 'GET_COUNTRY_FAILURE':
            return { ...initialState, loading: false };

        case 'UPDATE_ACTIVITY_DATA':
            const activityAux = { ...initialState.activity, ...action.payload };

            return {
                ...initialState, activity: activityAux
            };

        case 'ADD_LOCATION':
            const addLocationAux = [...initialState.locations];
            let location = {
                "municipality": { "name": action.payload.data.municipality },
                "locality": action.payload.data.locality,
                "ubication": action.payload.data.ubication,
                "roadType": { "description": action.payload.data.roadType },
                "street": action.payload.data.street,
                "number": action.payload.data.number,
                "postalCode": action.payload.data.postalCode,
                "xaxis": action.payload.data.xaxis,
                "yaxis": action.payload.data.yaxis
            }
            addLocationAux.push({ ...location })
            return { ...initialState, locations: addLocationAux };

        case 'REMOVE_LOCATION':
            //const removeLocationsAux = [...initialState.locations];
            //removeLocationsAux.splice(removeLocationsAux.indexOf(action.payload), 1)
            const removeLocationsAux = [...initialState.locations.filter((location) => location !== action.payload)];
            return { ...initialState, locations: removeLocationsAux };

        case 'SET_LOCATIONS':
            return { ...initialState, locations: action.payload };

        case 'SET_LOADING':
            return { ...initialState, loading: action.payload };

        case 'SET_MAP_ELEMENTS':
            return { ...initialState, mapElements: [] };

        case 'SET_MAP_ELEMENTS_SUCCESS':
            return { ...initialState, mapElements: action.payload };

        case 'SET_MAP_ELEMENTS_FAILURE':
            return { ...initialState, mapElements: [] };

        case 'SET_LOCATION':
            return { ...initialState, location: action.payload };

        case 'EDIT_LOCATIONS':
            const locationsAux = [...initialState.locations];
            locationsAux[action.payload.cloneData.locationIndex] = {
                ...action.payload.cloneData
            }
            return { ...initialState, locations: locationsAux }

        case 'SET_TEMPORALITIES':
            return { ...initialState, temporalities: action.payload };

        case 'EDIT_TEMPORALITIES':
            const temporalitiesAux = [...initialState.temporalities];
            temporalitiesAux[action.payload.data.temporalityIndex] = {
                "end_date": action.payload.data.end_date,
                "start_date": action.payload.data.start_date,
            }

            return { ...initialState, temporalities: temporalitiesAux };

        case 'REMOVE_TEMPORALITIES':
            const removeTemporalityAux = [...initialState.temporalities.filter((temporality) => temporality !== action.payload.temporality)];
            return { ...initialState, temporalities: removeTemporalityAux };

        case 'ADD_TEMPORALITIES':
            const addTemporalityAux = [...initialState.temporalities];
            addTemporalityAux.push({ "start_date": action.payload.data.start_date, "end_date": action.payload.data.end_date })
            return { ...initialState, temporalities: addTemporalityAux };

        case 'INITIALIZE_SEARCHER':
            return {
                ...initialState,
                //selectedTopics: [],
                initializing: true
            };

        case 'INITIALIZE_SEARCHER_SUCCESS':
            return {
                ...initialState,
                topics: action.payload.topics,
                municipalities: action.payload.municipalities,
                totalPages: action.payload.totalPages, totalElements: action.payload.totalElements,
                tableElements: action.payload.tableElements, genders: action.payload.genders,
                demgr: action.payload.demgr, initializing: false,
            };

        case 'INITIALIZE_SEARCHER_FAILURE':
            return { ...initialState, initializing: false };

        case 'INITIALIZE_UBICATIONS':
            return { ...initialState, initializing: true };

        case 'INITIALIZE_UBICATIONS_SUCCESS':

            return {
                ...initialState, municipalities: action.payload.municipalities, types: action.payload.types, initializing: false,
            };

        case 'INITIALIZE_UBICATIONS_FAILURE':
            return { ...initialState, initializing: false };

        case 'INITIALIZE_NEW_ACTIVITIES_FORM':
            return { ...initialState, initializing: true };

        case 'INITIALIZE_NEW_ACTIVITIES_FORM_SUCCESS':

            return {
                ...initialState, initializing: false,
                temporalitiesList: action.payload.temporalitiesList, freeList: action.payload.freeList, genders: action.payload.genders,
                municipalities: action.payload.municipalities, types: action.payload.types, demgr: action.payload.demgr,
            };

        case 'INITIALIZE_NEW_ACTIVITIES_FORM_FAILURE':
            return { ...initialState, initializing: false };

        case 'SET_NETWORK':
            return { ...initialState, network: action.payload }

        default:
            return { ...initialState };
    }
}
