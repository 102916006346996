import React from "react";
import { useState } from "react";
import cx from 'classnames';
import styles from './Help.module.scss'
import plus from '../../images/plus.svg';
import minus from '../../images/minus.svg';
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

const Help = (props) => {

    const [tabs, setTab] = useState(1);
    const [result, setResult] = useState(1);
    let history = useHistory();
    const { formatMessage: f } = useIntl();

    /* {f({ id: "forms.info" })} */

    const chanegResult = (tab) => {
        if (tab === result) {
            setResult(0);
        } else {
            setResult(tab);
        }
    }

    return (
        <div className={cx(styles["main-container"])}>
            <div className={cx(styles["elements-container"]) + ' ' + "row"}>
                <div className={cx(styles["columns"], styles["column-left"]) + ' ' + "col-lg-3"}>
                    <div onClick={(e) => { setTab(1); chanegResult(1) }}
                        className={tabs === 1
                            ? cx(styles["elements"], styles["elements-active"])
                            : cx(styles["elements"])}>{f({ id: "help.left.list.text1" })}
                    </div>
                    <div onClick={(e) => { setTab(2); chanegResult(7) }}
                        className={tabs === 2 ? cx(styles["elements"], styles["elements-active"])
                            : cx(styles["elements"])}>{f({ id: "help.left.list.text2" })}
                    </div>
                    <div onClick={(e) => { setTab(3); chanegResult(14) }}
                        className={tabs === 3 ? cx(styles["elements"], styles["elements-active"])
                            : cx(styles["elements"])}>{f({ id: "help.left.list.text3" })}
                    </div>
                    <div onClick={(e) => { setTab(4); chanegResult(15) }}
                        className={tabs === 4 ? cx(styles["elements"], styles["elements-active"])
                            : cx(styles["elements"])}>{f({ id: "help.left.list.text4" })}
                    </div>
                </div>
                <div className={cx(styles["columns"], styles["column-right"]) + ' ' + "col-lg-9 text-justify"}>
                    {tabs === 1 ?
                        <div className={cx(styles["questions"])}>
                            <div className={cx(styles["question"])} onClick={(e) => { chanegResult(1) }}>
                                {f({ id: "help.question1" })}
                                <img src={result === 1 ? minus : plus} className={cx(styles["icons"])} />
                                {result === 1 ?
                                    <div className={cx(styles["answer"])}>
                                        <p>{f({ id: "help.answer1" })}</p>
                                        <p>{f({ id: "help.answer1-2" })}</p>
                                    </div> : ""}
                            </div>
                            <div className={cx(styles["question"])} onClick={(e) => { chanegResult(2) }}>
                                {f({ id: "help.question2" })}
                                <img src={result === 2 ? minus : plus} className={cx(styles["icons"])} />
                                {result === 2 ?
                                    <div className={cx(styles["answer"])}>
                                        <p>{f({ id: "help.answer2" })}</p>
                                    </div> : ""}
                            </div>
                            <div className={cx(styles["question"])} onClick={(e) => { chanegResult(3) }}>
                                {f({ id: "help.question3" })}
                                <img src={result === 3 ? minus : plus} className={cx(styles["icons"])} />
                                {result === 3 ?
                                    <div className={cx(styles["answer"])}>
                                        <p>{f({ id: "help.answer3" })}</p>
                                        <ul className={cx(styles["answer"])}>
                                            <li>{f({ id: "help.answer3-2" })}</li>
                                            <li>{f({ id: "help.answer3-3" })}</li>
                                            <li>{f({ id: "help.answer3-4" })}</li>
                                            <li>{f({ id: "help.answer3-5" })}
                                            </li>
                                            <li>{f({ id: "help.answer3-6" })}</li>
                                        </ul>
                                    </div> : ""}
                            </div>
                            <div className={cx(styles["question"])} onClick={(e) => { chanegResult(4) }}>
                                {f({ id: "help.question4" })}
                                <img src={result === 4 ? minus : plus} className={cx(styles["icons"])} />
                                {result === 4 ?
                                    <div className={cx(styles["answer"])}>
                                        <p>{f({ id: "help.answer4" })}</p>
                                        <p>{f({ id: "help.answer4-2" })}</p>
                                    </div> : ""}
                            </div>
                            <div className={cx(styles["question"])} onClick={(e) => { chanegResult(5) }}>
                                {f({ id: "help.question5" })}
                                <img src={result === 5 ? minus : plus} className={cx(styles["icons"])} />
                                {result === 5 ?
                                    <div className={cx(styles["answer"])}>
                                        <p>{f({ id: "help.answer5" })} <a>{f({ id: "help.answer5-2" })}</a> <a>{f({ id: "help.answer5-3" })}</a>
                                        </p>
                                    </div> : ""}
                            </div>
                            <div className={cx(styles["question"])} onClick={(e) => { chanegResult(6) }}>
                                {f({ id: "help.question6" })}
                                <img src={result === 6 ? minus : plus} className={cx(styles["icons"])} />
                                {result === 6 ?
                                    <div className={cx(styles["answer"])}>
                                        <p>{f({ id: "help.answer6" })} <a>{f({ id: "help.email" })}</a></p>
                                    </div> : ""}
                            </div>
                        </div> : tabs === 2 ?
                            <div className={cx(styles["questions"])}>
                                <div className={cx(styles["question"])} onClick={(e) => { chanegResult(7) }}>
                                    {f({ id: "help.question7" })}
                                    <img src={result === 7 ? minus : plus} className={cx(styles["icons"])} />
                                    {result === 7 ?
                                        <div className={cx(styles["answer"])}>
                                            <p>{f({ id: "help.answer7" })}</p>
                                        </div> : ""}
                                </div>
                                <div className={cx(styles["question"])} onClick={(e) => { chanegResult(8) }}>
                                    {f({ id: "help.question8" })}
                                    <img src={result === 8 ? minus : plus} className={cx(styles["icons"])} />
                                    {result === 8 ?
                                        <div className={cx(styles["answer"])}>
                                            <p>{f({ id: "help.answer8-1" })}</p>
                                            <p>{f({ id: "help.answer8-2" })}</p>
                                        </div> : ""}
                                </div>
                                <div className={cx(styles["question"])} onClick={(e) => { chanegResult(9) }}>
                                    {f({ id: "help.question9" })}
                                    <img src={result === 9 ? minus : plus} className={cx(styles["icons"])} />
                                    {result === 9 ?
                                        <div className={cx(styles["answer"])}>
                                            <p>{f({ id: "help.answer9-1" })}</p>
                                            <p>{f({ id: "help.answer9-2" })}</p>
                                        </div> : ""}
                                </div>
                                <div className={cx(styles["question"])} onClick={(e) => { chanegResult(10) }}>
                                    {f({ id: "help.question10" })}
                                    <img src={result === 10 ? minus : plus} className={cx(styles["icons"])} />
                                    {result === 10 ?
                                        <div className={cx(styles["answer"])}>
                                            <p>{f({ id: "help.answer10" })}</p>
                                        </div> : ""}
                                </div>
                                <div className={cx(styles["question"])} onClick={(e) => { chanegResult(11) }}>
                                    {f({ id: "help.question11" })}
                                    <img src={result === 11 ? minus : plus} className={cx(styles["icons"])} />
                                    {result === 11 ?
                                        <div className={cx(styles["answer"])}>
                                            <p>{f({ id: "help.answer11-1" })}<a onClick={(e) => { history.push('/buscador'); history.go('/buscador') }}>{f({ id: "help.answer11-2" })}</a></p>
                                        </div> : ""}
                                </div>
                                <div className={cx(styles["question"])} onClick={(e) => { chanegResult(12) }}>
                                    {f({ id: "help.question12" })}
                                    <img src={result === 12 ? minus : plus} className={cx(styles["icons"])} />
                                    {result === 12 ?
                                        <div className={cx(styles["answer"])}>
                                            <p>{f({ id: "help.answer12" })}</p>
                                        </div> : ""}
                                </div>
                                <div className={cx(styles["question"])} onClick={(e) => { chanegResult(13) }}>
                                    {f({ id: "help.question13" })}
                                    <img src={result === 13 ? minus : plus} className={cx(styles["icons"])} />
                                    {result === 13 ?
                                        <div className={cx(styles["answer"])}>
                                            <p>{f({ id: "help.answer13-1" })}</p>
                                            <p>{f({ id: "help.answer13-2" })}</p>
                                        </div> : ""}
                                </div>
                            </div> : tabs === 3 ?
                                <div className={cx(styles["questions"])}>
                                    <div className={cx(styles["question"])} onClick={(e) => { chanegResult(14) }}>
                                    {f({ id: "help.question14" })}
                                        <img src={result === 14 ? minus : plus} className={cx(styles["icons"])} />
                                        {result === 14 ?
                                            <div className={cx(styles["answer"])}>
                                                <p>{f({ id: "help.answer14-1" })}<a>{f({ id: "help.answer14-4" })}</a></p>
                                            </div> : ""}
                                    </div>
                                </div> : tabs === 4 ?
                                    <div className={cx(styles["questions"])}>
                                        <div className={cx(styles["question"])} onClick={(e) => { chanegResult(15) }}>
                                        {f({ id: "help.question15" })}
                                            <img src={result === 15 ? minus : plus} className={cx(styles["icons"])} />
                                            {result === 15 ?
                                                <div className={cx(styles["answer"])}>
                                                    <p>{f({ id: "help.answer15-1" })}<a>{f({ id: "help.email" })}</a></p>
                                                    <p>{f({ id: "help.answer15-2" })}</p>
                                                </div> : ""}
                                        </div>
                                    </div> : ""
                    }
                </div>
            </div>
        </div>
    )

}

export default Help;