import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from '../../state';
import { useIntl } from "react-intl";
import ReactLoading from 'react-loading';
import PaginationComponent from "components/pagination/PaginationComponent";
import styles from './Resources.module.scss'
import cx from 'classnames';

import { getResources } from '../../state/resources/actions';

const Resources = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { formatMessage: f } = useIntl();
    const { resources, totalPages, loading, pageIndex, field, direction, keyword, typeResource } = useSelector("resources");
    const { userId, email } = useSelector("auth");

    useEffect(() => {
        const getResourcesData = async () => {
            await getResources(dispatch, { userId: userId, pageIndex: pageIndex, field: field, direction: direction, typeResource: typeResource,
            email: email });
        };
        getResourcesData();
    }, []);

    useEffect(() => {
        const updateTableResources = async () => {
            await getResources(dispatch, {
                userId: userId, keyword: keyword, pageIndex: pageIndex, field: field, direction: direction, type: "R", typeResource: typeResource,
                email: email
            });
        }
        updateTableResources();
    }, [field, direction, pageIndex, keyword, typeResource]);

    const sorting = (selectedField) => {
        return (field === selectedField ? direction === 'ASC' ? 'asc' : 'desc' : 'sorting')
    }

    const changeSort = (selectedField) => {

        dispatch({ type: 'SET_PAGE_INDEX', payload: 0 });

        if (selectedField !== field) {
            dispatch({ type: 'SET_FIELD', payload: selectedField });
            dispatch({ type: 'SET_DIRECTION', payload: "ASC" });
        } else if (direction === "ASC") {
            dispatch({ type: 'SET_DIRECTION', payload: "DESC" });
        } else {
            dispatch({ type: 'SET_DIRECTION', payload: "ASC" });
        }

    }

    const changePageIndex = (pageIndex) => {
        dispatch({ type: 'SET_PAGE_INDEX', payload: pageIndex });
    }

    const changeKeyword = (keyword) => {
        dispatch({ type: 'SET_KEYWORD', payload: keyword });
    }

    const changeTypeResource = (typeResource) => {
        dispatch({ type: 'SET_TYPE_RESOURCE', payload: typeResource })
    }

    const goToView = (id) => {
        history.push(`/recurso/consultar/${id}`);
    };

    const goToEdit = (id) => {
        history.push(`/recurso/editar/${id}`);
    };


    return (
        <div className={cx(styles["main-container"])}>

            <div className={"subheader" + ' ' + cx(styles["subheader-activities"])}>
                <div className='h2-title'>Lista de recursos</div>
            </div>

            <div className={cx(styles['filters-container'])}>
                <div className={cx(styles['filters'])}>
                    <div onClick={() => { changeTypeResource(1) }} className={typeResource === 1 ? cx(styles['filter'], styles["filter-active"], "button") : cx(styles['filter'], "button")}>Mis Recursos</div>
                    <div onClick={() => { changeTypeResource(2) }} className={typeResource === 2 ? cx(styles['filter'], styles["filter-active"], "button") : cx(styles['filter'], "button")}>Recursos creados</div>
                </div>
            </div>
            <div className={cx(styles['searcher-container'])}>
                    {typeResource === 1 ? 
                        <div className={cx(styles['searcher-title'])}>
                            Mis Recursos
                            <span style={{
                                marginLeft: "7px",
                                fontSize: "15px", 
                                color: "gray"}}>(Solo validados)</span>
                        </div>
                    :
                        <div className={cx(styles['searcher-title'])}>Recursos creados</div>
                    }
                <div className={cx(styles['searcher'])}>
                    <div className={cx(styles["btn-search"])}>Buscar:</div>
                    <input className={cx(styles["search-control"], 'select-control')} onChange={(e) => { changeKeyword(e.target.value) }}></input>
                </div>
            </div>
            <div className="results-container">
                {loading ?
                    <div className='loading-container'><ReactLoading type={'bars'} color={'#DC042B'} height={128} width={128} /></div>
                    :
                    <table>
                        <thead>
                            <tr>
                                <th className={`h4-title col-sm-1  ${sorting("id")}`} onClick={() => { changeSort("id") }}>Ref.</th>
                                <th className={`h4-title col-sm-2  ${sorting("name")}`} onClick={() => { changeSort("name") }}>Nombre del recurso</th>
                                <th className={`h4-title col-sm-3  ${sorting("summary")}`} onClick={() => { changeSort("summary") }}>Breve resumen</th>
                                <th className={`h4-title col-sm-2  ${sorting("municipality")}`} onClick={() => { changeSort("municipality") }}>Municipio</th>
                                <th className={`h4-title col-sm-2  ${sorting("contactMail")}`} onClick={() => { changeSort("contactMail") }}>Contacto</th>
                                {typeResource && (typeResource === 2) ?
                                    <th className={`h4-title col-sm-3  ${sorting("situation")}`} onClick={() => { changeSort("validated") }}>Estado</th>
                                    :
                                    <th className={`h4-title col-sm-3  ${sorting("situation")}`} onClick={() => { changeSort("situation") }}>Situación</th>
                                }
                                <th className={'h4-title col-sm-1'}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {resources && resources.length > 0 ?
                                resources.map((item, index) =>
                                    <tr key={index} className={cx(styles["tr-element"])}>
                                        <td className={cx(styles["td-element"])}>{item.id}</td>
                                        <td><strong>{item.name}</strong></td>
                                        <td className={cx(styles["td-element"])}>{item.summary}</td>
                                        <td className={cx(styles["td-element"])}>{item.municipality ? item.municipality : '-'}</td>
                                        <td className={cx(styles["td-element"])}>{item.contactMail}</td>
                                        {typeResource && (typeResource === 2) ?
                                            <td className={cx(styles["td-element"])}>
                                                {item.validated ? 
                                                <div className={cx(styles["estado"])}>
                                                    <div title= "Validado" className={cx(styles["estado-icon"], styles["validado"])}></div>
                                                    <span>Validado</span>
                                                </div>
                                                : 
                                                <div className={cx(styles["estado"])}>
                                                    <div title = "No validado" className={cx(styles["estado-icon"], styles["no-validado"])}></div>
                                                    <span>No validado</span>
                                                </div>}
                                            </td>
                                            :
                                            <td className={cx(styles["td-element"])}>
                                                {item.situation ? 
                                                <div>
                                                    <span>Activo</span>
                                                </div>
                                                : 
                                                <div>
                                                    <span>Caducado</span>
                                                </div>}
                                            </td>
                                        }
                                        <td className={cx(styles["actions-container"])}>
                                            <div className={cx(styles["actions-button"], "button")} onClick={() => goToView(item.id)}>
                                                <img src={require('../../images/eye-solid.svg').default} className={cx(styles["actions-img"])}></img>
                                            </div>
                                            <div className={cx(styles["actions-button"], "button")} onClick={() => goToEdit(item.id)}>
                                                <img src={require('../../images/pen-to-square-solid.svg').default} className={cx(styles["actions-img"])}></img>
                                            </div>
                                        </td>
                                    </tr>
                                )
                                :
                                <tr><td colSpan="5" >{f({ id: "app.table.empty" })}</td></tr>
                            }
                        </tbody>
                    </table>
                }

            </div>
            <PaginationComponent pageIndex={pageIndex} onPageIndexChange={(page) => changePageIndex(page)} items={resources} totalPages={totalPages} />
        </div>
    );
}

export default Resources;