import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from '../../state'
import { useHistory } from 'react-router-dom';
import styles from "./Home.module.scss";
import cx from 'classnames';
import downloadicon from '../../images/download.svg'
import Footer from '../../components/footer/Footer';


const Home = (props) => {
    let history = useHistory();
    const [tabs, setTab] = useState();

    const select = async (e) => {

        const selected = document.querySelectorAll(".home-tabs-active");
        selected.forEach(function (el) {
            el.classList.remove("home-tabs-active");
        });
        
    };

    const { } = useSelector('activities');
    const dispatch = useDispatch();

    return (
        <div className="container-fluid">
            <div className={cx(styles["home-container"])}>
                <div className={cx(styles["home-tabs"])}>
                    <div onClick={(e) => {
                        setTab(1);
                        history.push('/buscador');
                        history.go('/buscador');
                    }} className={cx(styles["home-element"])}>
                        <a className={
                            tabs === 1
                                ? cx(styles["botones-inicio"], styles["searcher"], styles["home-tabs-active"])
                                : cx(styles["botones-inicio"], cx(styles["searcher"]))
                        }>
                            <div>
                                Buscador
                            </div>
                            <div>Localiza las actuaciones comunitarias</div>
                        </a>
                    </div>
                    <div onClick={(e) => {
                        history.push('/actividad/consultar');
                        history.go('/actividad/consultar');
                    }} className="home-element">
                        <a className={
                            tabs === 2
                                ? cx(styles["botones-inicio"], styles["activities"], styles["home-tabs-active"])
                                : cx(styles["botones-inicio"], styles["activities"])}>
                            <div>Actividades</div>
                            <div>Talleres, jornadas, paseos saludables...</div>
                        </a>
                    </div>
                    <div onClick={(e) => {
                        history.push('/recurso/consultar');
                        history.go('/recurso/consultar');
                    }} className="home-element">
                        <a className={
                            tabs === 3
                                ? cx(styles["botones-inicio"], styles["resource"], styles["home-tabs-active"])
                                : cx(styles["botones-inicio"], styles["resource"])} >
                            <div>Recursos</div>
                            <div>Asociación, parque, piscina</div>
                        </a>
                    </div>
                    <div className={cx(styles["guide"])}>
                        <p className={cx(styles["guide-text"])}>Guía de ayuda para la inscripción de actividades o recursos</p>
                        <a href={process.env.PUBLIC_URL +"/pdf/GuiaAyudaActuacionesOBSA.pdf"} target="_blank" className="link">
                            <div className={cx(styles["home-button"], "button")}>
                                <div className={cx(styles["button-icon"])}><img src={downloadicon} className={cx(styles["download-icon"])}/></div> 
                                <div className={cx(styles["button-text"])}>Descargar PDF</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Home;