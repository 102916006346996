import React, { useEffect, useState, useRef } from "react";
import cx from 'classnames';
import styles from './ValidateActivity.module.scss';
import { useDispatch, useSelector } from "state";
import { useParams, useHistory } from 'react-router-dom';
import { getActivityById, validateActivity, getAllTopics } from 'state/activities/actions';
import { useForm } from "react-hook-form";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw } from 'draft-js';
import { useIntl } from "react-intl";
import { useDeferredValue } from "react";



const ValidateActivity = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { activity, selectedTopics, determinantes, topics } = useSelector('activities');
    const { id } = useParams();
    const [moreInfo, setMoreInfo] = useState(true);
    const [preview, setPreview] = useState();
    const [modal, setModal] = useState(false);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const firstRender = useRef(true);
    const { formatMessage: f } = useIntl();



    const toggle = () =>  {
        let total = activity.inside + activity.positiveev + activity.healthy + activity.profit + activity.strategy;
        if(total !== 5){
            if (!modal) {
                setModal(true);
            } else {
                setModal(false);
            }
        }else{
            post();
        }
    }

    useEffect(() => {
        const getActivity = async() => {
            await getActivityById(dispatch, {id: id});
        }

        const getTopics = async() => {
            await getAllTopics(dispatch);
        }
        getActivity();
        getTopics();

    }, []);


    useEffect(() => {

        activity.photo ? setPreview(`${process.env.REACT_APP_IMG_URL}${activity.photo}`) : setPreview("");

        if (activity.description !== "" && activity.description !== undefined) {
            const parsed = convertFromRaw(JSON.parse(activity.description));
            setEditorState(EditorState.createWithContent(parsed));
        }

        if(activity.topics){
            activity.topics.forEach(e => {
                addRemoveSelectedTopic(e.id);
            })
        }

        if(activity.detFisico)
            addRemoveSelectedDeterminante(1)
        if(activity.detSanita)
            addRemoveSelectedDeterminante(2)
        if(activity.detHabito)
            addRemoveSelectedDeterminante(3)
        if(activity.detSocioec)
            addRemoveSelectedDeterminante(4)
        if(activity.detHistorial)
            addRemoveSelectedDeterminante(5)


        if(activity.lastUpdateDate){
            const fecha = activity.lastUpdateDate.split('-');
            activity.lastUpdateDate = fecha[2]+"/"+fecha[1]+"/"+fecha[0];    
        }

    }, [activity]);
    

    const post = async () => {
        
        const put = async () => {
            await validateActivity(dispatch, data);
        }

        const data = {
            "id": activity.id,
            "inside": activity.inside ? activity.inside : 0,
            "insideob": activity.insideOb,
            "positiveev": activity.positiveev ? activity.positiveev : 0,
            "positiveevob": activity.positiveevOb,
            "healthy": activity.healthy ? activity.healthy : 0,
            "healthyob": activity.healthyOb,
            "profit": activity.profit ? activity.profit : 0,
            "profitob": activity.profitOb,
            "strategy": activity.strategy ? activity.strategy : 0,
            "strategyob": activity.strategyOb,
            "validated": 1,
            "topics": selectedTopics,
            "determinantes": determinantes
        }

        put();
        
        history.push('/admin/actividades'); 
        history.go('/admin/actividades');
        
        dispatch({ type: 'SET_ACTIVITY', payload: {} });
        dispatch({ type: 'SET_PHOTO', payload: [] });
        dispatch({ type: 'SET_TEMPORALITIES', payload: [] });
        dispatch({ type: 'SET_LOCATIONS', payload: [] });
        dispatch({ type: 'SET_PREVIEW', payload: [] });

    }

    const { register, handleSubmit, errors, watch, setValue, setError } = useForm({
        mode: "onSubmit",
    });

    const showMoreInfo = () => {
        setMoreInfo(!moreInfo);
    }

    const addRemoveSelectedTopic = (topic) => {
        dispatch({ type: 'ADD_REMOVE_SELECTED_TOPICS', payload: topic });
    }

    const addRemoveSelectedDeterminante = (determinante) => {
        dispatch({ type: 'ADD_REMOVE_DETERMINANTE', payload: determinante })
    }


    return (
        <div className={cx(styles["main-container"])}>
            
            <div className={cx(styles["subheader-container"])}>
                <div className={"subheader" + ' ' + cx(styles["subheader-edit"])}>
                    <div className='h2-title'>Validación de la actividad</div>
                </div>
                <div className={cx(styles["body-container"])}>
                    <div>
                        <div className={"col-sm-12 col-xs-12"}>
                            <div>
                                Red Adscrita
                                <img className={cx(styles['title-image'])} src={require("../../images/obsa_icon_hd_color.png")} alt="red" /><b>OBSA</b>
                            </div>
                            <br />
                            <div className={cx(styles[moreInfo ? 'show' : 'hide'])}>
                                <label>Resumen</label>
                                <div className="wrapperClassNamePerformance">{activity.summary}</div>
                            </div>
                            {
                                activity.description && activity.description != "" && activity.description != undefined &&
                                <>
                                    <div className={cx(styles[moreInfo ? 'hide' : 'show'])}>
                                        <label>Descripción</label>
                                        <Editor
                                            editorState={editorState}
                                            toolbarClassName="ACToolbar"
                                            wrapperClassName="wrapperClassNamePerformance"
                                            readOnly
                                        />
                                    </div>
                                    <br />
                                    <button onClick={showMoreInfo} className={"button" + ' ' + cx(styles["button-showdetails"])}>{moreInfo ? "Ver descripción" : "Ver resumen"}</button>
                                </>
                            }
                        </div>
                    </div>
                    <div className={"col-sm-12 col-xs-12 d-flex"} style={{flexWrap: "wrap"}}>
                        <div className={"col-sm-6 col-xs-6"}>
                            <div className={cx(styles["info-box"], "col-sm-12 col-xs-12")}>
                                <div className={cx(styles["subtitle"])}>Nº de referencia</div>
                                <div className={cx(styles["result"])}>{activity.id && activity.id}</div>
                            </div>
                            <div className={cx(styles["info-box"], "col-sm-12 col-xs-12")}>
                                <div className={cx(styles["subtitle"])}>Población</div>
                                <div className={cx(styles["result"])}>{activity.demographicGroup && activity.demographicGroup.description ? activity.demographicGroup.description : ""} - {activity.gender && activity.gender.description ? activity.gender.description : ""}</div>
                            </div>
                            <div className={cx(styles["info-box"], "col-sm-12 col-xs-12")}>
                                <div className={cx(styles["subtitle"])}>Contacto</div>
                                <div className={cx(styles["result"])}>
                                    {activity.contact && activity.contact} - {activity.phone && activity.phone} - {activity.contactMail && activity.contactMail}
                                </div>
                            </div>
                            <div className={cx(styles["info-box"], "col-sm-12 col-xs-12")}>
                                <div className={cx(styles["subtitle"])}>Entidad participante/colaboradora/promotora</div>
                                <div className={cx(styles["result"])}>{activity.entity ? activity.entity : "-"}</div>
                            </div>
                            <div className={cx(styles["info-box"], "col-sm-12 col-xs-12")}>
                                <div className={cx(styles["subtitle"])}>Fecha de última actualización de la actividad</div>
                                <div className={cx(styles["result"])}>{activity.lastUpdateDate ? activity.lastUpdateDate : "-"}</div>
                            </div>
                            
                            <div className={cx(styles["info-box"], "col-sm-12 col-xs-12")}>
                                <div className={cx(styles["subtitle"])}>Situación actual de la actividad</div>
                                <div className={cx(styles["result"])}> { 
                                    activity.situation == 1 ? <span className={cx(styles["active-situation"])}>Activa</span> : activity.situation == 0 ? <span className={cx(styles["historical-situation"])}>Histórica</span> : "-" 
                                }
                                </div>
                            </div>
                        </div>
                        <div className={"col-sm-6 col-xs-6"}>
                            <div className={cx(styles["info-box"], "col-sm-12 col-xs-12")}>
                                <div className={cx(styles["subtitle"])}>Localización</div>
                                <div className={cx(styles["result"])}>
                                    { activity.location?.map((item, index) => {
                                        return (
                                            <div className={cx(styles["result"])} key={index}>
                                                {item.roadType.description} {item.street} {item.number}, {item.postalCode} - {item.ubication} {item.municipality ? `${item.municipality.name}` : ""} {item.locality ? `${item.locality}` : ""}
                                            </div>
                                        )
                                    }) }
                                </div>
                            </div>
                            <div className={cx(styles["info-box"], "col-sm-12 col-xs-12")}>
                                <div className={cx(styles["subtitle"])}>Temporalidad</div>
                                <div className={cx(styles["result"])}>{ activity.temporality ? <span>{activity.temporality}</span> : <span>-</span>}</div>
                            </div>
                            <div className={cx(styles["info-box"], "col-sm-12 col-xs-12")}>
                                <div className={cx(styles["subtitle"])}>Página web del proyecto</div>
                                {
                                    activity.webPage &&
                                    <div><a className="link" href={activity.webPage} target="blank">{activity.webPage}</a></div>
                                }
                                {
                                    activity.facebookPage &&
                                    <div><a className="link" href={activity.facebookPage} target="blank">{activity.facebookPage}</a></div>
                                }
                                {
                                    activity.twitterPage &&
                                    <div><a className="link" href={activity.twitterPage} target="blank">{activity.twitterPage}</a></div>
                                }
                            </div>
                            <div className={cx(styles["info-box"], "col-sm-12 col-xs-12")}>
                                <div className={cx(styles["subtitle"])}>Imagen de la actividad</div>
                                <div className={cx(styles["result"])}>
                                    {activity.photo && <img style={{maxWidth: "400px"}} className={cx(styles["activity-img"])} src={preview} alt={preview}></img>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        activity.userValidated === 1 ?
                        <div className={cx(styles["status-bar-green"])}>
                            {f({id: "forms.check1"})}
                        </div> 
                        : 
                        <div className={cx(styles["status-bar-orange"])}>
                            {f({id: "forms.check1.denied"})}
                        </div>
                    }


                    

                    <form onSubmit={handleSubmit(post)}>
                        <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle}>No se cumplen todos los requisitos para validar</ModalHeader>
                                <ModalBody>
                                    <p>Al hacer clic en continuar se enviará un correo al email de contacto indicando los motivos de que la actividad no haya sido validada.</p>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="danger" onClick={post}>Continuar</Button>{' '}
                                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                                </ModalFooter>
                        </Modal>
                        <div className={"col-sm-12 col-xs-12 d-flex"}  style={{flexWrap: "wrap"}}>
                            <div className={"col-sm-6 col-xs-6"}>
                                <div className={"col-sm-12 col-xs-12 d-flex"}>
                                    <div className={"col-sm-1 col-xs-1"}>
                                        <input className={cx(styles["checkbox"])} type="checkbox" ref={register} name="inside" id="inside"
                                        defaultChecked={activity.inside}
                                        onClick={(e) => (activity.inside = e.target.checked ? 1 : 0)}/>
                                    </div>
                                    <div className={"col-sm-11 col-xs-11"}>
                                        <label htmlFor="inside">La actividad se realiza dentro del Principado de Asturias</label>
                                    </div>
                                </div>
                                {errors.inside && <p className="invalid-feedback-custom">{errors.inside.message}</p>}
                            </div>
                            <div className={"col-sm-6 col-xs-6"} style={{marginLeft: "auto"}}>
                                <div>Observaciones</div>
                                <textarea className={"select-control" + ' ' + cx(styles["form-control"] + ' ' + cx(styles["textarea-control"]))}
                                onInput={(e) => (activity.insideOb = e.target.value) }
                                defaultValue={activity.insideOb !== null ? activity.insideOb : ""}></textarea>
                            </div>
                        </div>
                        <div className={"col-sm-12 col-xs-12 d-flex"} style={{flexWrap: "wrap"}}>
                            <div className={"col-sm-6 col-xs-6"}>
                                <div className={"col-sm-12 col-xs-12 d-flex"}>
                                    <div className={"col-sm-1 col-xs-1"}>
                                        <input className={cx(styles["checkbox"])} type="checkbox" ref={register} name="positiveev" id="positiveev"
                                        defaultChecked={activity.positiveev}
                                        onClick={(e) => (activity.positiveev = e.target.checked ? 1 : 0)}/>
                                    </div>
                                    <div className={"col-sm-11 col-xs-11"}>
                                        <label htmlFor="positiveev">Existe algún tipo de evaluación positiva en la recomendación de este tipo de actividad y el posible beneficio que pudiera tener en términos de resultados en salud</label>
                                    </div>
                                </div>
                                {errors.positiveev && <p className="invalid-feedback-custom">{errors.positiveev.message}</p>}
                            </div>
                            <div className={"col-sm-6 col-xs-6"} style={{marginLeft: "auto"}}>
                                <div>Observaciones</div>
                                <textarea className={"select-control" + ' ' + cx(styles["form-control"] + ' ' + cx(styles["textarea-control"]))}
                                onInput={(e) => (activity.positiveevOb = e.target.value)}
                                defaultValue={activity.positiveevOb !== null ? activity.positiveevOb : ""}></textarea>
                            </div>
                        </div>
                        <div className={"col-sm-12 col-xs-12 d-flex"} style={{flexWrap: "wrap"}}>
                            <div className={"col-sm-6 col-xs-6"}>
                                <div className={"col-sm-12 col-xs-12 d-flex"}>
                                    <div className={"col-sm-1 col-xs-1"}>
                                        <input className={cx(styles["checkbox"])} type="checkbox" ref={register} name="healthy" id="healthy"
                                        defaultChecked={activity.healthy}
                                        onClick={(e) => (activity.healthy = e.target.checked ? 1 : 0)}/>
                                    </div>
                                    <div className={"col-sm-11 col-xs-11"}>
                                        <label htmlFor="healthy">Existe una evidencia o lógica clara acerca de que la realización de este tipo de actividades no produce un efecto perjudicial en la salud</label>
                                    </div>
                                </div>
                                {errors.healthy && <p className="invalid-feedback-custom">{errors.healthy.message}</p>}
                            </div>
                            <div className={"col-sm-6 col-xs-6"} style={{marginLeft: "auto"}}>
                                <div>Observaciones</div>
                                <textarea className={"select-control" + ' ' + cx(styles["form-control"] + ' ' + cx(styles["textarea-control"]))}
                                onInput={(e) => (activity.healthyOb = e.target.value)}
                                defaultValue={activity.healthyOb !== null ? activity.healthyOb : ""}></textarea>
                            </div>
                        </div>
                        <div className={"col-sm-12 col-xs-12 d-flex"} style={{flexWrap: "wrap"}}>
                            <div className={"col-sm-6 col-xs-6"}>
                                <div className={"col-sm-12 col-xs-12 d-flex"}>
                                    <div className={"col-sm-1 col-xs-1"}>
                                        <input className={cx(styles["checkbox"])} type="checkbox" ref={register} name="profit" id="profit"
                                        defaultChecked={activity.profit}
                                        onClick={(e) => (activity.profit = e.target.checked ? 1 : 0)}/>
                                    </div>
                                    <div className={"col-sm-11 col-xs-11"}>
                                        <label htmlFor="profit">La actividad que se desarrolla no persigue un ánimo de lucro</label>
                                    </div>
                                </div>
                                {errors.profit && <p className="invalid-feedback-custom">{errors.profit.message}</p>}
                            </div>
                            <div className={"col-sm-6 col-xs-6"} style={{marginLeft: "auto"}}>
                                <div>Observaciones</div>
                                <textarea className={"select-control" + ' ' + cx(styles["form-control"] + ' ' + cx(styles["textarea-control"]))}
                                onInput={(e) => (activity.profitOb = e.target.value)}
                                defaultValue={activity.profitOb !== null ? activity.profitOb : ""}></textarea>
                            </div>
                        </div>
                        <div className={"col-sm-12 col-xs-12 d-flex"} style={{flexWrap: "wrap"}}>
                            <div className={"col-sm-6 col-xs-6"}>
                                <div className={"col-sm-12 col-xs-12 d-flex"}>
                                    <div className={"col-sm-1 col-xs-1"}>
                                        <input className={cx(styles["checkbox"])} type="checkbox" ref={register} name="strategy" id="strategy"
                                        defaultChecked={activity.strategy}
                                        onClick={(e) => (activity.strategy = e.target.checked ? 1 : 0)}/>
                                    </div>
                                    <div className={"col-sm-11 col-xs-11"}>
                                        <label htmlFor="strategy">La actividad se integra en alguna de las Líneas estratégicas del Plan de Salud de la Consejería de Sanidad del Gobierno del Principado de Asturias</label>
                                    </div>
                                </div>
                                {errors.strategy && <p className="invalid-feedback-custom">{errors.strategy.message}</p>}
                            </div>
                            <div className={"col-sm-6 col-xs-6"} style={{marginLeft: "auto"}}>
                                <div>Observaciones</div>
                                <textarea className={"select-control" + ' ' + cx(styles["form-control"] + ' ' + cx(styles["textarea-control"]))}
                                onInput={(e) => (activity.strategyOb = e.target.value)}
                                defaultValue={activity.strategyOb !== null ? activity.strategyOb : ""}></textarea>
                            </div>
                        </div>
                        <hr/>
                        <div className={"col-sm-12 col-xs-12 d-flex"} style={{flexWrap: "wrap"}}>
                            <div className={"col-sm-6 col-xs-6"}>
                                <label className="h4-title">TEMAS</label>
                                {topics && activity.topics ?
                                    topics.map((item) => 
                                        <div key={item.id} className={cx(styles["check-topics"])}>
                                            <img src={require(`../../images/${item.imgName}`)} className="icons" />
                                            <input className={cx(styles["checkbox"])} type="checkbox" id={item.description}                                    
                                            defaultChecked={activity.topics.filter(e => e.id === item.id).length > 0}
                                            onClick={() => addRemoveSelectedTopic(item.id)}/>
                                            <label htmlFor={item.description} className={cx(styles["text-topics"])}>{item.description}</label>
                                        </div>
                                    )
                                : ""}
                                
                            </div>
                            <div className={`col-sm-6 col-xs-6 ${cx(styles["right-panel"])}`}>
                                <div className={`col-sm-12 col-xs-12 ${cx(styles["determinantes"])}`}>
                                    <label className="h4-title">DETERMINANTES</label>
                                    <div className={cx(styles["check-topics"])}>
                                        <input className={cx(styles["checkbox"])} type="checkbox" id="fisico"
                                        defaultChecked={activity.detFisico}
                                        onClick={() => addRemoveSelectedDeterminante(1)}/>
                                        <label htmlFor="fisico" className={cx(styles["text-topics"])}>Entorno físico</label>
                                    </div>
                                    <div className={cx(styles["check-topics"])}>
                                        <input className={cx(styles["checkbox"])} type="checkbox" id="sanita"
                                        defaultChecked={activity.detSanita}
                                        onClick={() => addRemoveSelectedDeterminante(2)}/>
                                        <label htmlFor="sanita" className={cx(styles["text-topics"])}>Recursos sanitarios</label>
                                    </div>
                                    <div className={cx(styles["check-topics"])}>
                                        <input className={cx(styles["checkbox"])} type="checkbox" id="habito"
                                        defaultChecked={activity.detHabito}
                                        onClick={() => addRemoveSelectedDeterminante(3)}/>
                                        <label htmlFor="habito" className={cx(styles["text-topics"])}>Hábitos/Comportamientos saludables</label>
                                    </div>
                                    <div className={cx(styles["check-topics"])}>
                                        <input className={cx(styles["checkbox"])} type="checkbox" id="economico"
                                        defaultChecked={activity.detSocioec}
                                        onClick={() => addRemoveSelectedDeterminante(4)}/>
                                        <label htmlFor="economico" className={cx(styles["text-topics"])}>Factores socioeconómicos</label>
                                    </div>
                                    
                                </div>
                                
                                <div className={"col-sm-12 col-xs-12"}>
                                    <label>Buscadores de evidencia</label>
                                    <ul>
                                        <li><a className="link" href="https://www.countyhealthrankings.org/take-action-to-improve-health/what-works-for-health">County Health Rankings & Roadmaps: What Works for Health</a></li>
                                        <li><a className="link" href="https://www.thecommunityguide.org/">The Community Guide</a></li>
                                        <li><a className="link" href="https://eppi.ioe.ac.uk/webdatabases4/Intro.aspx?ID=9">EPPI Centre: Database of promoting health effectiveness reviews (DoPHER)</a></li>
                                    </ul>
                                </div>
                                <div className={cx(styles["check-topics"], styles["hist-clinica"])}>
                                    <input className={cx(styles["checkbox"])} type="checkbox" id="hclinica"
                                    defaultChecked={activity.detHistorial}
                                    onClick={() => addRemoveSelectedDeterminante(5)}/>
                                    <label htmlFor="hclinica" className={cx(styles["text-topics"])}>Aparece en la historia clínica de Atención Primaria</label>
                                </div>
                            </div>  
                        </div>
                        <div className={cx(styles["button-container"])}>
                            <button variant="contained" className={cx(styles["button-index"], "button")} type="button" onClick={toggle}>Validar Actividad</button>
                        </div>

                    </form>
                    
                </div>
            </div>
        </div>
    )

}

export default ValidateActivity;