import {
  post,
  get,
  put,
  deleteMethod,
  postMultipart,
  putMultipart,
  putValidation,
  putRenewal,
  getCsv,
  getPdf
} from "../../api/client";
const API_URL = process.env.REACT_APP_API_URL;

const getResourcesUrlQuery = (paramsObject) => {
  let urlQuery = "resources/";

  urlQuery = paramsObject.url ? urlQuery + paramsObject.url : urlQuery;

  if (
    paramsObject.id ||
    paramsObject.keyword ||
    paramsObject.pageIndex ||
    paramsObject.direction ||
    paramsObject.field ||
    paramsObject.validated ||
    paramsObject.typeResource ||
    paramsObject.email ||
    paramsObject.url
  ) {
    let concatCaracter = "?";
    /*
    if (sessionStorage.getItem("userId")) {
      urlQuery += concatCaracter + "userId=" + sessionStorage.getItem("userId");
      concatCaracter = "&";
    }
    */

    if(paramsObject.typeResource){
      if(paramsObject.typeResource === 1){
        urlQuery += concatCaracter + "email=" + paramsObject.email + "&validated=1";
      }else{
        urlQuery += concatCaracter + "userId=" + sessionStorage.getItem("userId");
      }
      concatCaracter = "&";
    }
    if (paramsObject.keyword) {
      urlQuery += concatCaracter + "keyword=" + paramsObject.keyword;
      concatCaracter = "&";
    }
    if (paramsObject.pageIndex) {
      urlQuery += concatCaracter + "pageIndex=" + paramsObject.pageIndex;
      concatCaracter = "&";
    }
    if (paramsObject.direction) {
      urlQuery += concatCaracter + "direction=" + paramsObject.direction;
      concatCaracter = "&";
    }
    if (paramsObject.field) {
      urlQuery += concatCaracter + "field=" + paramsObject.field;
      concatCaracter = "&";
    }
    if(paramsObject.selectedSituation === 0){
      urlQuery += concatCaracter + "situation=" + paramsObject.selectedSituation;
      concatCaracter = "&";
    }else{
      if (paramsObject.validated !== "" && paramsObject.validated !== undefined) {
        urlQuery += concatCaracter + "validated=" + paramsObject.validated;
        concatCaracter = "&";
      }
    }
  }
  return urlQuery;
};

export const getPerformancePdf = async (paramsObject) => {
  try {
    const url = `performance/generate-performance-pdf`;
    await getPdf(url, paramsObject);
  } catch (error) {
    console.log(error);
  }
}

export const getResources = async (dispatch, paramsObject) => {
  try {
    dispatch({ type: "GET_RESOURCES" });
    let response = {};
    let responseResourcesQuery = await get(getResourcesUrlQuery(paramsObject));

    response.content = responseResourcesQuery.content;
    response.totalElements = responseResourcesQuery.totalElements;
    response.totalPages = responseResourcesQuery.totalPages;


    dispatch({ type: "GET_RESOURCES_SUCCESS", payload: response });
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_RESOURCES_FAILURE" });
  }
};

export const getResourcesCsv = async (paramsObject) => {
  try {
    const url = getResourcesUrlQuery(paramsObject);
    await getCsv(url);
  } catch (error) {
    console.log(error);
  }
}

export const postResource = async (dispatch, paramsObject) => {
  try {
    //dispatch({ type: "SET_RESOURCE", payload: {} });
    //const resourcePost = {peformance: resource, photo, locations: JSON.stringify(locations)}
    dispatch({ type: "SET_LOADING", payload: true });
    const data = await postMultipart(`resources/`, paramsObject);
    dispatch({ type: "SET_RESOURCE_SUCCESS" });
    dispatch({ type: "SET_LOADING", payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: "SET_LOADING", payload: false });
    dispatch({ type: "SET_RESOURCE_FAILURE" });
  }
};

export const editResource = async (dispatch, paramsObject) => {
  try {
    //const activityPut = { "id": resource.id, performance: resource, photo, locations: JSON.stringify(locations), temporalities: JSON.stringify(temporalities) };
    //dispatch({ type: "SET_ACTIVITY" });
    let data = await putMultipart(`resources`, paramsObject);
    dispatch({ type: "SET_RESOURCE_SUCCESS" });
  } catch (error) {
    console.log(error);
    dispatch({ type: "SET_RESOURCE_FAILURE" });
  }
};

export const deleteResource = async (dispatch, id) => {
  try {
    dispatch({ type: "DELETE_RESOURCE"});
    await deleteMethod(`resources/${id}`);
    dispatch({ type: "DELETE_RESOURCE_SUCCESS", payload: id});
  } catch (error) {
    console.log(error);
    dispatch({ type: "DELETE_RESOURCE_FAILURE"});
  }
};

export const renewResource = async (dispatch, id) => {
  try {
    dispatch({ type: "RENEW_RESOURCE" });
    await putRenewal(`resources`, id);
    dispatch({ type: "RENEW_RESOURCE_SUCCESS" });
  } catch (error) {
    dispatch({ type: "RENEW_RESOURCE_FAILURE" });
  }
}

export const getAutonomousCommunitiesList = async (dispatch) => {
  try {
    dispatch({ type: "GET_AC_LIST" });
    const data = await get(`autonomous-community/`);
    dispatch({ type: "GET_AC_LIST_SUCCESS", payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_AC_LIST_FAILURE" });
  }
};

export const getProvincesList = async (dispatch, caSelected) => {
  try {
    dispatch({ type: "GET_PROVINCES_LIST" });
    const data = await get(`province/ca/${caSelected}`);
    dispatch({ type: "GET_PROVINCES_LIST_SUCCESS", payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_PROVINCES_LIST_FAILURE" });
  }
};

export const getMunicipalitiesList = async (dispatch, prSelected) => {
  try {
    dispatch({ type: "GET_MUNICIPALITIES_LIST" });
    const data = await get(`municipalities/pr/${prSelected}`);
    dispatch({ type: "GET_MUNICIPALITIES_LIST_SUCCESS", payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_MUNICIPALITIES_LIST_FAILURE" });
  }
};

export const initializeNewResourceForm = async (dispatch) => {
  try {
    dispatch({ type: "INITIALIZE_NEW_RESOURCES_FORM" });
    let response = {};

    response.municipalities = await get(`municipalities/pr/33`);
    response.types = await get(`code/idtype/${7}`);
    response.categories = await get(`code/idtype/${3}`);

    dispatch({
      type: "INITIALIZE_NEW_RESOURCES_FORM_SUCCESS",
      payload: response,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: "INITIALIZE_NEW_RESOURCES_FORM_FAILURE" });
  }
};

export const getResourceById = async (dispatch, paramsObject) => {
  try {
    dispatch({ type: "GET_RESOURCE" });
    const data = await get(`performance/${paramsObject.id}`);
    dispatch({ type: "GET_RESOURCE_SUCCESS", payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_RESOURCE_FAILURE" });
  }
};

export const getAllTopics = async (dispatch) => {
  try {
    dispatch({type: "GET_ALL_TOPICS"});
    const data = await get(`topic`);
    dispatch({type: "GET_ALL_TOPICS_SUCCESS", payload: data});
  } catch (error) {
    console.log(error);
    dispatch({type: "GET_ALL_TOPICS_FAILURE"});
  }
}

export const validateResource = async (dispatch, paramsObject) => {
  
  try {
    //dispatch({ type: "SET_RESOURCE" });
    let data = await putValidation(`resources`, paramsObject);
    dispatch({ type: "SET_RESOURCE_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "SET_RESOURCE_FAILURE" });
  }
}
