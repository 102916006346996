import React, { useState, useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom';
import FirstForm from "./ActivitiesFirtsForm";
import SecondForm from "./ActivitiesSecondForm";
import Confirmation from "./ActivitiesConfirmation";
import cx from 'classnames';
import styles from './NewActivity.module.scss'
import { useDispatch, useSelector } from '../../state';
import { postActivity, getActivityById, editActivity } from "../../state/activities/actions";


const InfoContainer = () => {
    return (
        <div className={cx(styles["info-container"])}>
            <div className={cx(styles['info'])}>
                <div>
                    Los datos que se faciliten serán utilizados solamente en relación a la Red de Actividades Comunitarias
                    (RAC) del Observatorio de Salud en Asturias y el proyecto de red nacional de actividades comunitarias que se está desarrollando con el PACAP. El Observatorio de Salud en Asturias garantiza, en los términos establecidos por la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (LOPD), que sus datos serán tratados de modo confidencial. En todo caso, y al amparo de lo dispuesto en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (LOPD), el interesado puede ejercer los derechos de acceso, rectificación, cancelación y oposición de sus datos de carácter personal en los términos establecidos en la LOPD.
                    La entidad responsable del fichero es el Observatorio de Salud en Asturias,
                    y se puede contactar a través de su secretaría: Observatorio de Salud de Asturias

                    <br />
                    <br />

                    Dirección General de Salud Pública y Participación
                    <br />
                    Consejería de Salud y Servicios Sanitarios
                    <br />
                    C/ Ciriaco Miguel Vigil – nº 9
                    <br />
                    33006 Oviedo
                    <br />
                    <br />
                    Tfno: 985106385 / 985106338
                    <br />
                    Correo electrónico: obsalud@asturias.org
                </div>
            </div>
        </div>)
}

const Index = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    let [page, setPage] = useState(0);
    const { activity, locations, temporalities, photo, activityOk, loading } = useSelector('activities');

    const { id } = useParams();
    const { userId } = useSelector('auth');

    console.log(activityOk)

    useEffect(() => {
        const getActivity = async () => {
            await getActivityById(dispatch, { id });
        }
        if (id) {
            getActivity();
        }
    }, []);

    const backpage = () => {
        setPage(page - 1);
    }

    const returnHome = () => {
        setPage(0);
    }

    const post = async () => {
        const post = async () => {
            await postActivity(dispatch, data);
        }
        const put = async () => {
            await editActivity(dispatch, data);
        }
        const data = {
            "id": activity.id,
            "performance": activity,
            "photo": photo,
            "locations": JSON.stringify(locations),
            "temporalities": JSON.stringify(temporalities),
            "userId": userId
        }
        if (activity?.id) {
            put();
        } else {
            post();
        }


        //setPage(0);
    }



    useEffect(() => {
        if (activityOk) {
            history.push('/inicio');
            history.go('/inicio');


            dispatch({ type: 'SET_ACTIVITY', payload: {} });
            dispatch({ type: 'SET_PHOTO', payload: [] });
            dispatch({ type: 'SET_TEMPORALITIES', payload: [] });
            dispatch({ type: 'SET_LOCATIONS', payload: [] });
            dispatch({ type: 'SET_PREVIEW', payload: [] });
            dispatch({ type: 'RESET_ACTIVITY_SUCCESS' });
        }
    }, [activityOk])

    const reset = async () => {
        dispatch({ type: 'SET_ACTIVITY', payload: {} });
        dispatch({ type: 'SET_PHOTO', payload: [] });
        dispatch({ type: 'SET_TEMPORALITIES', payload: [] });
        dispatch({ type: 'SET_LOCATIONS', payload: [] });
        dispatch({ type: 'SET_PREVIEW', payload: [] });
        setPage(0);
    }

    const onSubmitFirstStep = (data) => {
        dispatch({ type: 'UPDATE_ACTIVITY_DATA', payload: data });

        setPage(page + 1);
    }

    const onSubmitSecondStep = (data) => {
        dispatch({ type: 'UPDATE_ACTIVITY_DATA', payload: data });
        setPage(page + 1);
    }

    return (
        <div className={cx(styles["main-container"])}>
            {(() => {
                switch (page) {
                    case 0:
                        return <FirstForm activity={activity} photo={photo} reset={reset} returnHome={returnHome} onSubmitFirstStep={onSubmitFirstStep} infoContainer={<InfoContainer />} />
                    case 1:
                        return <SecondForm activity={activity} backpage={backpage} reset={reset} returnHome={returnHome} onSubmitSecondStep={onSubmitSecondStep} infoContainer={<InfoContainer />} />
                    default:
                        return <Confirmation
                            loading={loading}
                            activity={activity}
                            backpage={backpage}
                            reset={reset}
                            locations={locations}
                            photo={photo}
                            temporalities={temporalities}
                            returnHome={returnHome}
                            post={post}
                            infoContainer={<InfoContainer />} />
                }
            })()}
        </div>
    );
}

export default Index;