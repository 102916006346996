import React, { useState, useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom';
import FirstForm from "./ResourceFirstForm";
import SecondForm from "./ResourceSecondForm";
import Confirmation from "./ResourceConfirmation";
import cx from 'classnames';
import styles from '../management-activities/NewActivity.module.scss'
import { useDispatch, useSelector } from '../../state';
import { getResourceById, editResource, postResource } from "../../state/resources/actions";

const InfoContainer = () => {
    return (
        <div className={cx(styles["info-container"])}>
            <div className={cx(styles['info'])}>
                <div>
                    Los datos que se faciliten serán utilizados solamente en relación a la Red de Actividades Comunitarias
                    (RAC) del Observatorio de Salud en Asturias y el proyecto de red nacional de actividades comunitarias que se está desarrollando con el PACAP. El Observatorio de Salud en Asturias garantiza, en los términos establecidos por la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (LOPD), que sus datos serán tratados de modo confidencial. En todo caso, y al amparo de lo dispuesto en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (LOPD), el interesado puede ejercer los derechos de acceso, rectificación, cancelación y oposición de sus datos de carácter personal en los términos establecidos en la LOPD.
                    La entidad responsable del fichero es el Observatorio de Salud en Asturias,
                    y se puede contactar a través de su secretaría: Observatorio de Salud de Asturias

                    <br />
                    <br />

                    Dirección General de Salud Pública y Participación
                    <br />
                    Consejería de Salud y Servicios Sanitarios
                    <br />
                    C/ Ciriaco Miguel Vigil – nº 9
                    <br />
                    33006 Oviedo
                    <br />
                    <br />
                    Tfno: 985106385 / 985106338
                    <br />
                    Correo electrónico: obsalud@asturias.org
                </div>
            </div>
        </div>)
}


const IndexResource = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    let [page, setPage] = useState(0);
    const { resource, locations, photo, resourceOk, loading } = useSelector('resources');

    const { id } = useParams();

    const { userId } = useSelector('auth');

    useEffect(() => {
        const getResource = async () => {
            await getResourceById(dispatch, { id });
        }
        if (id) {
            getResource();
        }
    }, []);

    const backpage = () => {
        setPage(page - 1);
    }

    const returnHome = () => {
        setPage(0);
    }

    const onSubmitFirstStep = (data) => {
        dispatch({ type: 'UPDATE_RESOURCE_DATA', payload: data });
        dispatch({ type: 'SET_LOCATIONS', payload: locations });

        setPage(page + 1);
    }

    const onSubmitSecondStep = (data) => {
        dispatch({ type: 'UPDATE_RESOURCE_DATA', payload: data });
        setPage(page + 1);
    }

    const post = async () => {
        const post = async () => {
            await postResource(dispatch, data);
        }
        const put = async () => {
            await editResource(dispatch, data);
        }
        const data = {
            "id": resource.id,
            "performance": resource,
            "photo": photo,
            "locations": JSON.stringify(locations),
            "userId": userId
        }
        if (resource?.id) {
            await put();
        } else {
            await post();
        }

        //setPage(0);
    }


    useEffect(() => {
        if (resourceOk) {

            dispatch({ type: 'SET_RESOURCE', payload: {} });
            dispatch({ type: 'SET_PHOTO', payload: [] });
            dispatch({ type: 'SET_TEMPORALITIES', payload: [] });
            dispatch({ type: 'SET_LOCATIONS', payload: [] });
            dispatch({ type: 'SET_PREVIEW', payload: [] });
            dispatch({ type: 'RESET_RESOURCE_SUCCESS' });

            history.push('/inicio');
            history.go('/inicio');
        }
    }, [resourceOk])

    return (
        <div className={cx(styles["main-container"])}>
            {(() => {
                switch (page) {
                    case 0:
                        return <FirstForm resource={resource} onSubmitFirstStep={onSubmitFirstStep} infoContainer={<InfoContainer />} />
                    case 1:
                        return <SecondForm resource={resource} backpage={backpage} returnHome={returnHome} onSubmitSecondStep={onSubmitSecondStep} infoContainer={<InfoContainer />} />
                    default:
                        return <Confirmation 
                            loading={loading}
                            resource={resource} 
                            locations={locations} 
                            backpage={backpage} 
                            returnHome={returnHome} 
                            post={post} 
                            infoContainer={<InfoContainer />} />
                }
            })()}
        </div>
    );
}

export default IndexResource;