import React from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import { privateRoutes, publicRoutes } from './config/routes.js';
import _ from 'lodash';
import PublicLayout from 'views/layout/PublicLayout/index.js';
import './App.css';
import { useSelector } from './state';
import { roles } from 'utils/enums.js';

function App() {

  const { token, role } = useSelector('auth');

  return (
    <Router>
      <Switch>
      {_.map(privateRoutes, (route, key) => {
            const { component, path } = route;
            return (
              <Route
                exact
                path={path}
                key={key}
                render={(route) => {
                  if (token) {
                    if (route.location.pathname.includes("/admin")) {
                      if (role && role === roles.ADMIN) {
                        return <PublicLayout component={component} route={route} />;
                      } else {
                        return <Redirect to="/inicio" />
                      }
                    } else {
                      return <PublicLayout component={component} route={route} />
                    }
                  } else {
                    return <Redirect to="/login" />;
                  }
                }}
              />
            );
          })}
          {_.map(publicRoutes, (route, key) => {
            const { component, path } = route;
            return (
              <Route
                exact
                path={path}
                key={key}
                render={(route) => {
                  return (token) ? 
                  route.location.pathname === "/login" || route.location.pathname === "/registro" ? <Redirect push to="/inicio" /> : <PublicLayout component={component} route={route} /> 
                   : <PublicLayout component={component} route={route} />;
                }}
              />
            );
          })}
        <Route exact path="/" render={() => (<Redirect to={"/home"} />)} />
        <Route render={() => (<Redirect to="/not-found" />)} />
      </Switch>
    </Router>
  );
}

export default App;
