import { Map, GoogleApiWrapper, Marker, InfoWindow} from 'google-maps-react';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from '../../state';
import { useHistory } from 'react-router-dom';
import InfoWindowEx from './InfoWindowEx';

const API_MAP = process.env.REACT_APP_API_MAP;

const MapContainer = (props) => {
    
    const dispatch = useDispatch();
    const selectorName = props.selector ? props.selector : "activities";
    const { xaxis, yaxis } = useSelector(selectorName);
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [activeMarker, setActiveMarker] = useState({});
    const [marker, setMarker] = useState(null);
    const mapRef = useRef(null);
    const firstRender = useRef(true);

    const history = useHistory();


    const onMarkerClick = (props, marker, e) =>
    {
        setActiveMarker(marker);
        setShowInfoWindow(true);
    };


    const mapClicked = (mapProps, map, clickEvent) => {
        
        console.log(map)
        console.log(mapProps)

        if(props.tableElements){
            if(showInfoWindow) setShowInfoWindow(false)
        }else{
            setCoordinates(clickEvent.latLng.lat().toFixed(7), clickEvent.latLng.lng().toFixed(7));
        }
    }

    useEffect(() => {

        if(!props.detailView){
            if(marker){
                marker.setMap(null);
            }
            let newMarker = new props.google.maps.Marker({
                position: {lat: parseFloat(xaxis), lng: parseFloat(yaxis)},
                map: mapRef.current.map
            });
            setMarker(newMarker);
        }
        

    }, [yaxis])

    useEffect(() => {
        const geocoder = new props.google.maps.Geocoder();
        if(props.location){
            const address = props.location.roadType + " " + props.location.street + " " + props.location.number +
            " " + props.location.postalCode + " " + props.location.municipality + " " + props.location.locality 
            + " " + props.location.ubication;
            geocoder.geocode({'address': address}, (results, status) => {
                if(status === 'OK'){
                    setCoordinates(results[0].geometry.location.lat().toFixed(7), results[0].geometry.location.lng().toFixed(7))
                }else{
                }
            })
        }
    }, [props.location])


    const setCoordinates = (xaxis, yaxis) => {
        dispatch({ type: 'SET_XAXIS', payload: xaxis });
        dispatch({ type: 'SET_YAXIS', payload: yaxis });
    }

    const mapReady = (mapProps, map) => {
        let bound = new props.google.maps.LatLngBounds();
        let numLoc = 0;

        if(props.tableElements)
            props.tableElements.map((item) => {
                item.location.map((location) => {
                    numLoc++;
                    bound.extend({lat: location.xaxis, lng: location.yaxis})
                })
            })
        if(props.locations){
            props.locations.map((item) => {
                numLoc++;
                bound.extend({lat: item.xaxis, lng: item.yaxis})
            })
        }
        
        map.setCenter(bound.getCenter());

        if(numLoc === 1){
            map.setZoom(10)
        }else if(numLoc > 1){
            map.fitBounds(bound);
        }else{
            map.setCenter({lat: 43, lng: -5.8});
            map.setZoom(8);
        }
        
    }


    useEffect(() => {

        if(firstRender.current){
            firstRender.current = false;
        }else{
            let numLoc = 0;
            const map = mapRef.current.map;
            let bound = new props.google.maps.LatLngBounds();

            props.mapElements.map((item) => {
                item.location.map((location) => {
                    numLoc++;
                    bound.extend({lat: location.xaxis, lng: location.yaxis})
                })
            })
            
            map.setCenter(bound.getCenter());
            if(numLoc === 1){
                map.setZoom(10)
            }else{
                map.fitBounds(bound);
            }
        }

    }, [props.mapElements])


    const openDetails = () => {
        if (activeMarker.itemType === "A") {
            history.push(`/actividad/detalle/${activeMarker.itemId}`);
            //history.go(`/actividad/detalle/${activeMarker.itemId}`);
        } else {
            history.push(`/recurso/detalle/${activeMarker.itemId}`);
            //history.go(`/recurso/detalle/${activeMarker.itemId}`);
        }
    }

    return (
        <Map 
            ref={mapRef}
            google={props.google}
            containerStyle={props.style}
            onClick={mapClicked}
            onReady={mapReady}
            streetViewControl={false}
            >
            {props.locations && props.locations.length > 0 ? 
                props.locations.map((item, index) => {
                    return(
                        <Marker
                                key={index}
                                //name={props.activity.name}
                                position={{ lat: item.xaxis, lng: item.yaxis }}
                                //onClick={() => changeShowInfoWindow()}
                                >
                        </Marker>
                    )
                })
                :
                ""
            }

            
            {props.mapElements ? props.mapElements.map((item) => {
                return (
                    item.location.map((location) => {
                        return (
                            <Marker
                                key={item.index}
                                name={item.name}
                                itemId={item.id}
                                itemType={item.type}
                                summary={item.summary}
                                position={{ lat: location.xaxis, lng: location.yaxis }}
                                onClick={onMarkerClick}>
                            </Marker>
                        )
                    })
                )
            })
                :
                ""
            }

            <InfoWindowEx
                visible={showInfoWindow}
                marker={activeMarker}>
                <div>
                    <a><h5 onClick={(openDetails)}>{activeMarker.name}</h5></a>
                    <p>{activeMarker.summary}</p>
                </div>
            </InfoWindowEx>

        </Map>
    )
}

export default GoogleApiWrapper({
    apiKey: API_MAP
})(MapContainer);


