import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import styles from './Ubication.module.scss';
import SimpleMap from '../../components/map/Map'
import { useDispatch, useSelector } from '../../state';
import { } from '../../state/activities/actions';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";


const Ubication = (props) => {

    const dispatch = useDispatch();
    const [edit, setEdit] = useState(false);
    const [locationIndex, setLocationIndex] = useState();
    const firstRender = useRef(false);
    const { location, locations, xaxis, yaxis } = useSelector(`${props.selector}`);

    useEffect(() => {
        if (props.locations && locations.length === 0) {
            dispatch({ type: 'SET_LOCATIONS', payload: props.locations });
        }
    }, [props.locations]);


    const setCoordinates = (xaxis, yaxis) => {
        setValueUbication("xaxis", xaxis);
        setValueUbication("yaxis", yaxis);
    }

    useEffect(() => {
        if(firstRender.current){
            setCoordinates(xaxis, yaxis);
        }else{
            firstRender.current = true;
        }
    }, [xaxis, yaxis]);


    const ubicationSchema = Yup.object({
        municipality: Yup.string().required("No puede estar vacío"),
        roadType: Yup.string().required("No puede estar vacío"),
        street: Yup.string().required("No puede estar vacío").max(100, "Texto demasiado largo"),
        number: Yup.string().required("No puede estar vacío"),
        postalCode: Yup.string().required("No puede estar vacío"),
        xaxis: Yup.string().required("No puede estar vacío"),
        yaxis: Yup.string().required("No puede estar vacío")
    }).required();

    const { register: registerUbication, handleSubmit: handleSubmitUbication, errors: errorsUbication, setValue: setValueUbication, getValues: getValuesUbication, watch } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(ubicationSchema)
    });

    const calculaCoordenadas = () => {
        let ubication = getValuesUbication();
        dispatch({ type: 'SET_LOCATION', payload: ubication });
    }

    const editLocation = () => {
        const cloneData = {
            "municipality": { "name": getValuesUbication("municipality") },
            "locality": getValuesUbication("locality"),
            "ubication": getValuesUbication("ubication"),
            "roadType": {"description": getValuesUbication("roadType")},
            "street": getValuesUbication("street"),
            "number": getValuesUbication("number"),
            "postalCode": getValuesUbication("postalCode"),
            "xaxis": getValuesUbication("xaxis"),
            "yaxis": getValuesUbication("yaxis"),
            "locationIndex": locationIndex
        }
        dispatch({ type: 'EDIT_LOCATIONS', payload: { cloneData } });
        leaveEditMode();
    };

    const addLocation = (data) => {
        dispatch({ type: 'ADD_LOCATION', payload: { data } });
        leaveEditMode();
    }

    const leaveEditMode = () => {
        setEdit(false);
        setValueUbication("municipality", "ALLANDE");
        setValueUbication("locality", "");
        setValueUbication("ubication", "");
        setValueUbication("roadType", "Avenida");
        setValueUbication("street", "");
        setValueUbication("number", "");
        setValueUbication("postalCode", "");
        setValueUbication("xaxis", "");
        setValueUbication("yaxis", "");

        dispatch({ type: 'SET_XAXIS' });
        dispatch({ type: 'SET_YAXIS' });
    }

    const takeLocation = (item, index) => {
        setEdit(true);
        setLocationIndex(index);

        setValueUbication("municipality", item.municipality.name);
        setValueUbication("locality", item.locality);
        setValueUbication("ubication", item.ubication);
        setValueUbication("roadType", item.roadType.description);
        setValueUbication("street", item.street);
        setValueUbication("number", item.number);
        setValueUbication("postalCode", item.postalCode);
        setValueUbication("xaxis", item.xaxis);
        setValueUbication("yaxis", item.yaxis);

        dispatch({ type: 'SET_XAXIS' });
        dispatch({ type: 'SET_YAXIS' });
        dispatch({ type: 'SET_LOCATION', payload: item });

    }

    const removeLocation = (item) => {
        dispatch({ type: 'REMOVE_LOCATION', payload: item })
        leaveEditMode();
    }

    return (
        <div className="form-container">
            <div className='results-container'>
                <table>
                    <thead>
                        <tr>
                            <th className={`h4-title col-sm-1`}>Municipio</th>
                            <th className={`h4-title col-sm-1`}>Localidad</th>
                            <th className={`h4-title col-sm-1`}>C. Postal</th>
                            <th className={`h4-title col-sm-1`}>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {locations?.length > 0 ?
                            locations?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.municipality?.name}</td>
                                        <td>{item.locality}</td>
                                        <td>{item.postalCode}</td>
                                        <td className={cx(styles["actions-container"])} >
                                            <div className={cx(styles["actions-button"], "button")} onClick={() => takeLocation(item, index)}>
                                                <img src={require('../../images/pen-to-square-solid.svg').default} alt="edit" className={cx(styles["actions-img"])}></img>
                                            </div>
                                            <div className={cx(styles["actions-button"], "button")} onClick={() => removeLocation(item)}>
                                                <img src={require('../../images/trash-solid.svg').default} alt="delete" className={cx(styles["actions-img"])}></img>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            : <tr></tr>}
                    </tbody>
                </table>
            </div>
            <form id="ubication-form" className={cx(styles["ubication-fields-container"])} onSubmit={handleSubmitUbication(addLocation)}>
                <div className={cx(styles["ubication-fields"])}>
                    <div className={cx(styles["control-label"], "h4-title")}>Municipio *</div>
                    <select className="select-control"
                        ref={registerUbication} name={`municipality`} id={`municipality`}>
                        {
                            props.municipalities && props.municipalities.map((item, index) => {
                                if (item.id === watch("municipality")) {
                                    return (
                                        <option key={index} value={item.name} defaultValue="selected" >{item.name}</option>
                                    )
                                } else {
                                    return (
                                        <option key={index} value={item.name} >{item.name}</option>
                                    )
                                }
                            })
                        }
                    </select>
                    {errorsUbication.municipality && (
                        <div className="invalid-feedback">
                            {errorsUbication.municipality.message}
                        </div>
                    )
                    }
                </div>
                <div className={cx(styles["ubication-fields"])}>
                    <div className={cx(styles["control-label"], "h4-title")}>Localidad</div>
                    <input className={cx(styles["ubication-inputs"], "select-control")}
                        ref={registerUbication} name={`locality`} id={`locality`} />
                </div>
                <div className={cx(styles["ubication-fields"])}>
                    <div className={cx(styles["control-label"], "h4-title")}>Ubicación</div>
                    <input className={cx(styles["ubication-inputs"], "select-control")}
                        ref={registerUbication} name={`ubication`} id={`ubication`} />
                </div>
                <div className={cx(styles["ubication-fields"])}>
                    <div className={cx(styles["control-label"], "h4-title")}>Tipo *</div>
                    <select className="select-control"
                        ref={registerUbication} name={`roadType`} id={`roadType`}>
                        {
                            props.types && props.types.map((item, index) => {
                                if (item.description === watch("roadType")) {
                                    return (
                                        <option key={index} value={item.description} defaultValue="selected" >{item.description}</option>
                                    )
                                } else {
                                    return (
                                        <option key={index} value={item.description} >{item.description}</option>
                                    )
                                }
                            })
                        }
                    </select>
                    {errorsUbication.roadType && (
                        <div className="invalid-feedback">
                            {errorsUbication.roadType.message}
                        </div>
                    )
                    }
                </div>
                <div className={cx(styles["ubication-fields"])}>
                    <div className={cx(styles["control-label"], "h4-title")}>Calle *</div>
                    <input className={cx(styles["ubication-inputs"], "select-control")}
                        ref={registerUbication} name={`street`} id={`street`} />
                    {errorsUbication.street && (
                        <div className="invalid-feedback">
                            {errorsUbication.street.message}
                        </div>
                    )}
                </div>
                <div className={cx(styles["ubication-fields"])}>
                    <div className={cx(styles["control-label"], "h4-title")}>Número *</div>
                    <input className={cx(styles["ubication-inputs"], "select-control")}
                        ref={registerUbication} name={`number`} id={`number`} />
                    {errorsUbication.number && (
                        <div className="invalid-feedback">
                            {errorsUbication.number.message}
                        </div>
                    )}
                </div>
                <div className={cx(styles["ubication-fields"])}>
                    <div className={cx(styles["control-label"], "h4-title")}>C. Postal *</div>
                    <input className={cx(styles["ubication-inputs"], "select-control")}
                        ref={registerUbication} name={`postalCode`} id={`postalCode`} type="number" />
                    {errorsUbication.postalCode && (
                        <div className="invalid-feedback">
                            {errorsUbication.postalCode.message}
                        </div>
                    )}
                </div> 
                <div className={cx(styles["ubication-coordinates"])}>
                    <p><b>Hacer clic en "Calcular coordenadas" para obtener las coordenadas a partir de la dirección introducida.</b></p>
                    <p>En caso de que las coordenadas no sean las correctas se pueden obtener haciendo clic en el mapa o introduciéndolas a mano.</p>
                    <button className={"temporality-button button"}
                        onClick={calculaCoordenadas} type="button" form="ubication-form">Calcular coordenadas</button>
                    <div className={cx(styles["ubication-fields"])}>
                        <div className={cx(styles["control-label"], "h4-title")}>Latitud *</div>
                        <input className={cx(styles["ubication-inputs"], "select-control")}
                            ref={registerUbication} name={`xaxis`} id={`xaxis`} type="number" step="any" />
                    </div>
                    <div className={cx(styles["ubication-fields"])}>
                        <div className={cx(styles["control-label"], "h4-title")}>Longitud *</div>
                        <input className={cx(styles["ubication-inputs"], "select-control")}
                            ref={registerUbication} name={`yaxis`} id={`yaxis`} type="number" step="any" />
                    </div>
                </div>
                
            </form>

            <div className={cx(styles["temporality-button-container"])}>
                <div className={edit ? "temporality-button button" : "hide-button button"}
                    onClick={() => editLocation()}>Editar localización</div>

                <div className={edit ? "temporality-button button" : "hide-button button"}
                    onClick={() => leaveEditMode()}>Salir de editar</div>

                <button className={edit ? "hide-button button" : "temporality-button button"}
                    type="submit" form="ubication-form">Añadir localización</button>
            </div>

            <div className={cx(styles["map-container"])}>
                <SimpleMap style={{ height: "300px", width: "70%" }} zoom={8} location = {location}
                locations={locations} selector={props.selector} initialCenter={{ lat: xaxis, lng: yaxis }} />
            </div>
        </div>
    )
}

export default Ubication;