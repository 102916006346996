import React, { useEffect } from "react";
import cx from 'classnames';
import styles from './Verification.module.scss'
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { verification } from "state/auth/actions";
import { useDispatch, useSelector } from '../../state';
import verficacionmal from "images/verificacion-mal.png";
import verficacionok from "images/verificacion-ok.png";

const Verification = () => {

    const { formatMessage: f } = useIntl();
    const dispatch = useDispatch();
    const { error } = useSelector('auth');
    const { id } = useParams();

    console.log(error)

    useEffect(() => {
        if (id) {
            const post = async () => {
                await verification(dispatch, id);
            }
            post();
        }
    }, []);

    return (
        <div className={cx(styles["main-container"])} >
            <div className={cx(styles["panel"])}>
                <div className={cx(styles["panel-head"])}>
                    {error ?
                        <div className={cx(styles["head-text"])}>
                            <div className={cx(styles["img-container"])}><img src={verficacionmal} className={cx(styles["img"])} /></div>
                            <div className={cx(styles["text"])}>No se ha podido verificar su cuenta</div>
                        </div>
                        :
                        <div className={cx(styles["head-text"])}>
                            <div className={cx(styles["img-container"])}><img src={verficacionok} className={cx(styles["img"])} /></div>
                            <div className={cx(styles["text"])}>Su cuenta ha sido verificada</div>
                        </div>}
                </div>
            </div>
        </div>
    );

}

export default Verification;