import React, { useEffect } from 'react';
import cx from 'classnames';
import styles from './NewResource.module.scss';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useIntl } from "react-intl";



const SecondForm = (props) => {

    let history = useHistory();
    const { formatMessage: f } = useIntl();

    const resourceSecondStepSchema = Yup.object({
        contact: Yup.string().required("Por favor, debes especificar la persona o personas de contacto."),
        contactMail: Yup.string().email("Formato incorrecto").required("Por favor, debes especificar un correo de contacto").max(100, "Longitud del texto erronea"),
        confirm: Yup.string().required("Completa este campo").oneOf([Yup.ref("contactMail"), null], "Los correos no coinciden"),
        phone: Yup.string().required("Completa este campo"),
        checkbox1: Yup.boolean().oneOf([true], 'Selecciona esta casilla de verificación si quieres continuar'),
    }).required();

    const { register, handleSubmit, errors, setValue, getValues } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(resourceSecondStepSchema),
    });

    useEffect(() => {
        if (props.resource) {
            setValue("contact", props.resource.contact);
            setValue("contactMail", props.resource.contactMail);
            setValue("confirm", props.resource.contactMail);
            setValue("webPage", props.resource.webPage)
            setValue("twitterPage", props.resource.twitterPage);
            setValue("facebookPage", props.resource.facebookPage);
            setValue("phone", props.resource.phone);
            setValue("permiso", props.resource.permiso);
        }
    }, [props.resource]);

    return (
        <form id="resource-form2" onSubmit={handleSubmit(props.onSubmitSecondStep)}>
            <div className={cx(styles["header"], "subheader")}>
                <div className={cx(styles["header-text"], "h2-title")}>{f({ id: "resources.header" })}</div>
            </div>
            <div className={cx(styles["body-style"])}>
                {f({ id: "forms.info" })}<a className="links" onClick={(e) => { history.push('/ayuda'); history.go('/ayuda') }}>{f({ id: "forms.info1" })}</a>
            </div>
            <div className={cx(styles["body-style"])}>
                <div className={cx(styles["icons-container"])}>
                    <div className={"col-xs-4"}>
                        <div className={cx(styles["icon-circle"]) + ' ' + cx(styles["circle-completed"])}><img className={cx(styles["icon-completed"])} src={require("../../images/information.svg").default} /></div>
                        <div className={cx(styles["circle-text"])}>{f({ id: "resources.icon.info" })}</div>
                    </div>
                    <div className={"col-xs-4"}>
                        <div className={cx(styles["icon-circle"]) + ' ' + cx(styles["circle-active"])}><img className={cx(styles["icon-active"])} src={require("../../images/user.svg").default} /></div>
                        <div className={cx(styles["circle-text"])}>{f({ id: "resources.icon.data" })}</div>
                    </div>
                    <div className={"col-xs-4"}>
                        <div className={cx(styles["icon-circle"])}><img className={cx(styles["icon-desactive"])} src={require("../../images/check-relleno.svg").default} /></div>
                        <div className={cx(styles["circle-text"])}>{f({ id: "resources.icon.confirmation" })}</div>
                    </div>
                </div>
            </div>
            <div className="form-container">
                <div className={cx(styles["requieredfield"], "h4-title")} >* Campo Obligatorio</div>
                <div className={cx(styles["second-container"])}>


                    <div>
                        <div className={cx(styles["control-label"])}>{f({ id: "resourceSecondForm.contact" })}</div>
                        <textarea className={"select-control" + ' ' + cx(styles["form-control"]) + ' ' + cx(styles["textarea-control"])} ref={register} name='contact' id='contact' ></textarea>
                        {errors.contact && (
                            <div className="invalid-feedback">
                                {errors.contact.message}
                            </div>
                        )}
                    </div>
                    <div>
                        <div className={cx(styles["control-label"])}>{f({ id: "resourceSecondForm.email" })}</div>
                        <input className={cx(styles["form-control"], "select-control")} ref={register} name='contactMail' id='contactMail' />
                        {errors.contactMail && (
                            <div className="invalid-feedback">
                                {errors.contactMail.message}
                            </div>
                        )}
                    </div>
                    <div>
                        <div className={cx(styles["control-label"])}>{f({ id: "resourceSecondForm.web" })}</div>
                        <input className={cx(styles["form-control"], "select-control")} ref={register} name='webPage' id='webPage' />
                    </div>
                    <div>
                        <div className={cx(styles["control-label"])}>{f({ id: "resourceSecondForm.repeat.email" })}</div>
                        <input className={cx(styles["form-control"], "select-control")} ref={register} name='confirm' />
                        {errors.confirm && (
                            <div className="invalid-feedback">
                                {errors.confirm.message}
                                {console.log(errors.confirm.message)}
                            </div>
                        )}
                    </div>
                    <div>
                        <div className={cx(styles["control-label"])}>{f({ id: "resourceSecondForm.twitter" })}</div>
                        <input className={cx(styles["form-control"], "select-control")} ref={register} name='twitterPage' id='twitterPage' />
                    </div>
                    <div>
                        <div className={cx(styles["control-label"])}>{f({ id: "resourceSecondForm.phone" })}</div>
                        <input className={cx(styles["form-control"], "select-control")} ref={register} name='phone' id='phone' />
                        {errors.phone && (
                            <div className="invalid-feedback">
                                {errors.phone.message}
                            </div>
                        )}
                    </div>
                    <div>
                        <div className={cx(styles["control-label"])}>{f({ id: "resourceSecondForm.facebook" })}</div>
                        <input className={cx(styles["form-control"], "select-control")} ref={register} name='facebookPage' id='facebookPage' />
                    </div>
                </div>
                <div className={cx(styles["checkbox-container"])}>
                    <div className={"check-topics custom-check"}>
                        <input type="checkbox" className={cx(styles["check-activity"])} ref={register} name="checkbox1" id="checkbox1"/>
                        <label htmlFor='checkbox1' className={cx(styles["text-topics"])}>{f({ id: "forms.check" })}</label>
                    </div>
                    {errors.checkbox1 && (
                        <div className="invalid-feedback">
                            {errors.checkbox1.message}
                        </div>
                    )}
                    <div className={"check-topics custom-check"}>
                        <input type="checkbox" className={cx(styles["check-activity"])} ref={register} name="permiso" id='permiso' />
                        <label htmlFor='permiso' className={cx(styles["text-topics"])}>{f({ id: "forms.check1" })}</label>
                    </div>
                    {errors.checkbox2 && (
                        <div className="invalid-feedback">
                            {errors.checkbox2.message}
                        </div>
                    )}
                </div>
            </div>
            {props.infoContainer}
            <div className={cx(styles["footer-container"])}>
                <div className={cx(styles["buttons-container-index"])}>
                    <button onClick={() => { props.returnHome() }} className={cx(styles["button-return"], "button")}>{f({ id: "forms.start.again" })}</button>
                    <button onClick={() => { props.backpage() }} className={cx(styles["button-index"], "button")}>{f({ id: "forms.previous" })}</button>
                    <button form="resource-form2" className={cx(styles["button-index"], "button")} type="submit">{f({ id: "forms.next" })}</button>
                </div>
            </div>
        </form>

    );
}

export default SecondForm;