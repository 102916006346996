import React, { useEffect, useState } from "react";
import cx from 'classnames';
import styles from './ValidateResource.module.scss';
import { useDispatch, useSelector } from "state";
import { useParams, useHistory } from 'react-router-dom';
import { getResourceById, validateResource, getAllTopics } from 'state/resources/actions';
import { useForm } from "react-hook-form";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw } from 'draft-js';
import { useIntl } from "react-intl";



const ValidateResource = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { resource, selectedTopics, topics, determinantes } = useSelector('resources');
    const { id } = useParams();
    const [moreInfo, setMoreInfo] = useState(true);
    const [preview, setPreview] = useState();
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const { formatMessage: f } = useIntl();


    useEffect(() => {
        const getResource = async() => {
            await getResourceById(dispatch, {id: id});
        }
        const getTopics = async() => {
            await getAllTopics(dispatch);
        }
        getResource();
        getTopics();

    }, []);

    useEffect(() => {
        resource.photo ? setPreview(`${process.env.REACT_APP_IMG_URL}${resource.photo}`) : setPreview("");

        if (resource.description !== "" && resource.description !== undefined) {
            const parsed = convertFromRaw(JSON.parse(resource.description));
            setEditorState(EditorState.createWithContent(parsed));
        }

        if(resource.lastUpdateDate){
            const fecha = resource.lastUpdateDate.split('-');
            resource.lastUpdateDate = fecha[2]+"/"+fecha[1]+"/"+fecha[0];    
        }
        if(resource.topics){
            resource.topics.forEach((topic) => {
                dispatch({ type: 'ADD_REMOVE_SELECTED_TOPICS', payload: topic.id });
            })
        }

    }, [resource]);

    
    const post = async () => {
        
        const put = async () => {
            await validateResource(dispatch, data);
        }
        const data = {
            "id": resource.id,
            "topics": selectedTopics,
            "determinantes": determinantes,
            "validated": 1
        }

        put();

        history.push('/admin/recursos'); 
        history.go('/admin/recursos');
        
        dispatch({ type: 'SET_RESOURCE', payload: {} });
        dispatch({ type: 'SET_PHOTO', payload: [] });
        dispatch({ type: 'SET_TEMPORALITIES', payload: [] });
        dispatch({ type: 'SET_LOCATIONS', payload: [] });
        dispatch({ type: 'SET_PREVIEW', payload: [] });

    }

    const { register, handleSubmit, errors, watch, setValue, setError } = useForm({
        mode: "onSubmit",
    });

    const showMoreInfo = () => {
        setMoreInfo(!moreInfo);
    }

    const addRemoveSelectedTopic = (topic) => {
        dispatch({ type: 'ADD_REMOVE_SELECTED_TOPICS', payload: topic });
    }

    const addRemoveSelectedDeterminante = (determinante) => {
        dispatch({ type: 'ADD_REMOVE_DETERMINANTE', payload: determinante })
    }

    const openImg = (url) => {
        window.open(url, '_blank');
    }

    return (
        <div className={cx(styles["main-container"])}>
            
            <div className={cx(styles["subheader-container"])}>
                <div className={"subheader" + ' ' + cx(styles["subheader-edit"])}>
                    <div className='h2-title'>Validación del Recurso</div>
                </div>
                <div className={cx(styles["body-container"])}>
                    <div>
                        <div className={"col-sm-12 col-xs-12"}>
                            <div>
                                Red Adscrita
                                <img className={cx(styles['title-image'])} src={require("../../images/obsa_icon_hd_color.png")} alt="red" /><b>OBSA</b>
                            </div>
                            <br />
                            <div className={cx(styles[moreInfo ? 'show' : 'hide'])}>
                                <label>Resumen</label>
                                <div className="wrapperClassNamePerformance">{resource.summary}</div>
                            </div>
                            {
                                resource.description && resource.description !== "" && resource.description !== undefined &&
                                <>
                                    <div className={cx(styles[moreInfo ? 'hide' : 'show'])}>
                                        <label>Descripción</label>
                                        <Editor
                                            editorState={editorState}
                                            toolbarClassName="ACToolbar"
                                            wrapperClassName="wrapperClassNamePerformance"
                                            readOnly
                                        />
                                    </div>
                                    <br />
                                    <button onClick={showMoreInfo} className={"button" + ' ' + cx(styles["button-showdetails"])}>{moreInfo ? "Ver descripción" : "Ver resumen"}</button>
                                </>
                            }
                        </div>
                    </div>
                    <div className={"col-sm-12 col-xs-12 d-flex"} style={{flexWrap: "wrap"}}>
                        <div className={"col-sm-6 col-xs-6"}>
                            <div className={cx(styles["info-box"], "col-sm-12 col-xs-12")}>
                                <div className={cx(styles["subtitle"])}>Nº de referencia</div>
                                <div className={cx(styles["result"])}>{resource.id && resource.id}</div>
                            </div>
                            <div className={cx(styles["info-box"], "col-sm-12 col-xs-12")}>
                                <div className={cx(styles["subtitle"])}>Categoria</div>
                                <div className={cx(styles["result"])}>{resource.category && resource.category.description ? resource.category.description : ""}</div>
                            </div>
                            <div className={cx(styles["info-box"], "col-sm-12 col-xs-12")}>
                                <div className={cx(styles["subtitle"])}>Contacto</div>
                                <div className={cx(styles["result"])}>
                                    {resource.contact && resource.contact} - {resource.phone && resource.phone} - {resource.contactMail && resource.contactMail}
                                </div>
                            </div>
                            <div className={cx(styles["info-box"], "col-sm-12 col-xs-12")}>
                                <div className={cx(styles["subtitle"])}>Fecha de última actualización del recurso</div>
                                <div className={cx(styles["result"])}>{resource.lastUpdateDate ? resource.lastUpdateDate : "-"}</div>
                            </div>
                            
                            <div className={cx(styles["info-box"], "col-sm-12 col-xs-12")}>
                                <div className={cx(styles["subtitle"])}>Situación actual del recurso</div>
                                <div className={cx(styles["result"])}> { 
                                    resource.situation === 1 ? <span className={cx(styles["active-situation"])}>Activa</span> : resource.situation === 0 ? <span className={cx(styles["historical-situation"])}>Histórica</span> : "-" 
                                }
                                </div>
                            </div>
                        </div>
                        <div className={"col-sm-6 col-xs-6"}>
                            <div className={cx(styles["info-box"], "col-sm-12 col-xs-12")}>
                                <div className={cx(styles["subtitle"])}>Localización</div>
                                <div className={cx(styles["result"])}>
                                    { resource.location?.map((item, index) => {
                                        return (
                                            <div className={cx(styles["result"])} key={index}>
                                                {item.roadType?.description} {item.ubication} {item.street} {item.number}, {item.postalCode} - {item.municipality ? `${item.municipality.name}` : ""} {item.locality ? `${item.locality}` : ""}
                                            </div>
                                        )
                                    }) }
                                </div>
                            </div>
                            <div className={cx(styles["info-box"], "col-sm-12 col-xs-12")}>
                                <div className={cx(styles["subtitle"])}>Página web del proyecto</div>
                                {
                                    resource.webPage &&
                                    <div><a className="link" href={resource.webPage} target="blank">{resource.webPage}</a></div>
                                }
                                {
                                    resource.facebookPage &&
                                    <div><a className="link" href={resource.facebookPage} target="blank">{resource.facebookPage}</a></div>
                                }
                                {
                                    resource.twitterPage &&
                                    <div><a className="link" href={resource.twitterPage} target="blank">{resource.twitterPage}</a></div>
                                }
                            </div>
                            <div className={cx(styles["info-box"], "col-sm-12 col-xs-12")}>
                                <div className={cx(styles["subtitle"])}>Imagen del recurso</div>
                                <div className={cx(styles["result"])}>
                                    {resource.photo && <img style={{maxWidth: "300px"}} className={cx(styles["resource-img"])} src={preview} alt={preview} onClick={() => openImg(preview)}></img>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        resource.userValidated === 1 ?
                        <div className={cx(styles["status-bar-green"])}>
                            {f({id: "forms.check1"})}
                        </div> 
                        : 
                        <div className={cx(styles["status-bar-orange"])}>
                            {f({id: "forms.check1.denied"})}
                        </div>
                    }


                    

                    <form onSubmit={handleSubmit(post)}>
                        
                        <hr/>
                        <div className={"col-sm-12 col-xs-12 d-flex"} style={{flexWrap: "wrap"}}>
                            <div className={"col-sm-6 col-xs-6"}>
                                <label className="h4-title">TEMAS</label>

                                {topics && 
                                    topics.map((item) => 
                                        <div key={item.id} className={cx(styles["check-topics"])}>
                                            <img src={require(`../../images/${item.imgName}`)} className="icons" />
                                            <input className={cx(styles["checkbox"])} type="checkbox" id={item.description}  
                                            defaultChecked={resource.topics?.filter(e => e.id === item.id).length > 0}                                  
                                            onClick={(e) => addRemoveSelectedTopic(item.id)}/>
                                            <label htmlFor={item.description} className={cx(styles["text-topics"])}>{item.description}</label>
                                        </div>
                                    )
                                }
                                

                            </div>
                            <div className={`col-sm-6 col-xs-6 ${cx(styles["right-panel"])}`}>
                                <div className={`col-sm-12 col-xs-12 ${cx(styles["determinantes"])}`}>
                                    <label className="h4-title">DETERMINANTES</label>
                                    <div className={cx(styles["check-topics"])}>
                                        <input className={cx(styles["checkbox"])} type="checkbox" id="fisico"
                                        onClick={() => addRemoveSelectedDeterminante(1)}/>
                                        <label htmlFor="fisico" className={cx(styles["text-topics"])}>Entorno físico</label>
                                    </div>
                                    <div className={cx(styles["check-topics"])}>
                                        <input className={cx(styles["checkbox"])} type="checkbox" id="sanita"
                                        onClick={() => addRemoveSelectedDeterminante(2)}/>
                                        <label htmlFor="sanita" className={cx(styles["text-topics"])}>Recursos sanitarios</label>
                                    </div>
                                    <div className={cx(styles["check-topics"])}>
                                        <input className={cx(styles["checkbox"])} type="checkbox" id="habito"
                                        onClick={() => addRemoveSelectedDeterminante(3)}/>
                                        <label htmlFor="habito" className={cx(styles["text-topics"])}>Hábitos/Comportamientos saludables</label>
                                    </div>
                                    <div className={cx(styles["check-topics"])}>
                                        <input className={cx(styles["checkbox"])} type="checkbox" id="economico"
                                        onClick={() => addRemoveSelectedDeterminante(4)}/>
                                        <label htmlFor="economico" className={cx(styles["text-topics"])}>Factores socioeconómicos</label>
                                    </div>
                                </div>
                                
                                <div className={"col-sm-12 col-xs-12"}>
                                    <label>Buscadores de evidencia</label>
                                    <ul>
                                        <li><a className="link" href="https://www.countyhealthrankings.org/take-action-to-improve-health/what-works-for-health">County Health Rankings & Roadmaps: What Works for Health</a></li>
                                        <li><a className="link" href="https://www.thecommunityguide.org/">The Community Guide</a></li>
                                        <li><a className="link" href="https://eppi.ioe.ac.uk/webdatabases4/Intro.aspx?ID=9">EPPI Centre: Database of promoting health effectiveness reviews (DoPHER)</a></li>
                                    </ul>
                                </div>
                                <div className={cx(styles["check-topics"], styles["hist-clinica"])}>
                                    <input className={cx(styles["checkbox"])} type="checkbox" id="hclinica"
                                    onClick={() => addRemoveSelectedDeterminante(5)}/>
                                    <label htmlFor="hclinica" className={cx(styles["text-topics"])}>Aparece en la historia clínica de Atención Primaria</label>
                                </div>
                            </div>  
                        </div>
                        <div className={cx(styles["button-container"])}>
                            <button variant="contained" className={cx(styles["button-index"], "button")} type="submit">Validar Recurso</button>
                        </div>

                    </form>
                    
                </div>
            </div>
        </div>
    )

}

export default ValidateResource;