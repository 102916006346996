import {
  postMultipart,
  get,
  put,
  deleteMethod,
  post,
  putMultipart,
  putValidation,
  putRenewal,
  getCsv,
  getPdf
} from "../../api/client";
const API_URL = process.env.REACT_APP_API_URL;

const getActivitiesUrlQuery = (paramsObject) => {
  let urlQuery = "activities/"

  urlQuery = paramsObject.url ? urlQuery + paramsObject.url : urlQuery;

  if (
    paramsObject.userId ||
    paramsObject.type ||
    paramsObject.keyword ||
    paramsObject.pageIndex ||
    paramsObject.direction ||
    paramsObject.field ||
    paramsObject.validated ||
    paramsObject.typeActivity ||
    paramsObject.email ||
    paramsObject.url
  ) {
    let concatCaracter = "?";
    /*if (sessionStorage.getItem("userId")) {
      urlQuery += concatCaracter + "userId=" + sessionStorage.getItem("userId");
      concatCaracter = "&";
    }*/
    if(paramsObject.typeActivity){
      if(paramsObject.typeActivity === 1){
        urlQuery += concatCaracter + "email=" + paramsObject.email + "&validated=1";
      }else{
        urlQuery += concatCaracter + "userId=" + sessionStorage.getItem("userId");
      }
      concatCaracter = "&";
    }
    if (paramsObject.keyword) {
      urlQuery += concatCaracter + "keyword=" + paramsObject.keyword;
      concatCaracter = "&";
    }
    if (paramsObject.type) {
      urlQuery += concatCaracter + "type=" + paramsObject.type;
      concatCaracter = "&";
    }
    if (paramsObject.pageIndex) {
      urlQuery += concatCaracter + "pageIndex=" + paramsObject.pageIndex;
      concatCaracter = "&";
    }
    if (paramsObject.direction) {
      urlQuery += concatCaracter + "direction=" + paramsObject.direction;
      concatCaracter = "&";
    }
    if (paramsObject.field) {
      urlQuery += concatCaracter + "field=" + paramsObject.field;
      concatCaracter = "&";
    }
    if(paramsObject.selectedSituation === 0){
      urlQuery += concatCaracter + "situation=" + paramsObject.selectedSituation;
      concatCaracter = "&";
    }else{
      if (paramsObject.validated !== "" && paramsObject.validated !== undefined) {
        urlQuery += concatCaracter + "validated=" + paramsObject.validated;
        concatCaracter = "&";
      }
    }
  }
  return urlQuery;
};

export const getActivities = async (dispatch, paramsObject) => {
  
  try {
    dispatch({ type: "GET_ACTIVITIES" });
    
    let response = {};
    let responseActivitiesQuery = await get(
      getActivitiesUrlQuery(paramsObject)
    );

    response.content = responseActivitiesQuery.content;
    response.totalElements = responseActivitiesQuery.totalElements;
    response.totalPages = responseActivitiesQuery.totalPages;

    dispatch({ type: "GET_ACTIVITIES_SUCCESS", payload: response });
  } catch (error) {
    dispatch({ type: "GET_ACTIVITIES_FAILURE" });
  }
};

export const getActivitiesCsv = async (paramsObject) => {
  
  try {
    const url = getActivitiesUrlQuery(paramsObject);
    await getCsv(url);
  } catch (error) {
    console.log(error);
  }
}

export const getActivitiesPdf = (paramsObject) => {
  return API_URL+'/'+buildGetPerformancesUrlQuery(paramsObject)
}


export const postActivity = async (dispatch, paramsObject) => {
  try {
    //const activityPost = { performance: activity, photo, locations: JSON.stringify(locations), temporalities: JSON.stringify(temporalities) };
    //dispatch({ type: "SET_ACTIVITY" });
    dispatch({ type: 'SET_LOADING', payload: true});
    let data = await postMultipart(`activities/`, paramsObject);
    dispatch({ type: 'SET_ACTIVITY_SUCCESS'});
    dispatch({ type: 'SET_LOADING', payload: false});
  } catch (error) {
    console.log(error);
    dispatch({ type: 'SET_LOADING', payload: false});
    dispatch({ type: "SET_ACTIVITY_FAILURE" });
  }
};

export const editActivity = async (dispatch, paramsObject) => {
  try {
    /* const activityPut = { "id": paramsObject.performance?.id, "performance": paramsObject.performance, "photo": paramsObject.photo, 
            "locations": JSON.stringify(paramsObject.locations), "temporalities": JSON.stringify(paramsObject.temporalities) }; */
    dispatch({ type: "SET_ACTIVITY" });
    let data = await putMultipart(`activities`, paramsObject);
    dispatch({ type: "SET_ACTIVITY_SUCCESS", payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "SET_ACTIVITY_FAILURE" });
  }
};


export const validateActivity = async (dispatch, paramsObject) => {
  
  try {
    //dispatch({ type: "SET_ACTIVITY" });
    let data = await putValidation(`activities`, paramsObject);
    dispatch({ type: "SET_ACTIVITY_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "SET_ACTIVITY_FAILURE" });
  }
}

export const renewActivity = async (dispatch, id) => {
  try {
    dispatch({ type: "RENEW_ACTIVITY" });
    await putRenewal(`activities`, id);
    dispatch({ type: "RENEW_ACTIVITY_SUCCESS" });
  } catch (error) {
    dispatch({ type: "RENEW_ACTIVITY_FAILURE" });
  }
}

export const deleteActivity = async (dispatch, id) => {
  try {
    dispatch({ type: "DELETE_ACTIVITY" });
    await deleteMethod(`activities/${id}`);
    dispatch({ type: "DELETE_ACTIVITY_SUCCESS", payload: id });
  } catch (error) {
    console.log(error);
    dispatch({ type: "DELETE_ACTIVITY_FAILURE" });
  }
};

const buildGetPerformancesUrlQuery = (paramsObject) => {
  let urlQuery = "performance";
  urlQuery = paramsObject.url ? urlQuery + paramsObject.url : urlQuery;

  if (
    paramsObject.id ||
    paramsObject.topics ||
    paramsObject.municipio ||
    paramsObject.demographicGroup ||
    paramsObject.gender ||
    paramsObject.situation ||
    paramsObject.pageIndex ||
    paramsObject.direction ||
    paramsObject.field ||
    paramsObject.keyword ||
    paramsObject.tableKeyword ||
    paramsObject.type ||
    paramsObject.validated 
  ) {
    let concatCaracter = "?";
    if (paramsObject.id) {
      urlQuery += concatCaracter + "id=" + paramsObject.topics;
      concatCaracter = "&";
    }
    if (paramsObject.topics && paramsObject.topics.length > 0) {
      urlQuery += concatCaracter + "topics=" + paramsObject.topics;
      concatCaracter = "&";
    }
    if (paramsObject.municipio !== "0" && paramsObject.municipio !== "") {
      urlQuery += concatCaracter + "municipality=" + paramsObject.municipio;
      concatCaracter = "&";
    }
    if (paramsObject.keyword) {
      urlQuery += concatCaracter + "keyword=" + paramsObject.keyword;
      concatCaracter = "&";
    }
    if (paramsObject.tableKeyword) {
      urlQuery += concatCaracter + "tableKeyword=" + paramsObject.tableKeyword;
      concatCaracter = "&";
    }
    if (paramsObject.type) {
      urlQuery += concatCaracter + "type=" + paramsObject.type;
      concatCaracter = "&";
    }
    if (paramsObject.demographicGroup) {
      urlQuery +=
        concatCaracter + "demographicGroup=" + paramsObject.demographicGroup;
      concatCaracter = "&";
    }
    if (paramsObject.gender) {
      urlQuery += concatCaracter + "gender=" + paramsObject.gender;
      concatCaracter = "&";
    }
    if (paramsObject.situation) {
      urlQuery += concatCaracter + "situation=" + paramsObject.situation;
      concatCaracter = "&";
    }
    if (paramsObject.pageIndex) {
      urlQuery += concatCaracter + "pageIndex=" + paramsObject.pageIndex;
      concatCaracter = "&";
    }
    if (paramsObject.direction) {
      urlQuery += concatCaracter + "direction=" + paramsObject.direction;
      concatCaracter = "&";
    }
    if (paramsObject.field) {
      urlQuery += concatCaracter + "field=" + paramsObject.field;
      concatCaracter = "&";
    }
    if(paramsObject.validated){
      urlQuery += concatCaracter + "validated=" + paramsObject.validated;
      concatCaracter = "&";
    }
  }
  return urlQuery;
};

export const getActivityById = async (dispatch, paramsObject) => {
  try {
    dispatch({ type: "GET_ACTIVITY" });
    const data = await get(`performance/${paramsObject.id}`);
    dispatch({ type: "GET_ACTIVITY_SUCCESS", payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_ACTIVITY_FAILURE" });
  }
};

export const getPerformancePdf = async (paramsObject) => {
  try {
    const url = `performance/generate-performance-pdf`;
    await getPdf(url, paramsObject);
  } catch (error) {
    console.log(error);
  }
}

export const getCountryById = async (dispatch, paramsObject) => {
  try {
    dispatch({ type: "GET_COUNTRY" });
    const data = await get(`country/${paramsObject.id}`);
    dispatch({ type: "GET_COUNTRY_SUCCESS", payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_COUNTRY_FAILURE" });
  }
};


export const getAllTopics = async (dispatch) => {
  try {
    dispatch({type: "GET_ALL_TOPICS"});
    const data = await get(`topic`);
    dispatch({type: "GET_ALL_TOPICS_SUCCESS", payload: data});
  } catch (error) {
    console.log(error);
    dispatch({type: "GET_ALL_TOPICS_FAILURE"});
  }
}

export const getTableElements = async (dispatch, paramsObject) => {
  try {
    dispatch({ type: "SEARCH_TABLE_ELEMENTS" });
    let data = await get(buildGetPerformancesUrlQuery(paramsObject));
    dispatch({ type: "SEARCH_TABLE_ELEMENTS_SUCCESS", payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "SEARCH_TABLE_ELEMENTS_FAILURE" });
  }
};

export const getAllLocations = async(dispatch, paramsObject) => {
  try {
    dispatch({type: "SET_MAP_ELEMENTS"});
    paramsObject.url = "/total"
    let data = await get(buildGetPerformancesUrlQuery(paramsObject));
    dispatch({type: "SET_MAP_ELEMENTS_SUCCESS", payload: data.content});
  } catch (error) {
    dispatch({type: "SET_MAP_ELEMENTS_FAILURE"});
  }

}

export const initializeSearcher = async (dispatch, defaultValues) => {
  try {
    dispatch({ type: "INITIALIZE_SEARCHER" });
    let response = {};
    response.topics = await get(`topic`);

    response.municipalities = await get(`municipalities/pr/33`);

    response.genders = await get(`code/idtype/${2}/`);
    response.demgr = await get(`code/idtype/${1}`);

    let responseActivitiesQuery = await get(
      buildGetPerformancesUrlQuery(defaultValues)
    );

    response.tableElements = responseActivitiesQuery.content;

    response.totalPages = responseActivitiesQuery.totalPages;
    response.totalElements = responseActivitiesQuery.totalElements;

    dispatch({ type: "INITIALIZE_SEARCHER_SUCCESS", payload: response });
  } catch (error) {
    console.log(error);
    dispatch({ type: "INITIALIZE_SEARCHER_FAILURE" });
  }
};

export const initializeNewActivityForm = async (dispatch) => {
  try {
    dispatch({ type: "INITIALIZE_NEW_ACTIVITIES_FORM" });
    let response = {};
    response.temporalitiesList = await get(`code/idtype/${5}/`);
    response.freeList = await get(`code/idtype/${4}/`);
    response.genders = await get(`code/idtype/${2}/`);
    response.demgr = await get(`code/idtype/${1}`);
    response.municipalities = await get(`municipalities/pr/33`);
    response.types = await get(`code/idtype/${7}`);

    dispatch({
      type: "INITIALIZE_NEW_ACTIVITIES_FORM_SUCCESS",
      payload: response,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: "INITIALIZE_NEW_ACTIVITIES_FORM_FAILURE" });
  }
};
