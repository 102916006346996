import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from '../../state';
import { useIntl } from "react-intl";
import ReactLoading from 'react-loading';
import PaginationComponent from "components/pagination/PaginationComponent";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import styles from './ResourcesAdmin.module.scss'
import cx from 'classnames';

import { getResources, deleteResource, renewResource, getResourcesCsv } from '../../state/resources/actions';

const ActivitiesAdmin = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { formatMessage: f } = useIntl();
    const [modal, setModal] = useState(false);
    const [modalVal, setModalVal] = useState(false);
    const [modalRenov, setModalRenov] = useState(false);
    const { field, direction, resources, totalPages, loading, pageIndex, keyword, validated, selectedSituation, renew } = useSelector("resources");
    const [tab, setTab] = useState(1);
    const [idResource, setIdResource] = useState(null);


    const toggle = (id) =>  {
        if (!modal) {
            setModal(true);
            setIdResource(id);
        } else {
            setModal(false);
            setIdResource(null);
        }
    }


    const toggleVal = () =>  {
        if (!modalVal) {
            setModalVal(true);
        } else {
            setModalVal(false);
        }
    }


    const toggleRenov = (id) =>  {
        if (!modalRenov) {
            setModalRenov(true);
            setIdResource(id);
        } else {
            setModalRenov(false);
            setIdResource(null);
        }
    }

    useEffect(() => {
        const getResourcesData = async () => {
            await getResources(dispatch, { 
                userId: 1, pageIndex: pageIndex, field: field, direction: direction
            });
        };
        getResourcesData();

    }, []);


    useEffect(() => {
        const updateTableResources = async () => {
            await getResources(dispatch, {
                keyword: keyword, pageIndex: pageIndex, validated: validated, field: field, direction: direction, selectedSituation: selectedSituation
            });
        }
        updateTableResources();
        dispatch({ type: "RENEW_RESOURCE" });


    }, [field, direction, pageIndex, keyword, validated, selectedSituation]);

    const changeSort = (selectedField) => {

        dispatch({ type: 'SET_PAGE_INDEX', payload: 0 });

        if (selectedField !== field) {
            dispatch({ type: 'SET_FIELD', payload: selectedField });
            dispatch({ type: 'SET_DIRECTION', payload: "ASC" })
        } else if (direction === "ASC") {
            dispatch({ type: 'SET_DIRECTION', payload: "DESC" });
        } else {
            dispatch({ type: 'SET_DIRECTION', payload: "ASC" });
        }
    }

    const changeFilter = (filter) => {
        dispatch({ type: 'SET_PAGE_INDEX', payload: 0 });
        if(filter === 1 || filter === 0 || filter === ""){
            dispatch({ type: 'SET_SELECTED_SITUATION', payload: 1 });
            dispatch({ type: 'SET_VALIDATED', payload: filter });
        }else if(filter === 2){
            dispatch({ type: 'SET_SELECTED_SITUATION', payload: 0 });
        }
    }

    const changePageIndex = (pageIndex) => {
        dispatch({ type: 'SET_PAGE_INDEX', payload: pageIndex });
    };

    const changeKeyword = (keyword) => {
        dispatch({ type: 'SET_KEYWORD', payload: keyword });
    };

    const deleteMethod = () => {
        if(idResource !== null){
            deleteResource(dispatch, idResource);
            setModal(false);
        }
    };

    const renewMethod = () => {
        if(idResource !== null){
            renewResource(dispatch, idResource);
            setModalRenov(false);
            changeFilter("");
            setTab(1);
        }
    };

    const getCsvDoc = () => {

        const data = {
            url: 'export-to-csv/',
            keyword: keyword,
            validated: validated,
            selectedSituation: selectedSituation
        }

        const getActivitiesCsvDoc = async (data) => {
            await getResourcesCsv(data)
        }

        getActivitiesCsvDoc(data);        
    }

    const sorting = (selectedField) => {
        return (field === selectedField ? direction === 'ASC' ? 'asc' : 'desc' : 'sorting')
    }

    const goToEdit = (id) => {
        history.push(`/recurso/editar/${id}`);
    };

    const goToValidate = (id) => {
        history.push(`/recurso/validar/${id}`);
    };


    return (
        <div className={cx(styles["main-container"])}>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>¿Estás seguro que deseas eliminar la actividad?</ModalHeader>
                <ModalBody>
                    <p>Una vez eliminada la actividad, no se podrá recuperar.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={deleteMethod}>Eliminar</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalVal} toggle={toggleVal}>
                <ModalHeader toggle={toggleVal}>El recurso ya se encuentra validado</ModalHeader>
                <ModalBody>
                    <p>No puede volver a validar un recurso que ya ha sido validado.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleVal}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalRenov} toggle={toggleRenov}>
                <ModalHeader toggle={toggleRenov}>Renovación de Recurso</ModalHeader>
                <ModalBody>
                    <p>La fecha fin del Recurso se incrementará en 2 años desde la última fecha fin establecida.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={toggleRenov}>Cerrar</Button>
                    <Button color="secondary" onClick={renewMethod}>Aceptar</Button>
                </ModalFooter>
            </Modal>

            <div className="admin-header">
                <div><img src={require("../../images/obsa-grande.png")}></img></div>
                <div><img src={require("../../images/logo-consejeria.png")}></img></div>
            </div>
            <div className={cx(styles["subheader-activities-admin"], "subheader")}>
                <div className='h2-title'>Lista de recursos</div>
            </div>

            <div className={cx(styles['filters-container'])}>
                <div className={cx(styles['filters'])}>
                    <div onClick={() => { changeFilter(""); setTab(1) }} className={tab === 1 ? cx(styles['filter'], styles["filter-active"], "button") : cx(styles['filter'], "button")}>Todos</div>
                    <div onClick={() => { changeFilter(1); setTab(2) }} className={tab === 2 ? cx(styles['filter'], styles["filter-active"], "button") : cx(styles['filter'], "button")}>Validados</div>
                    <div onClick={() => { changeFilter(0); setTab(3) }} className={tab === 3 ? cx(styles['filter'], styles["filter-active"], "button") : cx(styles['filter'], "button")}>No validados</div>
                    <div onClick={() => { changeFilter(2); setTab(4) }} className={tab === 4 ? cx(styles['filter'], styles["filter-active"], "button") : cx(styles['filter'], "button")}>Históricos</div>
                </div>
                <div className={cx(styles['searcher-container'])}>
                    <div className={cx(styles['searcher'])}>
                        <div className={cx(styles["btn-search"], "button")}>Buscar</div>
                        <input className={cx(styles["search-control"], 'select-control')} onChange={(e) => { changeKeyword(e.target.value) }} placeholder="Buscar"></input>
                    </div>
                </div>
            </div>
            <div className="results-container">
                {loading ?
                    <div className='loading-container'><ReactLoading type={'bars'} color={'#DC042B'} height={128} width={128} /></div>
                    :
                    <table>
                        <thead>
                            <tr>
                                <th className={`h4-title col-sm-1  ${sorting("id")}`} onClick={() => { changeSort("id") }}>Ref.</th>
                                <th className={`h4-title col-sm-3  ${sorting("name")}`} onClick={() => { changeSort("name") }}>Nombre de la actividad</th>
                                <th className={`h4-title col-sm-4  ${sorting("summary")}`} onClick={() => { changeSort("summary") }}>Breve resumen</th>
                                <th className={`h4-title col-sm-2  ${sorting("municipality")}`} onClick={() => { changeSort("municipality") }}>Municipio</th>
                                <th className={`h4-title col-sm-3  ${sorting("contactMail")}`} onClick={() => { changeSort("contactMail") }}>Editor</th>
                                <th className={'h4-title col-sm-1'}>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {resources && resources.length > 0 ?
                                resources.map((item, index) =>
                                    <tr key={index}>
                                        <td>{item.id}</td>
                                        <td className={cx(styles["activities-name"])}>{item.name}</td>
                                        <td>{item.summary}</td>
                                        <td>{item.municipality ? item.municipality : '-'}</td>
                                        <td>{item.contactMail}</td>
                                        <td className={cx(styles["actions-container"])}>
                                            
                                        
                                            <div className={"d-flex"}>
                                            {selectedSituation === 0 ? 
                                                <div title="Renovar" className={cx(styles["actions-button"], "button")} onClick={() => toggleRenov(item.id)}>
                                                    <img src={require('../../images/refresh.svg').default} className={cx(styles["actions-img"])}></img>
                                                </div>
                                                :
                                                <div title="Validar" className={cx(styles["actions-button"], "button")} onClick={() => item.validated ? toggleVal() : goToValidate(item.id)}>
                                                    <img src={require('../../images/check-relleno.svg').default} className={cx(styles["actions-img"])}></img>
                                                </div>
                                            }
                                                <div title="Editar" className={cx(styles["actions-button"], "button")} onClick={() => goToEdit(item.id)}>
                                                    <img src={require('../../images/pen-to-square-solid.svg').default} className={cx(styles["actions-img"])}></img>
                                                </div>
                                                <div title="Eliminar" className={cx(styles["actions-button"], "button")} onClick={() => toggle(item.id)}>
                                                    <img src={require('../../images/trash-solid.svg').default} className={cx(styles["actions-img"])}></img>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                                :
                                <tr><td colSpan="5" >{f({ id: "app.table.empty" })}</td></tr>
                            }
                        </tbody>
                    </table>
                }
                
            </div>
            <PaginationComponent pageIndex={pageIndex} onPageIndexChange={(page) => changePageIndex(page)} items={resources} totalPages={totalPages} />
            <div className={cx(styles["btn-container"])}>
                <div className={cx(styles["btn-csv-download"], "button")}>
                    <img className={cx(styles["csv-icon"])} src={require('../../images/svc.svg').default} />
                    <div className={cx(styles["btn-text-csv"])}>
                        <button style={{marginTop: "-5px"}}className="button" onClick={getCsvDoc}>Descargar CSV</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ActivitiesAdmin;