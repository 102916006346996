import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from '../../state';
import { getActivityById, getPerformancePdf} from 'state/activities/actions';
import ReactLoading from 'react-loading';
import SimpleMap from '../../components/map/Map.js';
import cx from 'classnames';
import styles from './ShowActivity.module.scss';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';


const ShowActivity = () => {

    const dispatch = useDispatch();

    const history = useHistory();
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const { activity, loading, xAxis, yAxis } = useSelector('activities');
    const { id } = useParams();
    const [preview, setPreview] = useState();

    const goBack = () => {
        history.goBack();
    };

    useEffect(() => {

        if (activity.photo) {
            setPreview(`${process.env.REACT_APP_IMG_URL}${activity.photo}`);
        } else {
            setPreview("")
        }
        
        
        if (activity.description !== "" && activity.description !== undefined) {
            const parsed = convertFromRaw(JSON.parse(activity.description));
            setEditorState(EditorState.createWithContent(parsed));
        }

    }, [activity]);

    const getPdfUrl = () => {

        const data = {
           // topics: resource.topics,
            name: activity.name,
            id: activity.id,
            summary: activity.summary,
            description: editorState.getCurrentContent().getPlainText(),
            contact: activity.contact,
            phone: activity.phone,
            contactMail: activity.contactMail,
            lastUpdateDate: activity.creationDate,
            situation: activity.situation,
            location: activity.location,
            photo: activity.photo
        }

        const auxFunction = async () => {
            await getPerformancePdf(data);
        }

        auxFunction();
    }

    useEffect(() => {

        const getActivity = async () => {
            await getActivityById(dispatch, { id });
        }

        getActivity();

    }, []);

    const openImg = (url) => {
        window.open(url, '_blank');
    }


    const [toggle, setToggle] = useState(true);
    const showMoreInfo = () => {
        setToggle((toggle) => !toggle);
    }

    return (

        loading ? <div className='loading-container'><ReactLoading type={'bars'} color={'#DC042B'} height={128} width={128} /></div> :
            <>
                <div className={cx(styles["main-container"])}>
                    <div className={cx(styles["sa-panel-heading"])}>
                        <div className='h2-title'>{activity.name}</div>
                    </div>
                    <div className={cx(styles['sa-body-panel'])}>
                        <div className={cx(styles['sa-top-panel'])}>
                            <div className={cx(styles['sa-title'])}>
                                Red adscrita<img className={cx(styles['title-image'])} src={require("../../images/obsa_icon_hd_color.png")} alt="red" /><b>OBSA</b>
                            </div>
                            <div className={cx(styles['sa-topics-container'])}>
                                <div className={cx(styles['sa-topics'])}>
                                    <div>Temas</div>
                                    {activity.topics?.map((item) => {
                                        return (
                                            <img title={item.description} key={item.id} className={cx(styles["sa-topics-image"])} 
                                            src={require(`../../images/${item.imgName}`)} alt="Imagen" />
                                        )
                                    })}
                                </div>

                            </div>
                            <div className={cx(styles['sa-summary-container'])}>
                                <div>
                                    <img className={cx(styles["activity-img"])} src={preview} alt={preview} onClick={() => openImg(preview)}/>
                                </div>
                                <div className={cx(styles[toggle ? 'sa-summary-big-none' : 'sa-summary-big-block'])}>
                                    <p><b>Descripción</b></p>
                                    <Editor
                                            editorState={editorState}
                                            toolbarClassName="ACToolbar"
                                            wrapperClassName="wrapperClassNamePerformance"
                                            readOnly
                                    /> 
                                </div>
                                <div className={cx(styles[toggle ? 'sa-summary-block' : 'sa-summary-none'])}>{activity.summary}</div>
                                <div className={cx(styles["sa-button"]) + ' ' + cx(styles['sa-details-button'])} onClick={() => showMoreInfo()}>
                                    
                                    {toggle ? 
                                    <div className={cx(styles["sa-button-text"])} >Ver detalles »</div>
                                    :
                                    <div className={cx(styles["sa-button-text"])} >Ver resumen »</div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={cx(styles['sa-mid-panel'])}>
                            <div className={cx(styles['sa-left-panel'])}>

                                <div className={cx(styles['separator'])}>
                                    <div className={cx(styles['separator-title'])}>Poblacion</div>
                                    <div className={cx(styles['separator-text'])}>{activity.demographicGroup}</div>
                                </div>

                                <div className={cx(styles['separator'])}>
                                    <div className={cx(styles['separator-title'])}>Contacto</div>
                                    <div className={cx(styles['separator-text'])}>{activity.contact} ({activity.phone}) ({activity.contactMail})</div>
                                </div>

                                <div className={cx(styles['separator'])}>
                                    <div className={cx(styles['separator-title'])}>Entidad participante/colaboradora/promotora</div>
                                    <div className={cx(styles['separator-text'])}>{activity.entity}</div>
                                </div>

                                <div className={cx(styles['separator'])}>
                                    <div className={cx(styles['separator-title'])}>Fecha de última actualización de la actividad</div>
                                    <div className={cx(styles['separator-text'])}>{activity.creationDate}</div>
                                </div>

                                <div className={cx(styles['separator'])}>
                                    <div className={cx(styles['separator-title'])}>Situación</div>
                                    <div className={cx(styles['separator-text'])}>{activity.situation === 1 ?
                                        <div className={cx(styles['situation-active-container'])}>Activa</div>
                                        :
                                        <div className={cx(styles["situation-historical-container"])}>Histórica</div>}</div>
                                </div>

                                <div className={cx(styles['separator'])}>
                                    <div className={cx(styles['separator-title'])}>Gratuita</div>
                                    <div className={cx(styles['separator-text'])}>{activity.free ? activity.free : "No"}</div>
                                </div>

                            </div>

                            <div className={cx(styles['sa-right-panel'])}>

                                <div className={cx(styles['separator'])}>
                                    <div className={cx(styles['separator-title'])}>Localización</div>
                                    <div className={cx(styles['separator-text'])}>
                                        {activity.location?.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                {item.roadType?.description !== undefined ? item.roadType?.description + " " : ""}
                                                    {item.street !== undefined ? item.street + " " : ""}
                                                    {item.postalCode !== undefined ? item.postalCode + " " : ""}
                                                    {item.locality !== undefined ? item.locality + " " : ""}
                                                    {item.ubication !== undefined ? item.ubication + " " : ""}
                                                    {item.municipality?.name !== undefined ? item.municipality?.name : ""}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div className={cx(styles['separator']) + ' ' + cx(styles['map'])}>
                                    <div className={cx(styles['separator-text'])}>
                                        <SimpleMap style={{ width: "70%", maxWidth: 510, height: 300 }} zoom={8} initialCenter={{ lat: xAxis, lng: yAxis }} detailView={true} locations={activity?.location}/>
                                    </div>

                                </div>

                                {activity.temporality === "Temporal" ?
                                    <div className={cx(styles['separator'])}>
                                        <div className={cx(styles['separator-title'])}>Fecha de la Actividad</div>
                                        <div className={cx(styles['separator-text'])}>De {activity.creationDate} a {activity.endDate}</div>
                                    </div>
                                    :
                                    ""
                                }

                                {activity.otherDetails ?
                                    <div className={cx(styles['separator'])}>
                                        <div className={cx(styles['separator-title'])}>Otros detalles</div>
                                        <div className={cx(styles['separator-text'])}>{activity.otherDetails}</div>
                                    </div>
                                    :
                                    ""
                                }

                                {activity.webPages ?
                                    <div className={cx(styles['separator'])}>
                                        <div className={cx(styles['separator-title'])}>Página web del proyecto</div>
                                        <a className={cx(styles['separator-text'])} href={activity.webPage}>{activity.webPage}</a>
                                    </div>
                                    : ""}

                                {activity.facebookPage ?
                                    <div className={cx(styles['separator'])}>
                                        <div className={cx(styles['separator-title'])}>Facebook</div>
                                        <a className={cx(styles['separator-text'])} href={activity.facebookPage}>{activity.facebookPage}</a>
                                    </div>
                                    : ""}
                                {activity.twitterPage ?
                                    <div className={cx(styles['separator'])}>
                                        <div className={cx(styles['separator-title'])}>Twitter</div>
                                        <a className={cx(styles['separator-text'])} href={activity.twitterPage}>{activity.twitterPage}</a>
                                    </div>
                                    : ""}
                                
                                <div className={cx(styles['separator'])}>
                                    <div onClick={getPdfUrl} className={cx(styles['sa-button']) + ' ' + cx(styles['button-download'])}>
                                        <img src={require("../../images/pdf.svg").default} className={cx(styles['btn-icon'])} />
                                        <div className={cx(styles['sa-button-text'])}>Descargar PDF</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={cx(styles['back-button-container'])}>
                    <div className={cx(styles['back-button']) + ' ' + cx(styles['sa-button']) + ' ' + cx(styles['sa-button-text'])} onClick={() => goBack()}>Volver a las actuaciones</div>
                </div>
            </>


    );
}

export default ShowActivity;