import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from '../../state';
import styles from './Admin.module.scss'
import cx from 'classnames';
import { useEffect } from "react";

const Admin = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <div className={cx(styles["main-container"])}>
            <div className={cx(styles["adminContainer"])}>
                <div><img src={require("../../images/obsa-grande.png")}></img></div>
                <div><img src={require("../../images/logo-consejeria.png")}></img></div>
            </div>
            <div className={cx(styles["subheader-activities-admin"], "subheader")}>
                <div className='h2-title'>Administración de Actuaciones</div>
            </div>
            <div className={cx(styles["admin-content"])}>
                <div>Área de administración de actividades comunitarias y recursos saludables del Observatorio de Salud en Asturias (OBSA) y el Programa de Actividades Comunitarias en Atención Primaria (PACAP).</div>
                <br />
                <div>Para ver y modificar actividades comunitarias, entra en este enlace: <a className={cx(styles['link'])} onClick={() => { history.push('/admin/actividades'); history.go('/admin/actividades') }}>Actividades</a></div>
                <div>Para ver y modificar recursos saludables, entra en este enlace: <a className={cx(styles['link'])} onClick={() => { history.push('/admin/recursos'); history.go('/admin/recursos') }}>Recursos</a></div>
                <br />
                {/* <div>Para importar actividades comunitarias, entra en este enlace: <a className={cx(styles['link'])} onClick={() => { history.push('/actividad/importar'); history.go('/actividad/importar') }}>Importar actividades comunitarias</a></div>
                <div>Para importar recursos saludables, entra en este enlace: <a className={cx(styles['link'])} onClick={() => { history.push('/admin'); history.go('/admin') }}>Importar recursos saludables</a></div>
                <div>Para validar individualmente usuarios no validados, entra en este enlace: <a className={cx(styles['link'])}onClick={() => { history.push('/admin'); history.go('/admin') }}>Validación manual de usuarios</a></div> */}
            </div>

            {/* <div className="web-information">
                <div>
                    <a href='https://www.facebook.com/ObservatorioSalud.Asturias' target="_blank" className={cx(styles['icons'])}><img src={require('../../images/icon_facebook.png')} alt="Facebook"/></a>
                    <a href='https://twitter.com/#!/obsaludasturias' target="_blank" className={cx(styles['icons'])}><img src={require('../../images/icon_twitter.png')} alt="Twitter"/></a>
                    <a href='https://www.obsaludasturias.com/obsa/feed/' target="_blank" className={cx(styles['icons'])}><img src={require('../../images/icon_rss.png')} alt="RSS"/></a>
                </div>
                <div><img src={require("../../images/logo-consejeria.png")} /></div>
                <br />
            </div> */}


        </div>
    )

};

export default Admin;