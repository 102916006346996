export const country_enum = {
    "SPAIN": "1",
};

export const cautonoma_enum = {
    "ASTURIAS": "03",
};

export const provincia_enum = {
    "ASTURIAS": "33",
};

export const municipio_enum = {
    "ALLANDE": "33001",
};

export const roles = {
    ADMIN: "ROLE_ADMIN",
    USER: "ROLE_USER",
};