export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const VERIFICATION = 'VERIFICATION';
export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS';
export const VERIFICATION_FAILURE = 'VERIFICATION_FAILURE';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const ME_REQUEST = 'ME_REQUEST';
export const ME_REQUEST_SUCCESS = 'ME_REQUEST_SUCCESS';
export const ME_REQUEST_FAILURE = 'ME_REQUEST_FAILURE';

export const RESET_OPERATION_OK = 'RESET_OPERATION_OK';

export const RECOVER_PASSWORD_REQUEST = 'RECOVER_PASSWORD_REQUEST';
export const RECOVER_PASSWORD_REQUEST_SUCCESS = 'RECOVER_PASSWORD_REQUEST_SUCCESS';
export const RECOVER_PASSWORD_REQUEST_FAILURE = 'RECOVER_PASSWORD_REQUEST_FAILURE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';