import React from "react";
import cx from 'classnames';
import styles from './Login.module.scss'
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIntl } from "react-intl";
import { login } from "state/auth/actions";
import { useDispatch, useSelector } from '../../state';
import { Alert } from "reactstrap";
import ReactLoading from 'react-loading';

const Login = () => {

    const history = useHistory();
    const { formatMessage: f } = useIntl();
    const dispatch = useDispatch();
    const { loading, error } = useSelector('auth');
    
    const schema = yup.object().shape({
        email: yup.string().email(f({ id: "form.errors.email.validation" })).required(f({ id: "form.errors.email.required" })),
        password: yup.string(f({ id: "login.errors.password.validation" })).required(f({ id: "form.errors.password.required" })),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        defaultValues: { email: "", password: "" },
    });

    const onSubmit = (data) => {
        const post = async () => {
            await login(dispatch, data);
        }
        post();
    }

    return (
        loading ? <div className='loading-container'><ReactLoading type={'bars'} color={'#DC042B'} height={128} width={128} /></div> :
            <div className={cx(styles["main-container"])} >
                <div className={cx(styles["panel"])}>
                    <div className={cx(styles["panel-head"])}>
                        <div className={cx(styles["head-text"])}>{f({ id: "login.title" })}</div>
                    </div>
                    <div className={cx(styles["panel-body"])} >
                        <Alert color="danger">{f({ id: "form.alert" })}</Alert>
                        <div className="login-description">
                            {f({ id: "form.description" })}
                            <a className="links" onClick={() => { history.push('/ayuda'); history.go('/ayuda') }}> {f({ id: "form.description2" })}</a>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className={cx(styles["login-form"])}>
                            <div className={cx(styles["input-container"])}> 
                                <div className={cx(styles["input-title"])}>{f({ id: "form.email" })}</div>
                                <input name="email" ref={register} className="textfield" />
                                <div className={"error"}>{errors.email?.message}</div>

                            </div>
                            <div className={cx(styles["input-container"])}>
                                <div className={cx(styles["input-title"])}>{f({ id: "form.password" })}</div>
                                <input name="password" ref={register} type="password" className="textfield" />
                                <div className={"error"}>{errors.password?.message}</div>
                            </div>
                            {error && <Alert className="mt-1" color="danger">
                                {f({id: "401"})}
                            </Alert>}
                            <input type="submit" className='button' value={f({ id: "login.button" })} />
                        </form>
                        <div className={"links-container"}>
                            <a className="links" onClick={(e) => { history.push('/registro'); history.go('/registro') }}>{f({ id: "login.register.text" })}</a>
                            <br />
                            <a className="links" onClick={(e) => { history.push('/recuperar'); history.go('/recuperar') }}>{f({ id: "login.recovery.text" })}</a>
                        </div>
                    </div>
                </div>
            </div>
    );

}

export default Login;