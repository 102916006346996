import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import styles from './NewResource.module.scss';
import { useHistory } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from '../../state';
import { initializeNewResourceForm } from '../../state/resources/actions';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Ubication from '../../components/ubication/Ubication';

const FirstForm = (props) => {

    const { resource, categories, category, preview, photo, municipalities, types, locations } = useSelector('resources');

    const dispatch = useDispatch();
    let history = useHistory();

    const [photoName, setPhotoName] = useState("");
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const resourceFirstStepSchema = Yup.object({
        name: Yup.string().required("Por favor, debes especificar un nombre para el recurso."),
        summary: Yup.string().required("Por favor, debes añadir un resumen del recurso").max(300, "Máximo de 300 caracteres"),
    }).required();

    const { register, handleSubmit, errors, setValue, setError } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(resourceFirstStepSchema),
    });

    useEffect(() => {
        const initializeResource = async () => {
            await initializeNewResourceForm(dispatch);
        }
        onEditorStateChange(editorState);
        initializeResource();
    }, []);

    useEffect(() => {
        if (props.resource) {
            setValue("name", props.resource.name);
            if (props.resource.photo) {
                setPhotoName(props.resource.photo);
                setPreview(`${process.env.REACT_APP_IMG_URL}${props.resource.photo}`)
            }
            changeCategory(props.resource.category?.description);
            setValue("summary", props.resource.summary);
            if (props.resource.description) {
                const parsed = convertFromRaw(JSON.parse(props.resource.description));
                setEditorState((EditorState.createWithContent(parsed)));
            }
        }
    }, [props.resource]);

    const onEditorStateChange = editorState => {
        setEditorState(editorState);

        register('description');
        setValue('description', JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    }

    const changeCategory = (value) => {
        dispatch({ type: 'SET_CATEGORY', payload: value });
        setValue("category", value)
    }

    useEffect(() => {
        if (photo.length > 0) {
            setPreview(URL.createObjectURL(photo[0]));
            setPhotoName(photo[0].name);
        }
    }, [photo]);

    const setPhoto = (photo) => {
        dispatch({ type: 'SET_PHOTO', payload: photo });
        setPhotoName(photo[0].name);
    }

    const setPreview = (preview) => {
        dispatch({ type: 'SET_PREVIEW', payload: preview });
    }

    const deletePhoto = () => {
        dispatch({ type: 'SET_PHOTO', payload: [] });
        setPreview([]);
        setPhotoName("");
    }

    const onSubmitFirstStep = (data) => {
        if (locations.length > 0) {
            { props.onSubmitFirstStep(data) }
        } else {
            register('locations');
            setError('locations', { type: 'custom', message: 'Debe introducir una localización' });
        }
    }

    return (
        <div>


            <div className={cx(styles["header"], "subheader")}>
                <div className={cx(styles["header-text"], "h2-title")}>Formulario Recursos Saludables</div>
            </div>
            <div className={cx(styles["body-style"])}>
                Cumplimente el siguiente formulario para solicitar la adscripción de nueva actividad comunitaria a la Red de Actividades Comunitarias
                (RAC) del Observatorio de Salud en Asturias o bien para modificar una ya existente.
                Para más información <a className="links" onClick={(e) => { history.push('/ayuda'); history.go('/ayuda') }}>puedes acceder a información sobre qué es la RAC y qué tipo de actividades pueden incluirse.</a>
            </div>
            <div className={cx(styles["body-style"])}>
                <div className={cx(styles["icons-container"])}>
                    <div className={cx("col-xs-4")}>
                        <div className={cx(styles["icon-circle"]) + ' ' + cx(styles["circle-active"])}><img className={cx(styles["icon-active"])} src={require("../../images/information.svg").default} /></div>
                        <div className={cx(styles["circle-text"])}>Información del Recurso</div>
                    </div>
                    <div className={cx("col-xs-4")}>
                        <div className={cx(styles["icon-circle"])}><img className={cx(styles["icon-desactive"])} src={require("../../images/user.svg").default} /></div>
                        <div className={cx(styles["circle-text"])}>Datos de contacto</div>
                    </div>
                    <div className={cx("col-xs-4")}>
                        <div className={cx(styles["icon-circle"])}><img className={cx(styles["icon-desactive"])} src={require("../../images/check-relleno.svg").default} /></div>
                        <div className={cx(styles["circle-text"])}>Confimación</div>
                    </div>
                </div>
            </div>
            <form id="resource-form" onSubmit={handleSubmit(onSubmitFirstStep)} className={cx("form-container")}>
                <div className={cx(styles["requieredfield"], "h4-title")} >* Campo Obligatorio</div>
                <div className={cx(styles["first-container"])}>
                    <div>
                        <div className={cx(styles["control-label"], "h4-title")}>Nombre del recurso *</div>
                        {errors.name && (
                            <div className="text-danger">
                                {errors.name.message}
                            </div>
                        )}
                        <input type="text" className={cx(styles["form-control"], "select-control")} ref={register} name='name' id='name' />
                    </div>
                    <div>
                        <div className={cx(styles["control-label"], "h4-title")}>Imagen de la actividad</div>
                        <div className={cx(styles["input-file-container"])}>
                            <input type="text" className={cx(styles["form-control"], "select-control")} readOnly value={photoName} />
                            <div className={cx(styles["upload-button"]) + ' ' + "select-control" + ' ' + cx(styles["form-control"])}>
                                <img src={require("../../images/upload.svg").default} className={cx(styles["upload-icon"])} />
                                <input type="file" accept="image/*" className={cx(styles["file-input"])} onChange={(e) => setPhoto(e.target.files)} />
                            </div>
                        </div>
                        <img src={preview} alt={preview} className={cx(styles["first-preview"])} onClick={() => deletePhoto()} />
                    </div>
                    <div>
                        <div className={cx(styles["control-label"], "h4-title")}>Categoria *</div>
                        {errors.category && (
                            <div className="text-danger">
                                {errors.category.message}
                            </div>
                        )}
                        <div className={cx(styles["select-inputs"])}>
                            <select className={cx("select-control", styles["form-control"])} ref={register} name='category' id='category'
                                value={category} onChange={(e) => changeCategory(e.target.value)}>
                                {categories && categories.map((item, index) => {
                                    return (
                                        <option key={index} value={item.description}>{item.description}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div>
                        <div className={cx(styles["control-label"], "h4-title")}>Breve resumen del recurso *</div>
                        {errors.summary && (
                            <div className="text-danger">
                                {errors.summary.message}
                            </div>
                        )}
                        <textarea className={cx(styles["form-control"], "select-control")} ref={register} name='summary' id='summary'></textarea>
                    </div>
                </div>
                <div>
                    <div className={cx(styles["control-label"], "h4-title")}>Descripción ampliada del recurso</div>
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName={"editorClassName texteditor"}
                        onEditorStateChange={onEditorStateChange}
                    />
                </div>
            </form>

            {
                <Ubication locations={resource?.location} selector='resources' municipalities={municipalities} types={types} />
            }
            {errors.locations && (
                <div className="invalid-feedback-custom">
                    {errors.locations.message}
                </div>
            )
            }

            {props.infoContainer}
            <div className={cx(styles["footer-container"])}>
                <div className={cx(styles["buttons-container-index"])}>
                    <button className={cx(styles["button-restart"], "button")} type="reset">Comenzar de nuevo</button>
                    <button form="resource-form" variant="contained" className={cx(styles["button-index"], "button")} type="submit">Siguiente</button>
                </div>
            </div>

        </div>

    );
}

export default FirstForm;