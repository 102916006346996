import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import styles from './searcher.module.scss';
import plus from '../../images/plus.svg';
import minus from '../../images/minus.svg';
import SimpleMap from '../../components/map/Map.js';
import PaginationComponent from "components/pagination/PaginationComponent";
import { useDispatch, useSelector } from '../../state';
import { initializeSearcher, getTableElements, getActivitiesPdf, getAllLocations } from 'state/activities/actions';
import ReactLoading from 'react-loading';



const Searcher = () => {
    const dispatch = useDispatch();
    const [toggle, setToggle] = useState(true);
    const [pdfUrl, setPdfUrl] = useState('');

    
    const changeToggle = () => {
        setToggle((toggle) => !toggle);
    }

    const history = useHistory();

    const { municipality, selectedSituation, totalElements, municipalities, mapElements,
        topics, selectedTopics, initializing, pageIndex, totalPages, direction,
        field, loading, keyword, tableElementsType, tableKeyword, tableElements, demgr, demographicGroup, genders, gender } = useSelector('activities');

    const changeMunicipio = (municipio) => {
        dispatch({ type: 'SET_MUNICIPALITY', payload: municipio });
    }

    const changeDemographicGroup = (demographicGroup) => {
        dispatch({ type: 'SET_DEMOGRAPHIC_GROUP', payload: demographicGroup });
    }

    const changeGender = (gender) => {
        dispatch({ type: 'SET_GENDER', payload: gender })
    }

    const changeSituation = (situation) => {
        dispatch({ type: 'SET_SELECTED_SITUATION', payload: situation });
    }

    const changePageIndex = (pageIndex) => {
        dispatch({ type: 'SET_PAGE_INDEX', payload: pageIndex });
    }

    const changeTableElementsType = (type) => {
        dispatch({ type: 'SET_TABLE_ELEMENTS_TYPE', payload: type });
    }

    const changeKeyword = (word) => {
        dispatch({ type: 'SET_KEYWORD', payload: word });
    }

    const changeSort = (selectedField) => {

        dispatch({ type: 'SET_PAGE_INDEX', payload: 0 });

        if (selectedField !== field) {
            dispatch({ type: 'SET_FIELD', payload: selectedField });
            dispatch({ type: 'SET_DIRECTION', payload: "ASC" })
        } else if (direction === "ASC") {
            dispatch({ type: 'SET_DIRECTION', payload: "DESC" });
        } else {
            dispatch({ type: 'SET_DIRECTION', payload: "ASC" });
        }

    }

    const addRemoveSelectedTopic = (topic) => {
        dispatch({ type: 'ADD_REMOVE_SELECTED_TOPICS', payload: topic });
    }

    const changeTableKeyword = (word) => {
        dispatch({ type: 'SET_TABLE_KEYWORD', payload: word });
    }


    useEffect(() => {
        const updateTableActivities = async () => {
            await getTableElements(dispatch, {
                municipio: municipality, demographicGroup: demographicGroup, gender: gender, situation: selectedSituation, 
                topics: selectedTopics, tableKeyword: tableKeyword, keyword: keyword, pageIndex: 0, field: field, 
                direction: direction, type: tableElementsType, validated: 1
            });
        }
        updateTableActivities();
        getAllPerformanceLocations();
        setPdfUrl(getPdfUrl());
    }, [tableKeyword]);

    const sorting = (selectedField) => {
        return (field === selectedField ? direction === 'ASC' ? 'asc' : 'desc' : 'sorting')
    }

    useEffect(() => {
        const initializeAllActivities = async () => {
            await initializeSearcher(dispatch, {
                municipio: municipality, demographicGroup: demographicGroup, gender: gender, topics: selectedTopics,
                situation: selectedSituation, pageIndex: pageIndex, field: field, direction: direction, validated: 1
            });
        }
        initializeAllActivities();
        getAllPerformanceLocations();
        setPdfUrl(getPdfUrl());
    }, []);


    useEffect(() => {
        const updateTableActivities = async () => {
            await getTableElements(dispatch, {
                municipio: municipality, demographicGroup: demographicGroup, gender: gender, situation: selectedSituation, topics: selectedTopics,
                keyword: keyword, tableKeyword: tableKeyword, pageIndex: pageIndex, field: field, direction: direction, type: tableElementsType, validated: 1
            });
        }
        updateTableActivities();
        getAllPerformanceLocations();
        setPdfUrl(getPdfUrl());
    }, [field, direction, pageIndex]);

    const searchTableElements = () => {
        const getTableElementsAux = async () => {
            await getTableElements(dispatch, {
                municipio: municipality, demographicGroup: demographicGroup, gender: gender, situation: selectedSituation, 
                topics: selectedTopics, keyword: keyword, type: tableElementsType, validated: 1
            });
        }

        getTableElementsAux();
        getAllPerformanceLocations();
    };

    const getAllPerformanceLocations = () => {

        const getAllLocationsAux = async () => {
            await getAllLocations(dispatch, {
                municipio: municipality, demographicGroup: demographicGroup, gender: gender, situation: selectedSituation, topics: selectedTopics,
                keyword: keyword, tableKeyword: tableKeyword, type: tableElementsType, validated: 1
            })
        }

        getAllLocationsAux();
    }

    const getPdfUrl = () => {

        const data = {
            type: tableElementsType,
            topics: selectedTopics,
            municipio: municipality,
            demographicGroup: demographicGroup,
            gender: gender,
            keyword: keyword,
            url: `/generate-pdf/`,
            tableKeyword: tableKeyword,
            validated: 1,
            situation: selectedSituation
        }

        let prevUlr = getActivitiesPdf(data);
        let hostAdd = window.location.host;
        prevUlr = prevUlr.includes("?") ? prevUlr+`&hostAdd=${hostAdd}` : prevUlr+`?hostAdd=${hostAdd}`

        return prevUlr;
        
    }

    const goToDetail = (id, type) => {
        if (type === "A") {
            history.push(`/actividad/detalle/${id}`);
            //history.go(`/actividad/detalle/${id}`);
        } else {
            history.push(`/recurso/detalle/${id}`);
            //history.go(`/recurso/detalle/${id}`);
        }
    };

    return (
        initializing ? <div className='loading-container'><ReactLoading type={'bars'} color={'#DC042B'} height={128} width={128} /></div> :
            <div className={cx(styles["main-container"])}>
                <div className="subheader">
                    <div className='h2-title'>Buscador de Actuaciones</div>
                    <div className={cx(styles["header-right-items"])}>
                        <div className="label">{totalElements} Actuaciones</div>
                        <div className={cx(styles["filter-result"])} onClick={changeToggle}><img src={toggle === true ? minus : plus}
                            className={cx(styles["plus-icon"])} alt="icono más"/>Filtrar Resultados
                        </div>
                    </div>
                </div>
                {toggle === true ?
                    <div className={cx(styles["types-filter"])}>
                        <div className={'row'}>
                            <label className='h4-title'>Temas</label>

                            <div className={cx(styles["topics-container"], "row")}>
                                {topics && topics.map((item, index) => {
                                    return (
                                        <div key={index} className={cx(styles["check-topics"]) + " " + "custom-check col-xs-12 col-sm-6 col-md-3 col-lg-2"}>
                                            <img src={require(`../../images/${item.imgName}`)} alt={item.description} className={cx(styles["icons"])} />
                                            <input className={cx(styles["topics-input"])} type="checkbox" id={item.id} 
                                            defaultChecked={selectedTopics.filter(t => t === item.id).length > 0}
                                            onClick={() => addRemoveSelectedTopic(item.id)} />
                                            <label htmlFor={item.id} className={cx(styles["text-topics"])}>{item.description}</label>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                        <div className={'row'}>
                                <div className='col-xs-12 col-sm-6 col-md-4'>
                                    <label className="h4-title">Municipio</label>
                                    <select id='municipality' className="select-control" value={municipality} onChange={(event) => changeMunicipio(event.target.value)} >
                                        <option value="0">Cualquiera</option>
                                        {
                                            municipalities && municipalities.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className='col-xs-12 col-sm-6 col-md-4'>
                                    <label className="h4-title">Población</label>
                                    <select id="demographicGroup" className="select-control" value={demographicGroup} onChange={(event) => changeDemographicGroup(event.target.value)}>
                                        <option value="" defaultValue={"selected"}>Cualquiera</option>

                                        {demgr && demgr.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id}>{item.description}</option>
                                            )
                                        })}

                                    </select>
                                </div>

                                <div className='col-xs-12 col-sm-6 col-md-4'>
                                    <label className="h4-title">Género</label>
                                    <select id="gender" className="select-control" value={gender} onChange={(event) => changeGender(event.target.value)}>
                                        <option value="" defaultValue={"selected"}>Cualquiera</option>

                                        {genders && genders.map((item, index) => {
                                            return (
                                                <option key={index} value={item?.id}>{item.description}</option>
                                            )
                                        })}

                                    </select>
                                </div>


                                <div className='col-xs-12 col-sm-6 col-md-4'>
                                    <label className="h4-title">Tipo</label>
                                    <select className="select-control" value={tableElementsType} onChange={(event) => changeTableElementsType(event.target.value)}>
                                        <option value="" defaultValue="selected">Cualquiera</option>
                                        <option value="A">Actividad</option>
                                        <option value="R">Recurso/Activo</option>
                                    </select>
                                </div>

                                <div className='col-xs-12 col-sm-6 col-md-4'>
                                    <label className="h4-title">Situación actual</label>
                                    <select className="select-control" value={selectedSituation} onChange={(event) => changeSituation(event.target.value)}>
                                        <option value="">Cualquiera</option>
                                        <option value="1">Activa</option>
                                        <option value="0">Histórica</option>
                                    </select>
                                </div>

                                <div className='col-xs-12 col-sm-6 col-md-4'>
                                    <label className="h4-title">Palabra Clave</label>
                                    <input className={cx(styles["keyword-textfield"])} value={keyword} onChange={(e) => changeKeyword(e.target.value)} />
                                </div>


                            <div className={cx(styles["search-button-container"])}>
                                <div className={cx(styles["search-button"], "button")} onClick={() => { searchTableElements(); setPdfUrl(getPdfUrl())}}>
                                    <img src={require("../../images/search.svg").default} alt="buscar" className={cx(styles["btn-icon"])} />
                                    <div className={cx(styles["btn-text"])} >Buscar</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""
                }

                <div className={cx(styles['buscar'])}>
                    <div className={cx(styles['buscar-content'])}>
                        <label className={cx(styles["table-searcher-label"])}>
                            Buscar:
                            <input className={'select-control' + ' ' + cx(styles["table-searcher-input"])} onChange={(e) => { changeTableKeyword(e.target.value) }} />
                        </label>
                    </div>
                    <div className={cx(styles['buscar-content'])}>
                        <div className={cx(styles['text-results']) + ' ' + "h4-title"}> Se han encontrado {totalElements} Actuaciones</div>
                    </div>
                    <div className={cx(styles['buscar-content'])}>
                        <div className={"button" + ' ' + cx(styles["button-download"])}>
                            <img src={require("../../images/pdf.svg").default} alt="descargar" className={cx(styles["btn-icon"])} />
                            <div className={cx(styles["btn-text"])}>
                                <a style={{color: "white"}}href={pdfUrl} >Descargar PDF</a>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className={cx(styles["map-container"])} name="map-container">
                    <SimpleMap detailView={true} style={{ height: 500 }} tableElements={tableElements} mapElements={mapElements}/>
                </div>
    
                {loading ? <div className='loading-container'><ReactLoading type={'bars'} color={'#DC042B'} height={64} width={64} /></div> :
                    <div className={cx(styles["results"])}>
                        <table>
                            <thead>
                                <tr>
                                    <th className={`h4-title col-sm-1  ${sorting("id")}`} onClick={() => { changeSort("id") }}>Ref.</th>
                                    <th className={`h4-title col-sm-2  ${sorting("name")}`} onClick={() => { changeSort("name") }}>Nombre de la actividad</th>
                                    <th className={`h4-title col-sm-4  ${sorting("summary")}`} onClick={() => { changeSort("summary") }}>Breve resumen</th>
                                    <th className={`h4-title col-sm-3  ${sorting("street")}`} onClick={() => { changeSort("street") }} >Localización</th>
                                    <th className='h4-title col-sm-1'></th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    tableElements.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.id}</td>
                                                <td className='name-td'>{item.name}</td>
                                                <td>{item.summary}</td>
                                                <td>
                                                    {item.location[0]?.roadType?.description !== undefined ? item.location[0]?.roadType?.description + " " : ""}
                                                    {item.location[0]?.street !== undefined ? item.location[0]?.street + " " : ""}
                                                    {item.location[0]?.postalCode !== undefined ? item.location[0]?.postalCode + " " : ""}
                                                    {item.location[0]?.locality !== undefined ? item.location[0]?.locality + " " : ""}
                                                    {item.location[0]?.ubication !== undefined ? item.location[0]?.ubication + " " : ""}
                                                    {item.location[0]?.municipality?.name !== undefined ? item.location[0]?.municipality?.name : ""}
                                                </td>
                                                <td>
                                                    <div className='button table-button' onClick={() => goToDetail(item.id, item.type)}>
                                                        <img src={require('../../images/eye-solid.svg').default} alt="ver actuación" className={cx(styles["actions-img"])}></img>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>}
                    <PaginationComponent pageIndex={pageIndex} onPageIndexChange={(page) => changePageIndex(page)} items={tableElements} totalPages={totalPages} />

            </div>
    );
}

export default Searcher;