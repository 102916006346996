/*import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'typeface-roboto';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StoreProvider } from './state';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StoreProvider>
      <App />
    </StoreProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();*/

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './index.css';
import 'typeface-roboto';
import { locales } from "./i18n";
import { IntlProvider } from "react-intl";
import "bootstrap/dist/css/bootstrap.min.css";

import reportWebVitals from "./reportWebVitals";
import { StoreProvider } from "./state";

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider>
    <IntlProvider locale={"es"} messages={locales["es"]} defaultLocale="es">
        <App />
    </IntlProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

