/**
* Auth User Reducers
*/
import * as types from './types';
//import { NotificationManager } from 'react-notifications';
/**
 * initial auth user
 */
export const initialAuthState = {
  token: (sessionStorage.getItem('token') && sessionStorage.getItem('token') !== "undefined" ? sessionStorage.getItem('token') : null),
  //borrar con cookies
  refreshToken: (sessionStorage.getItem('refreshToken') && sessionStorage.getItem('refreshToken') !== "undefined" ? sessionStorage.getItem('refreshToken') : null),
  email: (sessionStorage.getItem('email') && sessionStorage.getItem('email') !== "undefined" ? sessionStorage.getItem('email') : null),
  role: (sessionStorage.getItem('role') && sessionStorage.getItem('role') !== "undefined" ? sessionStorage.getItem('role') : null),
  userId: (sessionStorage.getItem('userId') && sessionStorage.getItem('userId') !== "undefined" ? sessionStorage.getItem('userId') : null),
  initializing: true,
  loading: false,
  registerUser: null,
  loginError: false,
  logged: false,
  error: null,
  me: null,
  admin: false,
  initials: null,
  username: null,
  tokenExpired: false,
  emailDoesNotExist: false,
  sentMail: false,
  company: null,
  roles: [],
  name: '',
  surname: '',
  user900: false,
  type: '',
  icass: false,
  residence: false,
  doctor: false,
  ok: false
};

export const authReducer = (initialState = initialAuthState, action) => {
  switch (action.type) {

    case types.LOGIN:
      return { ...initialState, loading: true };

    case types.LOGIN_SUCCESS:
      return { ...initialState, loading: false, token: action.payload, email: action.email, error: null, logged: true, role: action.role, userId: action.userId };

    case types.LOGIN_FAILURE:
      return { ...initialState, loading: false, error: action.error };

    case types.REGISTER:
      return { ...initialState, loading: true, registerUser: null };

    case types.REGISTER_SUCCESS:
      return { ...initialState, loading: false, error: null, registerUser: "Email enviado" };

    case types.REGISTER_FAILURE:
      return { ...initialState, loading: false, error: action.error, registerUser: null  };

    case types.VERIFICATION:
      return { ...initialState, loading: true };

    case types.VERIFICATION_SUCCESS:
      return { ...initialState, loading: false, error: null };

    case types.VERIFICATION_FAILURE:
      return { ...initialState, loading: false, error: action.error };

    case types.LOGOUT:
      return { ...initialState };

    case types.LOGOUT_SUCCESS:
      return { ...initialState, me: null, token: null, logged: false, email: null, role: null, userId: null };

    case types.LOGOUT_FAILURE:
      return { ...initialState };

    case types.ME_REQUEST:
      return { ...initialState, loading: true, logged: false, me: null, initializing: true };

    case types.ME_REQUEST_SUCCESS:
      // TODO
      const me = action.payload;

      return { ...initialState };

    case types.ME_REQUEST_FAILURE:
      return { ...initialState, loading: false, residence: false, icass: false, type: '', user900: false, admin: false, logged: false, me: null, initializing: false };

    case types.RESET_OPERATION_OK:
      return { ...initialState, loading: false, initializing: false, error: null, ok: false };

    case types.RECOVER_PASSWORD_REQUEST:
      return { ...initialState, loading: true, ok: false, error: undefined };

    case types.RECOVER_PASSWORD_REQUEST_SUCCESS:
      return { ...initialState, loading: false, ok: true };

    case types.RECOVER_PASSWORD_REQUEST_FAILURE:
      return { ...initialState, loading: false, error: action.error };

    case types.RESET_PASSWORD:
      return { ...initialState, loading: true, ok: false, error: undefined };

    case types.RESET_PASSWORD_SUCCESS:
      return { ...initialState, loading: false, ok: true };

    case types.RESET_PASSWORD_FAILURE:
      return { ...initialState, loading: false, error: action.error };

    default:
      return { ...initialState };
  }
}



