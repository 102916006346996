import React, { useState, useEffect } from "react";
import cx from 'classnames';
import styles from './NewResource.module.scss';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useSelector, useDispatch } from '../../state';
import { getResourceById } from "../../state/resources/actions";


const InfoContainer = () => {
    return (
        <div className={cx(styles["info-container"])}>
            <div className={cx(styles['info'])}>
                <div>
                    Los datos que se faciliten serán utilizados solamente en relación a la Red de Actividades Comunitarias
                    (RAC) del Observatorio de Salud en Asturias y el proyecto de red nacional de actividades comunitarias que se está desarrollando con el PACAP. El Observatorio de Salud en Asturias garantiza, en los términos establecidos por la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (LOPD), que sus datos serán tratados de modo confidencial. En todo caso, y al amparo de lo dispuesto en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (LOPD), el interesado puede ejercer los derechos de acceso, rectificación, cancelación y oposición de sus datos de carácter personal en los términos establecidos en la LOPD.
                    La entidad responsable del fichero es el Observatorio de Salud en Asturias,
                    y se puede contactar a través de su secretaría: Observatorio de Salud de Asturias

                    <br />
                    <br />

                    Dirección General de Salud Pública y Participación
                    <br />
                    Consejería de Salud y Servicios Sanitarios
                    <br />
                    C/ Ciriaco Miguel Vigil – nº 9
                    <br />
                    33006 Oviedo
                    <br />
                    <br />
                    Tfno: 985106385 / 985106338
                    <br />
                    Correo electrónico: obsalud@asturias.org
                </div>
            </div>
        </div>)
}


const ResourcesView = () => {

    let history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [preview, setPreview] = useState();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const { resource } = useSelector('resources');


    useEffect(() => {
        const getResource= async () => {
            await getResourceById(dispatch, { id });
        }
        if (id) {
            getResource();
        }
    }, []);

    useEffect(() => {

        resource.photo ? setPreview(`${process.env.REACT_APP_IMG_URL}${resource.photo}`) : setPreview("");

        if (resource.description && resource.description !== "") {
            const parsed = convertFromRaw(JSON.parse(resource.description));
            setEditorState((EditorState.createWithContent(parsed)));
        }
    }, [resource]);

    return (
        <div>
            <div className={cx(styles["header"], "subheader")}>
                <div className={cx(styles["header-text"], "h2-title")}>Detalles del Recurso Ref:{resource.id}</div>
            </div>
            
            <div className={cx(styles["body-style"])}>
                
                <div>
                    <div className={cx(styles["confirmation-element"])}>Nombre: {resource.name}</div>
                    <div className={cx(styles["confirmation-element"])}>Breve resumen: {resource.summary}</div>
                    {resource.description && resource.description !== "" ?
                        <div>
                            <div className={cx(styles["confirmation-element"])}>Descripción completa:</div>
                            <div className={cx(styles["confirmation-element"])}>
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="ACToolbar"
                                    wrapperClassName="wrapperClassName"
                                    readOnly
                                />
                            </div>
                        </div>
                        :
                        ""
                    }
                    <div className={cx(styles["confirmation-element"])}>Categoría: {resource.category ? resource.category.description : ""}</div>
                    <div className={cx(styles["confirmation-element"])}>Lugares donde se desarrolla:</div>
                    {resource.location ?
                    resource.location.map((item, index) => {
                            return (
                                <div key={index} className={cx(styles["confirmation-element"])}>
                                    {item.roadType.description !== undefined ? item.roadType.description + " " : ""}
                                    {item.street !== undefined ? item.street + " " : ""}
                                    {item.number !== undefined ? item.number + " " : ""}
                                    {item.postalCode !== undefined ? item.postalCode + " " : ""}
                                    {item.locality !== undefined ? item.locality + " " : ""}
                                    {item.ubication !== undefined ? item.ubication + " " : ""}
                                    {item.municipality.name !== undefined ? item.municipality.name : ""}
                                </div>
                            )
                        }) : ""}
                    <div className={cx(styles["confirmation-element"])}>Persona de contacto: {resource.contact}</div>
                    <div className={cx(styles["confirmation-element"])}>Mail de contacto: {resource.contactMail}</div>
                    <div className={cx(styles["confirmation-element"])}>Teléfono de contacto: {resource.phone}</div>
                    {resource.webPage && resource.webPage !== "" ?
                        <div className={cx(styles["confirmation-element"])}>Página web: {resource.webPage}</div>
                        :
                        ""
                    }
                    {resource.facebookPage && resource.facebookPage !== "" ?
                    <div className={cx(styles["confirmation-element"])}>Página de Facebook: {resource.facebookPage}</div>
                        :
                        ""
                    }
                    {resource.twitterPage && resource.twitterPage !== "" ?
                    <div className={cx(styles["confirmation-element"])}>Página de Twitter: {resource.twitterPage}</div>
                        :
                        ""
                    }
                    {resource.photo ?
                        <div className={cx(styles["confirmation-element"])}>Imagen: <img src={preview} alt={resource.photo} className="img-preview" /></div>
                        : ""
                    }
                    <div className={cx(styles["confirmation-element"])}>Has aceptado la política de confidencialidad</div>
                    {resource.permiso ?
                    <div className={cx(styles["confirmation-element"])}>La persona de contacto de la actividad/recurso ha dado su conformidad para recibir mensajes de la web</div>                    
                    : ""}
                </div>
            </div>

            {InfoContainer}
            <div className={cx(styles["footer-container"])}>
                <div className={cx(styles["buttons-container-index"])}>
                    {/*
                    <div onClick={() => { returnHome() }} className={cx(styles["button-return"], "button")}>Comenzar de nuevo</div>
                    <div onClick={() => { backpage() }} className={cx(styles["button-index"], "button")}>Anterior</div>
                    <div onClick={() => { post() }} className={cx(styles["button-index"], "button")}>Enviar</div>*/}
                </div>
            </div>
        </div>
    );
}

export default ResourcesView;