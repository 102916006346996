import React from "react";
import obsa from "../../images/obsa-gris.png";
import consejeria from "../../images/consejeria-gris.png";
import facebook from "../../images/facebook.png";
import twitter from "../../images/twitter.png";
import rss from "../../images/rss.png";
import styles from "./Footer.module.scss";
import cx from "classnames";

const Footer = (props) => {
  return (
    <div className={cx(styles["footer"])}>
      <div className={cx(styles["logos-container"])}>
        <img
          className={cx(styles["footer-img"])}
          src={obsa}
          alt="logo_obsa"
        />
        <img
          className={cx(styles["footer-img"])}
          src={consejeria}
          alt="logo_consejeria"
        />
      </div>
      <p>
        Activos de Salud es un proyecto iniciado desde el Programa de
        Actividades Comunitarias en Atención Primaria de la semFYC, el
        Observatorio de Salud en Asturias.
      </p>
      <div className={cx(styles["legal-warning-container"])}>
        <a href='https://www.obsaludasturias.com/obsa/aviso-legal/' target="_blank">Aviso Legal</a>
        <span> | </span>
        <a href='https://www.obsaludasturias.com/obsa/creditos/' target="_blank">Créditos</a>
        <span> | </span>
        <span>v. {process.env.REACT_APP_VERSION} </span>
      </div>
      <div className={cx(styles["footer-icon-container"])}>
      <a href='https://www.facebook.com/ObservatorioSalud.Asturias' target="_blank">
        <img src={facebook} alt="Facebook" className={cx(styles['footer-icon'])}/>
      </a>
      <a href='https://twitter.com/#!/obsaludasturias' target="_blank">
        <img src={twitter} alt="Twitter" className={cx(styles['footer-icon'])}/>
      </a>
      <a href='https://www.obsaludasturias.com/obsa/feed/' target="_blank">
        <img src={rss} alt="RSS" className={cx(styles['footer-icon'])}/>
      </a> 
      </div>
    </div>
  );
};
export default Footer;
