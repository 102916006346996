import React from "react";
import cx from 'classnames';
import styles from './Recovery.module.scss'
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from '../../state';
import ReactLoading from 'react-loading';
import { recoverPasswordRequest } from "state/auth/actions";
import { Alert } from "reactstrap";
import { useIntl } from "react-intl";

const Recovery = () => {

    let history = useHistory();
    const dispatch = useDispatch();
    const { loading, error, ok } = useSelector('auth');
    const { formatMessage: f } = useIntl();

    const schema = yup.object().shape({
        email: yup.string().email().required(),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        defaultValues: { email: "" },
    });

    const onSubmit = (data) => {
        const post = async () => {
            await recoverPasswordRequest(dispatch, data);
        }
        post();
    }

    return (
        loading ? <div className='loading-container'><ReactLoading type={'bars'} color={'#DC042B'} height={128} width={128} /></div> :
            <div className={cx(styles["main-container"])} >
                <div className={cx(styles["panel"])}>
                    <div className={cx(styles["panel-head"])}>
                        <div className={cx(styles["head-text"])}>Recuperación</div>
                    </div>
                    <div className={cx(styles["panel-body"])} >
                        <div className="login-description">
                            Es necesario que se identifique para solicitar la adscripción
                            de nueva actividad comunitaria a la Red de Actividades Comunitarias (RAC) del Observatorio de Salud en Asturias
                            o bien para modificar una ya existente. Para más información
                            <a className="links" onClick={() => { history.push('/ayuda'); history.go('/ayuda') }}> puedes acceder a información sobre qué es la RAC y qué tipo de actividades pueden incluirse.</a>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className={cx(styles["recovery-form"])}>
                            <div className={cx(styles["input-container"])}>
                                <div>Email:</div>
                                <input name="email" ref={register({ required: true })} className="textfield" />
                                <div className={"error"}>{errors.email?.message}</div>
                            </div>
                            {error && <Alert className="mt-1" color="primary">
                                {error.description}
                            </Alert>}
                            {ok && <Alert className="mt-1" color="primary">
                                {f({ id: "form.email.enviado" })}
                            </Alert>}
                            <input type="submit" className='button' value="Enviar" />
                        </form>
                        <div className={"links-container"}>
                            <a className="links" onClick={(e) => { history.push('/login'); history.go('/login') }}>Volver al formulario de acceso</a>
                        </div>
                    </div>
                </div>
            </div>
    );

}

export default Recovery;