import React from 'react';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';

const PublicLayout = (props) => {
  const Component = props.component;
  const route = props.route;

  return (
    <div>
      <Header />
      <Component route={route} />
      <Footer />
    </div>
  );
};

export default PublicLayout;