import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import styles from './NewActivity.module.scss';
import { useHistory } from "react-router-dom";
import { convertToRaw, EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useDispatch, useSelector } from '../../state';
import { initializeNewActivityForm, } from '../../state/activities/actions';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Ubication from '../../components/ubication/Ubication';
import Temporalities from 'components/temporalities/Temporalities';


const FirstForm = (props) => {

    const dispatch = useDispatch();
    let history = useHistory();

    const [photoName, setPhotoName] = useState("");
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [demographicGroup, setDemographicGroup] = useState();
    const [gender, setGender] = useState();
    const [free, setFree] = useState();
    const [temporality, setTemporality] = useState();

    const { activity, demgr, genders, freeList, temporalitiesList, photo, preview, 
        temporalities, municipalities, types, locations, yAxis, xAxis } = useSelector('activities');

    const onEditorStateChange = editorState => {
        setEditorState(editorState);

        register('description');
        setValue('description', JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    }

    useEffect(() => {
        if (props.activity) {
            setValue("name", props.activity.name);
            if (props.activity.photo) {
                setPhotoName(props.activity.photo);
                setPreview(`${process.env.REACT_APP_IMG_URL}${props.activity.photo}`)
            }
            setValue("summary", props.activity.summary);
            if (props.activity.description) {
                const parsed = convertFromRaw(JSON.parse(props.activity.description));
                setEditorState((EditorState.createWithContent(parsed)));
            }
            if (props.activity.demographicGroup?.description) {
                changeDemographicGroup(props.activity.demographicGroup.description)
            }
            if (props.activity.gender?.description) {
                changeGender(props.activity.gender.description)
            }
            changeTemporality(props.activity?.temporality);
            setValue("otherDetails", props.activity.otherDetails);
            changeFree(props.activity.free);
        } else {
            setValue("demographicGroup.description", "Población General");
            setValue("gender.description", "Indiferente");
            setValue("free", "Sí");
            //setValue("temporality", "Continuada");

        }
    }, [props.activity]);


    useEffect(() => {
        const initializeActivities = async () => {
            await initializeNewActivityForm(dispatch);
        }
        onEditorStateChange(editorState);
        
        initializeActivities();
    }, []);


    const activityFirstStepSchema = Yup.object({
        name: Yup.string().required("Por favor, debes especificar un nombre para la actividad."),
        photo: Yup.string(),
        summary: Yup.string().required("Por favor, debes añadir un resumen a la actividad").max(300, "Máximo 300 caracteres"),
        description: Yup.string(),
        otherDetails: Yup.string(),
        temporality: Yup.string().required("Debes de seleccionar la temporalidad de la actividad"),
    }).required();

    const { register, handleSubmit, errors, watch, setValue, setError } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(activityFirstStepSchema),
    });

    const setPhoto = (photo) => {
        dispatch({ type: 'SET_PHOTO', payload: photo });
        setPhotoName(photo[0].name);
    }
    const setPreview = (preview) => {
        dispatch({ type: 'SET_PREVIEW', payload: preview });
    }

    const deletePhoto = () => {
        dispatch({ type: 'SET_PHOTO', payload: [] });
        setPreview([]);
        setPhotoName("");
    }

    useEffect(() => {
        if (photo.length > 0) {
            setPreview(URL.createObjectURL(photo[0]));
            setPhotoName(photo[0].name);
        }
    }, [photo]);

    const changeDemographicGroup = (value) => {
        setDemographicGroup(value);
        setValue("demographicGroup.description", value);
    }

    const changeGender = (value) => {
        setGender(value);
        setValue("gender.description", value);
    }

    const changeFree = (value) => {
        setFree(value);
        setValue("free", value);
    }

    const changeTemporality = (value) => {
        setTemporality(value);
        setValue("temporality", value);
    }

    const onSubmitFirstStep = (data) => {
        if (locations.length > 0) {
            if (data.temporality === "Temporal") {
                if (temporalities.length > 0) {
                    { props.onSubmitFirstStep(data) }
                } else {
                    register('temporalities');
                    setError('temporalities', { type: 'custom', message: 'No puede esta vacío' });
                }
            } else {
                { props.onSubmitFirstStep(data) }
            }
        } else {
            register('locations');
            setError('locations', { type: 'custom', message: 'Debe introducir una localización' });
        }
    }

    return (
        <div>

            <div className={cx(styles["header"], "subheader")}>
                <div className={cx(styles["header-text"], "h2-title")}>Formulario de Actividades Comunitarias</div>
            </div>
            <div className={cx(styles["body-style"])}>
                Cumplimente el siguiente formulario para solicitar la adscripción de nueva actividad comunitaria a la Red de Actividades Comunitarias
                (RAC) del Observatorio de Salud en Asturias o bien para modificar una ya existente.
                Para más información <a className="links" onClick={() => { history.push('/ayuda'); history.go('/ayuda') }}>puedes acceder a información sobre qué es la RAC y qué tipo de actividades pueden incluirse.</a>
            </div>
            <div className={cx(styles["body-style"])}>
                <div className={cx(styles["icons-container"])}>
                    <div className={cx("col-xs-4")}>
                        <div className={cx(styles["icon-circle"]) + ' ' + cx(styles["circle-active"])}><img className={cx(styles["icon-active"])} src={require("../../images/information.svg").default} /></div>
                        <div className={cx(styles["circle-text"])}>Información de la Actividad</div>
                    </div>
                    <div className={cx("col-xs-4")}>
                        <div className={cx(styles["icon-circle"])}><img className={cx(styles["icon-desactive"])} src={require("../../images/user.svg").default} /></div>
                        <div className={cx(styles["circle-text"])}>Datos de contacto</div>
                    </div>
                    <div className={cx("col-xs-4")}>
                        <div className={cx(styles["icon-circle"])}><img className={cx(styles["icon-desactive"])} src={require("../../images/check-relleno.svg").default} /></div>
                        <div className={cx(styles["circle-text"])}>Confimación</div>
                    </div>
                </div>
            </div>
            <form id="activity-form" className="form-container" onSubmit={handleSubmit(onSubmitFirstStep)}>
                <div className={cx(styles["requieredfield"], "h4-title")} >* Campo Obligatorio</div>
                <div className={cx(styles["first-container"])}>
                    <div>
                        <div className={cx(styles["control-label"], "h4-title")}>Nombre de la actividad comunitaria *</div>
                        {errors.name && (
                            <div className="text-danger">
                                {errors.name.message}
                            </div>
                        )
                        }
                        <input type="text" className={cx(styles["form-control"], "select-control")} ref={register} name='name' id='name' />
                        
                    </div>
                    <div>
                        
                        <div className={cx(styles["control-label"], "h4-title")}>Imagen de la actividad</div>
                        <div className={cx(styles["input-file-container"])}>
                            <input type="text" className={cx(styles["form-control"], "select-control")} readOnly value={photoName} />
                            <div className={cx(styles["upload-button"]) + ' ' + "select-control" + ' ' + cx(styles["form-control"])}>
                                <img src={require("../../images/upload.svg").default} className={cx(styles["upload-icon"])} />
                                <input type="file" accept="image/*" className={cx(styles["file-input"])} onChange={(e) => setPhoto(e.target.files)} />
                            </div>
                        </div>
                        <img src={preview} alt={preview} className={cx(styles["first-preview"])} onClick={() => deletePhoto()} />
                        
                    </div>
                    <div>
                        <div className={cx(styles["control-label"], "h4-title")}>Breve resumen de la actividad realizada *</div>
                        {errors.summary && (
                            <div className='text-danger'>
                                {errors.summary.message}
                            </div>
                        )}
                        <textarea className={cx(styles["form-control"], "select-control")} ref={register} name='summary' id='summary'></textarea>
                        
                    </div>
                </div>
                <div>
                    <div className={cx(styles["control-label"], "h4-title")}>Descripción completa de la actividad</div>
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName={"editorClassName texteditor"}
                        onEditorStateChange={onEditorStateChange}
                    />
                </div>
                <div className={cx(styles["first-container"])}>
                    <div>
                        <div className={cx(styles["control-label"], "h4-title")}>Edad a la que va dirigida la actividad *</div>
                        <div className={cx(styles["select-inputs"])}>
                            <select className={cx("select-control")} value={demographicGroup} onChange={(e) => changeDemographicGroup(e.target.value)}
                                ref={register} name='demographicGroup.description' id='demographicGroup.description'>
                                {demgr && demgr.map((item, index) => {
                                    return (
                                        <option key={index} value={item.description}>{item.description}</option>
                                    )
                                })}
                            </select>
                        </div>
                        {errors.demographicGroup && (
                            <div className="invalid-feedback">
                                {errors.demographicGroup.message}
                            </div>
                        )
                        }
                    </div>
                    <div>
                        <div className={cx(styles["control-label"], "h4-title")}>Sexo al que va dirigida la actividad *</div>
                        <div className={cx(styles["select-inputs"])}>
                            <select className={cx("select-control")} value={gender} onChange={(e) => changeGender(e.target.value)}
                                ref={register} name='gender.description' id='gender'>
                                {genders && genders.map((item, index) => {
                                    return (
                                        <option key={index} value={item.description}>{item.description}</option>
                                    )
                                })}
                            </select>
                        </div>
                        {errors.gender && (
                            <div className="invalid-feedback">
                                {errors.gender.message}
                            </div>
                        )
                        }
                    </div>
                </div>
                <div className={cx(styles["first-container"])}>
                    <div>
                        <div className={cx(styles["control-label"], "h4-title")}>Otros detalles sobre la población a la que va dirigida la actividad</div>
                        <input type="text" className={cx(styles["form-control"], "select-control")} ref={register} name='otherDetails' id='otherDetails' />
                    </div>
                    <div>
                        <div className={cx(styles["control-label"], "h4-title")}>Gratuita *</div>
                        <div className={cx(styles["select-inputs"])}>
                            <select className={cx("select-control")} value={free} onChange={(e) => changeFree(e.target.value)}
                                ref={register} name='free' id='free'>
                                {freeList && freeList.map((item, index) => {
                                    return (
                                        <option key={index} value={item.description}>{item.description}</option>
                                    )
                                })}
                            </select>
                        </div>
                        {errors.free && (
                            <div className="invalid-feedback">
                                {errors.free.message}
                            </div>
                        )
                        }
                    </div>
                </div>
                <div>
                    <div className={cx(styles["control-label"], "h4-title")}>Temporalidad *</div>
                    <div className={cx(styles["select-inputs"])}>
                        <select className={cx("select-control")} value={temporality} onChange={(e) => changeTemporality(e.target.value)}
                            ref={register} name='temporality' id='temporality'>
                            {temporalitiesList && temporalitiesList.map((item, index) => {
                                return (
                                    <option key={index} value={item.description}>{item.description}</option>
                                )
                            })}
                        </select>
                    </div>
                    {errors.temporality && (
                        <div className="invalid-feedback">
                            {errors.temporality.message}
                        </div>
                    )
                    }
                </div>

            </form>

            {temporality === "Temporal" ?
                <Temporalities temporalitiesList={activity?.temporalities} />
                : ""
            }
            {errors.temporalities && (
                <div className="invalid-feedback-custom">
                    {errors.temporalities.message}
                </div>
            )
            }

            {
                <Ubication locations={activity?.location} selector='activities' municipalities={municipalities} types={types} />
            }
            {errors.locations && (
                <div className="invalid-feedback-custom">
                    {errors.locations.message}
                </div>
            )
            }

            {props.infoContainer}
            <div className={cx(styles["footer-container"])}>
                <div className={cx(styles["buttons-container-index"])}>
                    <button onClick={() => { props.returnHome() }} className={cx(styles["button-restart"], "button")} type="reset">Comenzar de nuevo</button>
                    <button form="activity-form" className={cx(styles["button-index"], "button")} type="submit">Siguiente</button>
                </div>
            </div>
        </div>

    );
}

export default FirstForm;
