import React, { useState, useEffect } from "react";
import cx from 'classnames';
import styles from './NewActivity.module.scss';
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useSelector } from '../../state';

const Confirmation = (props) => {

    let history = useHistory();
    const { formatMessage: f } = useIntl();

    const { handleSubmit } = useForm({
        mode: "onSubmit",
        defaultValues: {
            ...props.activity
        },
    });

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const { preview } = useSelector('activities');

    useEffect(() => {
        if (props.activity.description && props.activity.description !== "") {
            const parsed = convertFromRaw(JSON.parse(props.activity.description));
            setEditorState((EditorState.createWithContent(parsed)));
        }
        props.activity.permiso ? props.activity.permiso = 1 : props.activity.permiso = 0;
    }, []);


    return (
        <form onSubmit={handleSubmit(props.post)}>
            <div className={"subheader" + ' ' + cx(styles["header"])}>
                <div className={"h2-title" + ' ' + cx(styles["header-text"])}>Formulario de Actividades Comunitarias</div>
            </div>
            <div className={cx(styles["body-style"])}>
                Cumplimente el siguiente formulario para solicitar la adscripción de nueva actividad comunitaria a la Red de Actividades Comunitarias
                (RAC) del Observatorio de Salud en Asturias o bien para modificar una ya existente.
                Para más información <a className="links" onClick={(e) => { history.push('/ayuda'); history.go('/ayuda') }}>puedes acceder a información sobre qué es la RAC y qué tipo de actividades pueden incluirse.</a>
            </div>
            <div className={cx(styles["body-style"])}>
                <div className={cx(styles["icons-container"])}>
                    <div className={"col-xs-4"}>
                        <div className={cx(styles["icon-circle"]) + ' ' + cx(styles["circle-completed"])}><img className={cx(styles["icon-completed"])} src={require("../../images/information.svg").default} /></div>
                        <div className={cx(styles["circle-text"])}>Información de la Actividad</div>
                    </div>
                    <div className={"col-xs-4"}>
                        <div className={cx(styles["icon-circle"]) + ' ' + cx(styles["circle-completed"])}><img className={cx(styles["icon-completed"])} src={require("../../images/user.svg").default} /></div>
                        <div className={cx(styles["circle-text"])}>Datos de contacto</div>
                    </div>
                    <div className={"col-xs-4"}>
                        <div className={cx(styles["icon-circle"]) + ' ' + cx(styles["circle-active"])}><img className={cx(styles["icon-active"]) + ' ' + cx(styles["icon-check"])} src={require("../../images/check-relleno.svg").default} /></div>
                        <div className={cx(styles["circle-text"])}>Confimación</div>
                    </div>
                </div>
                <div>
                    <div className={cx(styles["confirmation-element"])}>Nombre: {props.activity.name}</div>
                    <div className={cx(styles["confirmation-element"])}>Breve descripción: {props.activity.summary}</div>
                    {props.activity.description && props.activity.description !== "" ?
                        <div>
                            <div className={cx(styles["confirmation-element"])}>Descripción completa:</div>
                            <div className={cx(styles["confirmation-element"])}>
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="ACToolbar"
                                    wrapperClassName="wrapperClassName"
                                    readOnly
                                />
                            </div>
                        </div>
                        :
                        ""
                    }
                    <div className={cx(styles["confirmation-element"])}>Población: {props.activity.demographicGroup?.description}</div>
                    <div className={cx(styles["confirmation-element"])}>Género: {props.activity.gender?.description}</div>
                    {props.activity.otherDetails && props.activity.otherDetails !== "" ?
                        <div className={cx(styles["confirmation-element"])}>Otros detalles población: {props.activity.otherDetails}</div>
                        :
                        ""
                    }
                    <div className={cx(styles["confirmation-element"])}>Gratuita: {props.activity.free}</div>
                    <div className={cx(styles["confirmation-element"])}>Temporalidad: {props.activity.temporality}</div>
                    {props.activity.temporality === "Temporal" ?
                        props.temporalities ?
                            props.temporalities.map((item, index) => {
                                console.log("Temporalities: " + index);
                                return (
                                    <div key={index} className={cx(styles["confirmation-element"])}>De: {item.start_date} a {item.end_date}</div>
                                )
                            })
                            : ""
                        :
                        ""}

                    <div className={cx(styles["confirmation-element"])}>Lugares donde se desarrolla:
                    </div>
                    {props.locations.map((item, index) => {
                        return (
                            <div className={cx(styles["confirmation-element"])} key={index}>
                                {item.roadType.description !== undefined ? item.roadType.description + " " : ""}
                                {item.street !== undefined ? item.street + " " : ""}
                                {item.number !== undefined ? item.number + " " : ""}
                                {item.postalCode !== undefined ? item.postalCode + " " : ""}
                                {item.locality !== undefined ? item.locality + " " : ""}
                                {item.ubication !== undefined ? item.ubication + " " : ""}
                                {item.municipality.name !== undefined ? item.municipality.name : ""}
                            </div>
                        )
                    })}
                    <div className={cx(styles["confirmation-element"])}>Persona de contacto: {props.activity.contact}</div>
                    <div className={cx(styles["confirmation-element"])}>Mail de contacto: {props.activity.contactMail}</div>
                    <div className={cx(styles["confirmation-element"])}>Teléfono de contacto: {props.activity.phone}</div>
                    {props.activity.webPage && props.activity.webPage !== "" ?
                        <div className={cx(styles["confirmation-element"])}>Página web: {props.activity.webPage}</div>
                        : ""
                    }
                    {props.activity.facebookPage && props.activity.facebookPage !== "" ?
                        <div className={cx(styles["confirmation-element"])}>Página de Facebook: {props.activity.facebookPage}</div>
                        : ""
                    }
                    {props.activity.twitterPage && props.activity.twitterPage !== "" ?
                        <div className={cx(styles["confirmation-element"])}>Página de Twitter: {props.activity.twitterPage}</div>
                        : ""
                    }
                    {preview && preview.length > 0 ?
                        <div className={cx(styles["confirmation-element"])}>Imagen: <img src={preview} alt={preview} className="img-preview" /></div>
                        : ""
                    }
                    <div className={cx(styles["confirmation-element"])}>Has aceptado la política de confidencialidad</div>
                    {props.activity.permiso ? 
                        <div className={cx(styles["confirmation-element"])}>La persona de contacto de la actividad/recurso ha dado su conformidad para recibir mensajes de la web</div>
                     : ""}
                </div>
            </div>
            {props.infoContainer}
            <div className={cx(styles["footer-container"])}>
                <div className={cx(styles["buttons-container-index"])}>
                    <div onClick={() => { props.returnHome() }} className={cx(styles["button-return"], "button")}>{f({ id: "forms.start.again" })}</div>
                    <div onClick={() => { props.backpage() }} className={cx(styles["button-index"], "button")}>{f({ id: "forms.previous" })}</div>
                    <button 
                        onClick={() => { 
                            if(!props.loading){
                                props.post() 
                            }
                        }} 
                        form="boat-type-form" variant="contained" className={cx(styles["button-index"], "button")} type="submit">{f({ id: "forms.next" })}</button>
                </div>
            </div>

        </form>
    );
}

export default Confirmation;