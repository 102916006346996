//import Cookies from 'js-cookie';
const API_URL = process.env.REACT_APP_API_URL_FILES;


export function isEmptyFunction(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key)) {
            if (obj[key]) {
                return false
            }
        }
    }
    return true;
}

const buildHeaderGeneric = (token, contentType) => {
    let header = {};
    if (contentType === "multipart/form-data") {
        header = {};
    } else {
        header = { 
            "Content-Type": contentType ? contentType : "application/json",
            // "Accept": "application/json"
        }
    }
    
    if (token) {
        header = { ...header, "Authorization":  `Bearer ${token}`};
    }

    return header;
    
}

export const buildHeader = (contentType) => {
    return buildHeaderGeneric(sessionStorage.getItem('token'), contentType);
}

export const buildHeaderRefresh = () => {
    return buildHeaderGeneric(sessionStorage.getItem('refreshToken'));
}

