import React, { useState } from "react";
import cx from 'classnames';
import styles from './Register.module.scss'
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIntl } from "react-intl";
import { registerMethod } from "state/auth/actions";
import { useDispatch, useSelector } from '../../state';
import { Alert, Modal, ModalHeader, ModalBody } from "reactstrap";
import ReactLoading from 'react-loading';

const Register = () => {

  const history = useHistory();
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const { loading, error, registerUser } = useSelector('auth');

  const schema = yup.object().shape({
    email: yup.string().email(f({ id: "form.errors.email.validation" })).required(f({ id: "form.errors.email.required" })),
    emailVerification: yup.string(f({ id: "form.errors.email.validation" })).oneOf([yup.ref('email'), null], f({ id: "register.errors.email.match" })).required(f({ id: "form.errors.email.required" })),
    password: yup.string().min(8).max(32).matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,32})/, f({ id: "form.errors.password.strong" }))
      .required(),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { email: "", password: "", emailVerification: "" }
  });

  const onSubmit = (data) => {
    const post = async () => {
      await registerMethod(dispatch, data);
    }

    post();
  }

  return (
    loading ? <div className='loading-container'><ReactLoading type={'bars'} color={'#DC042B'} height={128} width={128} /></div> :
      <div className={cx(styles["main-container"])} >
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Aviso legal y política de privacidad</ModalHeader>
          <ModalBody>
            <p>Al amparo de lo dispuesto en la Ley Orgánica 15/1999, de 13 de diciembre, de 
            Protección de Datos de Carácter Personal (LOPD), el interesado puede ejercer los 
            derechos de acceso, rectificación, cancelación y oposición de sus datos de carácter 
            personal en los términos establecidos en la LOPD. La entidad responsable del fichero 
            es la Alianza de Salud Comunitaria y el Observatorio de Salud en Asturias, y se puede 
            contactar a través de la Escuela Andaluza de Salud Pública.</p>
            <p>Los datos que se faciliten serán utilizados solamente en relación a la Red de 
            Actividades Comunitarias (RAC),  al Observatorio de Salud en Asturias y el proyecto de 
            red nacional de actividades comunitarias que se está desarrollando con el PACAP.</p>
          </ModalBody>
        </Modal>
        <div className={cx(styles["panel"])}>
          <div className={cx(styles["panel-head"])}>
            <div className={cx(styles["head-text"])}>{f({ id: "register.title" })}</div>
          </div>
          <div className={cx(styles["panel-body"])} >
            <div className="login-description">
              {f({ id: "form.description" })}
              <a className="links" onClick={() => { history.push('/ayuda'); history.go('/ayuda') }}> {f({ id: "form.description2" })}</a>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={cx(styles["register-form"])}>
              <div className={cx(styles["input-container"])}>
                <div>{f({ id: "form.email" })}</div>
                <input name="email" ref={register} className="textfield" />
                <div className={"error"}>{errors.email?.message}</div>
              </div>
              <div className={cx(styles["input-container"])}>
                <div>{f({ id: "register.email.repeat" })}</div>
                <input name="emailVerification" ref={register} className="textfield" />
                <div className={"error"}>{errors.emailVerification?.message}</div>
              </div>
              <div className={cx(styles["input-container"])}>
                <div>{f({ id: "form.password" })}</div>
                <input name="password" ref={register} type="password" className="textfield" />
                <div className={"error"}>{errors.password?.message}</div>
              </div>
              <div className={cx(styles["checkbox-container"])}>
                <input type="checkbox" id="cboxPrivacity" className={cx(styles["registerCheckbox"])} />
                <div htmlFor="cboxPrivacity">{f({ id: "register.checkbox1" })}<a className="links" onClick={toggle}>{f({ id: "register.checkbox2" })}</a></div>
              </div>
              {error && <Alert className="mt-1" color="danger">
                {error.description}
              </Alert>}

              {registerUser && <Alert className="mt-1" color="primary">
                {registerUser}
              </Alert>}

              <input type="submit" className={cx(styles["register-button"], 'button')} />
            </form>
            <div className={"links-container"}>

              <a className="links" onClick={() => { history.push('/login'); history.go('/login') }}>{f({ id: "form.login.text" })}</a>

            </div>
          </div>
        </div>
      </div>
  );

}

export default Register;