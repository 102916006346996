import React from "react";
import {
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";

const PaginationComponent = (props) => {

  const buildPagination = () => {
    let pages = [];

    pages.push(<PaginationItem key={-1} disabled={props.pageIndex === 0}>
      <PaginationLink onClick={props.pageIndex === 0 ? () => props.onPageIndexChange(0) : () => props.onPageIndexChange(props.pageIndex - 1)} >Anterior</PaginationLink>
    </PaginationItem>);

    let firstPage = 0;
    let lastPage = 10;

    if (props.totalPages > 10) {
      // se calculan la primera y última página que aparecerán en la paginación 
      firstPage = props.pageIndex - 4;
      lastPage = props.pageIndex + 5;

      if (firstPage < 1) {
        lastPage = 10;
        while (firstPage < 1) {
          firstPage++;
        }
        lastPage += firstPage - 1;
      }

      if (lastPage > props.totalPages) {
        // se calcula la última página
        while (lastPage > props.totalPages) {
          lastPage--;
          firstPage--;
        }
      }
    }

    for (let i = 1; i <= (props.totalPages < 10 ? props.totalPages : lastPage); i++) {
      pages.push(
        <PaginationItem key={i - 1} active={props.pageIndex === i - 1} >
          <PaginationLink onClick={() => props.onPageIndexChange(i - 1)}>{i}</PaginationLink>
        </PaginationItem>);
    }

    pages.push(
      <PaginationItem key={props.totalPages + 1} disabled={props.pageIndex === props.totalPages}>
        <PaginationLink onClick={ props.totalPages - 1 !== props.pageIndex ? () => props.onPageIndexChange(props.pageIndex + 1) : () => props.onPageIndexChange(props.pageIndex)} >Siguiente</PaginationLink>
      </PaginationItem>);

    return pages;
  }


  return (
    props.items && props.items.length > 0 && <div className="pagination-wrapper">
      <Pagination aria-label="Page navigation example">
        {buildPagination()}
      </Pagination>
    </div>

  )
}

export default PaginationComponent;