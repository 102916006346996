import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import styles from './Temporalities.module.scss';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch, useSelector } from '../../state';

const Temporalities = (props) => {

    const dispatch = useDispatch();

    const [temporalityIndex, setTemporalityIndex] = useState();
    const [edit, setEdit] = useState(false);

    const { temporality, temporalities } = useSelector('activities');

    useEffect(() => {
        if (props.temporalitiesList && temporalities.length === 0) {
            dispatch({ type: 'SET_TEMPORALITIES', payload: props.temporalitiesList });
        }
    }, [props.temporalities]);

    let schema = Yup.object({
        start_date: Yup.string().required("No puede estar vacio."), 
        end_date: Yup.string().required("No puede estar vacio.")
    });

    const { register: registerTemporality, handleSubmit: handleSubmitTemporality, errors: errorsTemporality, setValue, getValues, watch, control } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(schema),
        defaultValues: {
            start_date: "",
            end_date: "",
        }
    });

    const addTemporality = (data) => {
        dispatch({ type: 'ADD_TEMPORALITIES', payload: { data } });
        setValue("start_date", "");
        setValue("end_date", "");
    };

    const removeTemporality = (temporality) => {
        dispatch({ type: 'REMOVE_TEMPORALITIES', payload: { temporality } });
        leaveEditMode();
    };

    const takeTemporality = (item, index) => {
        setValue("start_date", item.start_date);
        setValue("end_date", item.end_date);
        setTemporalityIndex(index);
        if (edit === false) {
            setEdit(true);
        }
    };

    const editTemporality = () => {
        const data = {
            "temporalityIndex": temporalityIndex,
            "start_date": getValues("start_date"),
            "end_date": getValues("end_date"),
        }

        dispatch({ type: 'EDIT_TEMPORALITIES', payload: { data } });
        leaveEditMode();
    };

    const leaveEditMode = () => {
        setEdit(false);
        setValue("start_date", "");
        setValue("end_date", "");
    }

    return (
        <div className="form-container">
            <table>
                <thead>
                    <tr>
                        <th className={`h4-title col-sm-2`}>Fecha de inicio</th>
                        <th className={`h4-title col-sm-2`}>Fecha de fin</th>
                        <th className={`h4-title col-sm-1`}>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {temporalities.length > 0 ?
                        temporalities.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.start_date}</td>
                                    <td>{item.end_date}</td>
                                    <td className={cx(styles["actions-container"])} >
                                        <div className={cx(styles["actions-button"], "button")} onClick={() => takeTemporality(item, index)} >
                                            <img src={require('../../images/pen-to-square-solid.svg').default} className={cx(styles["actions-img"])}></img>
                                        </div>
                                        <div className={cx(styles["actions-button"], "button")} onClick={() => removeTemporality(item)}>
                                            <img src={require('../../images/trash-solid.svg').default} className={cx(styles["actions-img"])}></img>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                        : <tr></tr>}
                </tbody>
            </table>

            <form key={2} id="temporalities-form" className={cx(styles["temporality-container"])} onSubmit={handleSubmitTemporality(addTemporality)}>
                <div className={cx(styles["control-label"], "h4-title")}>Intervalos de fechas en los que se realiza la actividad *</div>

                <div className={cx(styles["temporality-fields-container"])}>
                    <div className={cx(styles["temperality-field"])}>
                        <div className={cx(styles["control-label"])}>Inicio *</div>
                        <input type="date" className={cx(styles["ubication-inputs"], "select-control")} 
                            ref={registerTemporality} name="start_date" max={watch("end_date")}/>
                        {errorsTemporality.start_date && (
                            <div className="invalid-feedback">
                                {errorsTemporality.start_date.message}
                            </div>
                        )}
                    </div>
                    <div className={cx(styles["temperality-field"])}>
                        <div className={cx(styles["control-label"])}>Fin *</div>
                        <input type="date" className={cx(styles["ubication-inputs"], "select-control")} 
                            ref={registerTemporality} name="end_date" min={watch("start_date")}/>
                        {errorsTemporality.end_date && (
                            <div className="invalid-feedback">
                                {errorsTemporality.end_date.message}
                            </div>
                        )}
                    </div>
                </div>

                <div className={cx(styles["temporality-button-container"])}>
                    <div className={edit ? "temporality-button button" : "hide-button button"}
                        onClick={() => editTemporality()}>Editar rango</div>

                    <div className={edit ? "temporality-button button" : "hide-button button"}
                        onClick={() => leaveEditMode()}>Salir de editar</div>

                    <button className={edit ? "hide-button button" : "temporality-button button"}
                        type="submit" form="temporalities-form">Añadir rango</button>
                </div>

            </form>

        </div>

    )

}

export default Temporalities;