import React from "react";

import styles from './Activity-import.module.scss'
import cx from 'classnames';

const ActivityImport = () => {

    return (
        <div className={cx(styles["main-container"])}>
            <div className={cx(styles["img-container"])}>
                <div><img src={require("../../images/obsa-grande.png")}></img></div>
                <div><img src={require("../../images/logo-consejeria.png")}></img></div>
            </div>
            <div className={cx(styles["subheader-activities-admin"], "subheader")}>
                <div className='h2-title'>Importar Actividades Comunitarias</div>
            </div>
            <div className={cx(styles["panel-body"])}>
                <form id="csv-actividad" method="POST" className={cx(styles["csv-activity"])}>
                    <div className={cx(styles["csv-actividad-label"])}>
                        <label >File</label>
                    </div>
                    <div className={cx(styles["csv-actividad-input"])}>
                        <input type="file" required="required"></input>
                    </div>
                    <div className={cx(styles["buttons-container"])}>

                    </div>
                </form>
            </div>
            

            <div className="web-information">
                <div>
                    <a href='https://www.facebook.com/ObservatorioSalud.Asturias' target="_blank" className={cx(styles['icons'])}><img src={require('../../images/icon_facebook.png')} alt="Facebook" /></a>
                    <a href='https://twitter.com/#!/obsaludasturias' target="_blank" className={cx(styles['icons'])}><img src={require('../../images/icon_twitter.png')} alt="Twitter" /></a>
                    <a href='https://www.obsaludasturias.com/obsa/feed/' target="_blank" className={cx(styles['icons'])}><img src={require('../../images/icon_rss.png')} alt="RSS" /></a>
                </div>
                <div>
                    <a href='https://www.obsaludasturias.com/obsa/aviso-legal/' target="_blank" className="links">Aviso Legal</a>
                    <a href='https://www.obsaludasturias.com/obsa/creditos/' target="_blank" className="links">Créditos</a>
                </div>
                <div><img src={require("../../images/logo-consejeria.png")} /></div>
                <br />
                <div>Web optimizada para versiones posteriores a Internet Explorer 8, Firefox 10, Google Chrome 15</div>
                <br />
            </div>


        </div>
    )

};

export default ActivityImport;