import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from '../../state';
import { getResourceById, getPerformancePdf } from 'state/resources/actions';
import ReactLoading from 'react-loading';
import SimpleMap from '../../components/map/Map.js';
import cx from 'classnames';
import styles from './ShowResource.module.scss';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';


const ShowResource = () => {

    const dispatch = useDispatch();
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const history = useHistory();
    const { resource, loading, xAxis, yAxis } = useSelector('resources');
    const { id } = useParams();
    const [preview, setPreview] = useState();


    const goBack = () => {
        history.goBack();
    };

    useEffect(() => {
        const getActivity = async () => {
            await getResourceById(dispatch, { id });
        }
        getActivity();
    }, []);

    useEffect(() => {
        if (resource.photo) {
            setPreview(`${process.env.REACT_APP_IMG_URL}${resource.photo}`);
        } else {
            setPreview("");
        }

        if (resource.description !== "" && resource.description !== undefined) {
            const parsed = convertFromRaw(JSON.parse(resource.description));
            setEditorState(EditorState.createWithContent(parsed));
        }

    }, [resource])

    const openImg = (url) => {
        window.open(url, '_blank');
    }

    const getPdfUrl = () => {

        const data = {
           // topics: resource.topics,
            name: resource.name,
            id: resource.id,
            summary: resource.summary,
            description: editorState.getCurrentContent().getPlainText(),
            contact: resource.contact,
            phone: resource.phone,
            contactMail: resource.contactMail,
            lastUpdateDate: resource.creationDate,
            situation: resource.situation,
            location: resource.location
        }

        const auxFunction = async () => {
            await getPerformancePdf(data);
        }

        auxFunction();
    }

    const [toggle, setToggle] = useState(true);
    const showMoreInfo = () => {
        setToggle((toggle) => !toggle);
    }

    return (

        loading ? <div className='loading-container'><ReactLoading type={'bars'} color={'#DC042B'} height={128} width={128} /></div> :
            <>
                <div className={cx(styles["main-container"])}>
                    <div className={cx(styles["sa-panel-heading"])}>
                        <div className='h2-title'>{resource.name}</div>
                    </div>
                    <div className={cx(styles['sa-body-panel'])}>
                        <div className={cx(styles['sa-top-panel'])}>
                            <div className={cx(styles['sa-title'])}>
                                Red adscrita<img className={cx(styles['title-image'])} src={require("../../images/obsa_icon_hd_color.png")} alt="red" /><b>OBSA</b>
                            </div>
                            <div className={cx(styles['sa-topics-container'])}>
                                <div className={cx(styles['sa-topics'])}>
                                    <div>Temas</div>
                                    {resource.topics?.map((item) => {
                                        return (
                                            <img className={cx(styles["sa-topics-image"])} src={require(`../../images/${item.imgName}`)} 
                                            key={item.imgName} title={item.description} alt={item.description} />
                                        )
                                    })}
                                </div>

                            </div>
                            <div className={cx(styles['sa-summary-container'])}>
                                <div className='sa-activity-photo'><img className={cx(styles['activity-img'])} src={preview} alt={preview} onClick={() => openImg(preview)}/></div>
                                <div className={cx(styles[toggle ? 'sa-summary-big-none' : 'sa-summary-big-block'])}>
                                    <p><b>Descripción</b></p>
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="ACToolbar"
                                        wrapperClassName="wrapperClassNamePerformance"
                                        readOnly
                                        /> 
                                </div>
                                <div className={cx(styles[toggle ? 'sa-summary-block' : 'sa-summary-none'])}>{resource.summary}</div>
                                <div className={cx(styles["sa-button"]) + ' ' + cx(styles['sa-details-button'])} onClick={() => showMoreInfo()}>
                                    <div className={cx(styles["sa-button-text"])} >Ver detalles »</div>
                                </div>
                            </div>
                        </div>

                        <div className={cx(styles['sa-mid-panel'])}>
                            <div className={cx(styles['sa-left-panel'])}>

                                {resource.contact ?
                                    <div className={cx(styles['separator'])}>
                                        <div className={cx(styles['separator-title'])}>Contacto</div>
                                        <div className={cx(styles['separator-text'])}>{resource.contact ? resource.contact : ""} {resource.phone ? resource.phone : ""} ({resource.contactMail})</div>
                                    </div>
                                    : ""}


                                <div className={cx(styles['separator'])}>
                                    <div className={cx(styles['separator-title'])}>Fecha de última actualización de la actividad</div>
                                    <div className={cx(styles['separator-text'])}>{resource.lastUpdateDate}</div>
                                </div>

                                
                                <div className={cx(styles['separator'])}>
                                    <div className={cx(styles['separator-title'])}>Situación</div>
                                    <div className={cx(styles['separator-text'])}>{resource.situation === 1 ?
                                        <div className={cx(styles['situation-active-container'])}>Activa</div>
                                        :
                                        <div className={cx(styles["situation-historical-container"])}>Histórica</div>}</div>
                                </div>
                                 
                                {resource.webPages ?
                                    <div className={cx(styles['separator'])}>
                                        <div className={cx(styles['separator-title'])}>Página web del proyecto</div>
                                        <a className={cx(styles['separator-text'])} href={resource.webPage}>{resource.webPage}</a>
                                    </div>
                                    : ""}

                                {resource.facebookPage ?
                                    <div className={cx(styles['separator'])}>
                                        <div className={cx(styles['separator-title'])}>Facebook</div>
                                        <a className={cx(styles['separator-text'])} href={resource.facebookPage}>{resource.facebookPage}</a>
                                    </div>
                                    : ""}
                                {resource.twitterPage ?
                                    <div className={cx(styles['separator'])}>
                                        <div className={cx(styles['separator-title'])}>Twitter</div>
                                        <a className={cx(styles['separator-text'])} href={resource.twitterPage}>{resource.twitterPage}</a>
                                    </div>
                                    : ""}


                            </div>

                            <div className={cx(styles['sa-right-panel'])}>

                                <div className={cx(styles['separator'])}>
                                    <div className={cx(styles['separator-title'])}>Localización</div>
                                    <div className={cx(styles['separator-text'])}>
                                        {resource.location?.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    {item.roadType?.description !== undefined ? item.roadType?.description + " " : ""}
                                                    {item.street !== undefined ? item.street + " " : ""}
                                                    {item.postalCode !== undefined ? item.postalCode + " " : ""}
                                                    {item.locality !== undefined ? item.locality + " " : ""}
                                                    {item.ubication !== undefined ? item.ubication + " " : ""}
                                                    {item.municipality?.name !== undefined ? item.municipality?.name : ""}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div className={cx(styles['separator']) + ' ' + cx(styles['map'])}>
                                    <div className={cx(styles['separator-text'])}>
                                        <SimpleMap detailView={true} style={{ width: "80%", maxWidth: 510, height: 300 }} locations={resource?.location} zoom={8} initialCenter={{ lat: xAxis, lng: yAxis }} />
                                    </div>
                                </div>
                                <div className={cx(styles['separator'])}>
                                    <div onClick={getPdfUrl} className={cx(styles['sa-button']) + ' ' + cx(styles['button-download'])}>
                                        <img alt="descarga pdf" src={require("../../images/pdf.svg").default} className={cx(styles['btn-icon'])} />
                                        <div  className={cx(styles['sa-button-text'])}>Descargar PDF</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={cx(styles['back-button-container'])}>
                    <div className={cx(styles['back-button']) + ' ' + cx(styles['sa-button']) + ' ' + cx(styles['sa-button-text'])} onClick={() => goBack()}>Volver a las actuaciones</div>
                </div>
            </>


    );
}

export default ShowResource;