import { buildHeader, buildHeaderRefresh } from "helpers/helpers";
const API_URL = process.env.REACT_APP_API_URL;

export const putMultipart = async (url, payload) => {

  const contentType = "multipart/form-data";
  const formData = new FormData();
  formData.append("photo", payload.photo[0]);

  const activityDto = {};

  activityDto.id = payload.id;
  activityDto.name =  payload.performance.name;
  activityDto.summary = payload.performance.summary;
  activityDto.description = payload.performance.description;
  activityDto.demographicGroup =
    payload.performance.demographicGroup?.description;
  activityDto.gender = payload.performance.gender?.description;
  activityDto.temporality = payload.performance.temporality;
  activityDto.otherDetails =  payload.performance.otherDetails;
  activityDto.free = payload.performance.free;
  activityDto.activityDtotemporality = payload.performance.temporality;
  activityDto.contact = payload.performance.contact;
  activityDto.contactMail = payload.performance.contactMail;
  activityDto.webPage = payload.performance.webPage;
  activityDto.twitterPage = payload.performance.twitterPage;
  activityDto.facebookPage = payload.performance.facebookPage;
  activityDto.entity = payload.performance.entity;
  activityDto.phone = payload.performance.phone;
  activityDto.temporalities = payload.temporalities;
  activityDto.locations = payload.locations;
  activityDto.category = payload.performance.category;
  activityDto.permiso = payload.performance.permiso;

  const json = JSON.stringify(activityDto);
  const blob = new Blob([json], {
    type: 'application/json'
  });

  formData.append("performanceDto", blob);

  const requestOptions = {
    method: "PUT",
    body: formData
  };

  let response = await doFetch(`${url}/${payload.id}`, requestOptions, contentType);

  return { status: response.status, data: await response.json() };
};


export const putValidation = async (url, payload) => {

  const contentType = "application/json";

  let formData = new FormData();
  formData.append("id", payload.id);
  formData.append("inside", payload.inside);
  formData.append("insideob", payload.insideob ? payload.insideob : "");
  formData.append("positiveev", payload.positiveev);
  formData.append("positiveevob", payload.positiveevob ? payload.positiveevob : "");
  formData.append("healthy", payload.healthy);
  formData.append("healthyob", payload.healthyob ? payload.healthyob : "");
  formData.append("profit", payload.profit);
  formData.append("profitob", payload.profitob ? payload.profitob : "");
  formData.append("strategy", payload.strategy);
  formData.append("strategyob", payload.strategyob ? payload.strategyob : "");
  formData.append("validated", payload.validated);
  formData.append("topics", payload.topics);
  formData.append("determinantes", payload.determinantes);


  let object = {};
  formData.forEach(function(value, key){
      object[key] = value;
  });
  let json = JSON.stringify(object);


  const requestOptions = {
    method: "PUT",
    body: json
  };

  let response = await doFetch(`${url}/${payload.id}/validation`, requestOptions, contentType);

  return { status: response.status, data: await response.json() };


}


export const putRenewal = async (url, payload) => {

  let headers = JSON.stringify({token: sessionStorage.getItem("token")});

  const requestOptions = {
    method: "PUT",
    headers
  };


  let response = await doFetch(
    `${url}/${payload}/renewal`,
    requestOptions
  );

  return response.json();

}


export const getPdf = async (url, payload) => {

  
  let formData = new FormData();
  formData.append("id", payload.id);
  formData.append("summary", payload.summary);
  formData.append("description", payload.description);
  formData.append("contact", payload.contact);
  formData.append("phone", payload.phone);
  formData.append("contactMail", payload.contactMail);
  formData.append("name", payload.name);
  formData.append("lastUpdateDate", payload.lastUpdateDate);
  formData.append("situation", payload.situation);
  formData.append("photo", payload.photo);
  formData.append("locations", JSON.stringify(payload.location))


  let object = {};
  formData.forEach(function(value, key){
      object[key] = value;
  });
  let json = JSON.stringify(object);


  const requestOptions = {
    method: "PUT",
    body: json
  };

  let response = await doFetch(url, requestOptions);
  let filename = response.headers.get("Content-Disposition").split("=")[1];
  const blob = await response.blob();
  const urlCsv = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = urlCsv;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();

}

export const getCsv = async (url, payload) => {

  let header = JSON.stringify({token: sessionStorage.getItem("token"), "Content-Type":"text/csv"});
  const requestOptions = {
    method: "GET",
    headers: header
  };

  let response = await doFetch(url, requestOptions);
  let filename = response.headers.get("Content-Disposition").split("=")[1];
  const blob = await response.blob();
  const urlCsv = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = urlCsv;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();

}


export const postMultipart = async (url, payload) => {
  
  const contentType = "multipart/form-data";
  let formData = new FormData();
  formData.append("photo", payload.photo[0]);

  const activityDto = {};

  activityDto.name =  payload.performance.name;
  activityDto.summary = payload.performance.summary;
  activityDto.description = payload.performance.description;
  activityDto.demographicGroup =
    payload.performance.demographicGroup?.description;
  activityDto.gender = payload.performance.gender?.description;
  activityDto.temporality = payload.performance.temporality;
  activityDto.otherDetails =  payload.performance.otherDetails;
  activityDto.free = payload.performance.free;
  activityDto.activityDtotemporality = payload.performance.temporality;
  activityDto.contact = payload.performance.contact;
  activityDto.contactMail = payload.performance.contactMail;
  activityDto.webPage = payload.performance.webPage;
  activityDto.twitterPage = payload.performance.twitterPage;
  activityDto.facebookPage = payload.performance.facebookPage;
  activityDto.entity = payload.performance.entity;
  activityDto.phone = payload.performance.phone;
  activityDto.temporalities = payload.temporalities;
  activityDto.locations = payload.locations;
  activityDto.category = payload.performance.category;
  activityDto.permiso = payload.performance.permiso;
  activityDto.user = payload.userId;

  const json = JSON.stringify(activityDto);
  const blob = new Blob([json], {
    type: 'application/json'
  });

  formData.append("performanceDto", blob);

  const requestOptions = {
    method: "POST",
    body: formData
  };

  let response = await doFetch(url, requestOptions, contentType);
  return response.json();
};

export const post = async (url, payload) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(payload),
  };

  let response = await doFetch(url, requestOptions);

  if (response.status === 204) {
    return { status: response.status };
  }

  if (response.status === 201) {
    return { status: response.status, data: await response.json() };
  }
  return { status: response.status, data: await response.json() };
};

export const get = async (url) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
  };

  const response = await doFetch(url, requestOptions);
  let data = response.url;
  data = await response.json();

  return data;
};

export const put = async (url, payload) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(payload),
  };

  let response = await doFetch(url, requestOptions);
  return { status: response.status, data: await response.json() };
};

export const deleteMethod = async (url) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };

  let response = await fetch(`${API_URL}/${url}`, requestOptions);
  
  return response;
};

const doFetch = async (url, requestOptions, contentType) => {
  
  requestOptions = { ...requestOptions, headers: buildHeader(contentType) };
  let response = await fetch(`${API_URL}/${url}`, requestOptions);

  if (response.status === 401 || response.status === 403) {
    //refresh token and retry fetch
    sessionStorage.removeItem("token");
    if(sessionStorage.getItem("refreshToken")){
      await refreshToken();
      requestOptions = { ...requestOptions, headers: buildHeader(contentType) };
      response = await fetch(`${API_URL}/${url}`, requestOptions);
    }
  }
  return response;
};

const refreshToken = async () => {



  const refreshTokenResponse = await fetch(`${API_URL}/user/refresh-token`, {
    method: "POST",
    mode: "cors",
    headers: buildHeaderRefresh(),
    body: JSON.stringify({refreshToken: sessionStorage.getItem("refreshToken")}),
  });
  console.log("response status");
  console.log(refreshTokenResponse);

  if (refreshTokenResponse.status === 200) {
    const responseData = await refreshTokenResponse.json();
    /*console.log("responseData");
    console.log(responseData);*/
    sessionStorage.setItem("token", responseData.token);
  }
  return refreshTokenResponse.status;
};
